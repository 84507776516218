import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { ANALYTICS_API, PERSISTOR_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { AsyncTask } from '../../util/AsyncTask';

type Coordinates = { latitude: number; longitude: number };

type HeatMapRaw = {
  radiusMetres: number;
  windowMinutes: string;
  points: (Coordinates & { hits: number })[];
};

export type HeatMap = {
  radius: number;
  points: (Coordinates & { intensity: number })[];
};

const parseResponse = (response: HeatMapRaw): HeatMap => {
  const sumHits = response.points.reduce((acc, point) => acc + Number(point.hits), 0) / 100;
  return {
    radius: response.radiusMetres / 100000,
    points: response.points.map(point => ({
      latitude: Number(point.latitude),
      longitude: Number(point.longitude),
      intensity: point.hits / sumHits,
    })),
  };
};

export const useHeatMap = ({
  to,
  from,
  id,
}: {
  to?: Date | null;
  from?: Date | null;
  id?: number;
}): AsyncTask<HeatMap> & { go: () => void } => {
  const [data, setData] = useState<HeatMap | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  const go = useCallback(async () => {
    if (!to || !from || !id) return;
    try {
      setData(undefined);
      setError(undefined);
      setLoading(true);
      const response = await axios.get<HeatMapRaw>(
        `${persistorUrl}/${cid}/${pid}/analytics/contact-points`,
        {
          params: {
            fromUnixTs: Math.floor(from.getTime() / 1000),
            toUnixTs: Math.floor(to.getTime() / 1000),
            creatorId: id,
          },
          ...authedConfig,
        },
      );
      setData(parseResponse(response.data));
    } catch (error: unknown) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [to, from, id, cid, pid, persistorUrl, authedConfig]);

  return { data, loading, error, go };
};
