import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { PERSISTOR_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { AsyncTask } from '../../util/AsyncTask';

const NEXT_LEVEL_LIMIT = 10;

export const useContacts = ({
  to,
  from,
  id,
}: {
  to: Date | null;
  from: Date | null;
  id?: number;
}): AsyncTask<Contacts> & { go: () => void; computedLevels: number } => {
  const [data, setData] = useState<Contacts | undefined>();
  const [computedLevels, setComputedLevels] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  const go = useCallback(async () => {
    if (!to || !from || !id) return;
    try {
      setLoading(true);
      setComputedLevels(0);
      setError(undefined);
      setData(undefined);
      const traceLevels = (maxLevel: number) =>
        axios.get<RawContacts>(`${persistorUrl}/${cid}/${pid}/analytics/contact-levels`, {
          params: {
            fromUnixTs: Math.floor(from.getTime() / 1000),
            toUnixTs: Math.floor(to.getTime() / 1000),
            maxLevel,
            creatorId: id,
          },
          ...authedConfig,
        });
      const level1 = processRawContacts((await traceLevels(1)).data);
      setData(level1);
      if (level1.length < NEXT_LEVEL_LIMIT) {
        const level2 = processRawContacts((await traceLevels(2)).data);
        setData(level2);
        setComputedLevels(2);
      } else {
        setComputedLevels(1);
      }
    } catch (error: unknown) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [to, from, id, cid, pid, persistorUrl, authedConfig]);

  return { data, computedLevels, loading, error, go };
};

const processRawContacts = (contacts: RawContacts) => {
  return Object.values(contacts).map(({ earliestContact, level, ...rest }) => ({
    ...rest,
    earliestContact: new Date(earliestContact),
    indirection: level,
  }));
};

type RawContacts = Record<
  string,
  {
    creatorId: number;
    label: string;
    level: number;
    hits: number;
    earliestContact: string;
    identifiers: unknown;
    queried: boolean;
  }
>;

export type Contacts = {
  creatorId: number;
  label: string;
  indirection: number;
  hits: number;
  earliestContact: Date;
  identifiers: unknown;
  queried: boolean;
}[];
