import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Build, Download } from '@mui/icons-material';
import { Header } from '../../Common/Sidebar';
import { FileInput } from '../../util/FileInput/FileInput';
import ToolsList from './ToolsList';
import { PortableAsset, ToolType } from '../Map/types';
import InputContainer from '../Global/InputContainer';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { METADATA_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { useMobile } from '../../util/useMobile';
import ToolTypeList from './ToolTypeList';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { useGeomobyLiveStream } from '../../hooks/geomoby/useGeomobyLiveStream';
import { SensedTriggeredEvent } from '../Map/Messages';
import { getStreamedTools } from '../Map/LiveMap';

export type BulkTool = { label?: string; tool?: string };
type Tool = {
  ibeacon: {
    uuid: string;
    major: number;
    minor: number;
  };
  tool: string;
  id?: string;
  label?: string;
  type: 'tool';
};
type TabType = 'TOOLS' | 'TOOL_TYPES';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function getDuplicateLines(duplicates: (Tool & { index: number })[]): string {
  if (duplicates.length < 1) return '';
  const lines = duplicates.map(dup => dup.index);
  return `${lines.slice(0, lines.length - 1).join()} and ${lines[lines.length - 1]}.`;
}

const Tools = () => {
  const COMMA_REPLACEMENT_TOKEN = '###COMMA###';
  const metadataUrl = useAtomValue(METADATA_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const isMobile = useMobile();
  const liveStreamData = useGeomobyLiveStream({ useFor: 'livemap' });
  const liveStreamState = liveStreamData.state;
  const streamedTools: SensedTriggeredEvent[] = useMemo(
    () => getStreamedTools(liveStreamState.assets),
    [liveStreamState],
  );

  const [bulkList, setBulkList] = useState<PortableAsset[]>([]);
  const [bulkUpdatedTool, setBulkUpdatedTool] = useState<BulkTool | undefined>();
  const [csvFile, setCsvFile] = useState<File>();
  const [editingType, setEditingType] = useState<'SINGLE' | 'BULK' | undefined>();
  const [editingTooltype, setEditingTooltype] = useState<ToolType | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newTooltype, setNewTooltype] = useState<string | undefined>();
  const [page, setPage] = useState<number>(1);
  const [openToolsFilterDialog, setOpenToolsFilterDialog] = useState<boolean>(false);
  const [openToolTypeFilterDialog, setOpenToolTypeFilterDialog] = useState<boolean>(false);
  const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);
  const [duplicateIbeaconTools, setDuplicateIbeaconTools] = useState<(Tool & { index: number })[]>(
    [],
  );
  const [refresh, setRefresh] = useState<boolean>(true);
  const [refreshToolTypes, setRefreshToolTypes] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<TabType>('TOOLS');
  const [toolsCount, setToolsCount] = useState<number>(0);
  const [toolTypes, setToolTypes] = useState<ToolType[]>([]);
  const [tools, setTools] = useState<PortableAsset[]>([]);
  const [singleUpdatedTool, setSingleUpdatedTool] = useState<PortableAsset | undefined>();

  const pageRef = useRef<number>(1);

  const CSV_HEADER =
    'row,"[ibeacon_uuid, string]","[ibeacon_major, number]","[ibeacon_minor, number]","[id, string]","[label, string, OPTIONAL]","[tool_type, string]"';

  const getToolTypes = useCallback(async () => {
    setToolTypes(
      (
        await axios.get<ToolType[]>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool/all`,
          authedConfig,
        )
      ).data?.sort((a, b) => a.tool.toLowerCase().localeCompare(b.tool.toLowerCase())),
    );
  }, [cid, pid, metadataUrl, authedConfig, setToolTypes]);

  const changeTab = (event: React.SyntheticEvent, newTab: TabType) => {
    setSelectedTab(newTab);
  };

  const uploadBulkTools = async () => {
    if (!csvFile) return;
    let newTools: Tool[] = [];
    setTools([]);
    setPage(1);
    const fileReader = new FileReader();
    fileReader.readAsText(csvFile, 'utf-8');
    fileReader.onload = e => {
      const contents = String((e as ProgressEvent<FileReader>).target?.result);
      const lines = Array.from(contents.trim().split(/\r|\n/));
      if (lines.length > 0 && lines[0].replaceAll('\n', '').replaceAll('\r', '') !== CSV_HEADER) {
        setRefresh(true);
        setSaveNotification({
          id: SaveResult.FAIL,
          action: 'Save',
          message: 'Uploaded file not in the correct format. Refer to CSV template',
        });
        return;
      } else if (lines.length <= 1) {
        setRefresh(true);
        setSaveNotification({
          id: SaveResult.FAIL,
          action: 'Save',
          message: 'Uploaded file is empty',
        });
        return;
      }

      if (lines.length > 1) {
        lines.slice(1).forEach(line => {
          const lineContent = Array.from(
            line
              .replace(/"(.*?)"/g, str => str.replaceAll(',', COMMA_REPLACEMENT_TOKEN))
              .trim()
              .split(',')
              .slice(1),
          ).map(content => content.replaceAll(COMMA_REPLACEMENT_TOKEN, ',').replaceAll('"', ''));
          if (lineContent.length === 0) return;
          const newTool = {
            ibeacon: {
              uuid: lineContent[0].toLowerCase(),
              major: Number(lineContent[1]),
              minor: Number(lineContent[2]),
            },
            tool: lineContent[5],
            type: 'tool',
          } as Tool;
          if (!lineContent[0] || !lineContent[1] || !lineContent[2] || !lineContent[5]) {
            setRefresh(true);
            setSaveNotification({
              id: SaveResult.FAIL,
              action: 'Save',
              message: 'Uploaded file missing mandatory values. Refer to CSV template',
            });
            newTools = [];
            return;
          }
          const toolId = lineContent[3]
            .replaceAll(COMMA_REPLACEMENT_TOKEN, ',')
            .replaceAll('"', '');
          if (lineContent[3]) {
            newTool.id = toolId;
          }
          const label = lineContent[4];
          if (label) {
            newTool.label = label;
          }
          newTools.push(newTool);
        });
      }
    };

    fileReader.onloadend = async () => {
      if (newTools.length === 0) return;
      axios
        .post<Tool>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool/bulk`,
          [...newTools],
          authedConfig,
        )
        .then(() => {
          setRefresh(true);
          setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
        })
        .catch(error => {
          const responseData = ((error as AxiosError).response as AxiosResponse).data;
          setRefresh(true);
          setSaveNotification({
            id: SaveResult.FAIL,
            action: 'Save',
            message: responseData.message.message,
          });

          if (responseData.statusCode === 400) {
            const foundDuplicate = newTools.find(tl => {
              const duplicates: (Tool & { index: number })[] = [];
              newTools.forEach((t, index) => {
                if (JSON.stringify(t.ibeacon) === JSON.stringify(tl.ibeacon)) {
                  duplicates.push({
                    ...tl,
                    index: index + 1,
                  });
                }
              });
              if (duplicates.length > 1) {
                setDuplicateIbeaconTools(duplicates);
                return tl;
              }
            });
          }
        });
    };
  };

  const updateTool = async () => {
    const updatedTool = { ...singleUpdatedTool, type: 'tool' };
    try {
      setTools([]);
      setPage(1);
      setBulkList([]);
      await axios.patch<Tool>(
        `${metadataUrl}/${cid}/${pid}/portableasset/${singleUpdatedTool?.uuid}`,
        updatedTool,
        authedConfig,
      );
      setRefresh(true);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const updateBulkTools = async () => {
    try {
      setTools([]);
      setPage(1);
      const updatedTools = bulkList.map(tool => {
        return {
          ...tool,
          label: bulkUpdatedTool?.label ?? tool.label,
          tool: bulkUpdatedTool?.tool ?? tool.tool,
          type: 'tool',
        };
      });

      setBulkList([]);
      Promise.all(
        updatedTools.map(async toUpdate => {
          await axios.patch<Tool>(
            `${metadataUrl}/${cid}/${pid}/portableasset/${toUpdate.uuid}`,
            toUpdate,
            authedConfig,
          );
        }),
      ).then(() => {
        setRefresh(true);
      });
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const createToolType = async () => {
    try {
      const createdToolType = (
        await axios.post<ToolType>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool`,
          { tool: newTooltype },
          authedConfig,
        )
      ).data;
      setToolTypes(
        [...toolTypes, createdToolType].sort((a, b) =>
          a.tool.toLowerCase().localeCompare(b.tool.toLowerCase()),
        ),
      );
      setNewTooltype(undefined);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const updateToolType = async () => {
    try {
      const updatedToolType = (
        await axios.patch<ToolType>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool/${editingTooltype?.id}`,
          { tool: editingTooltype?.tool },
          authedConfig,
        )
      ).data;
      setToolTypes(
        [...toolTypes.filter(t => t.id !== updatedToolType.id), updatedToolType].sort((a, b) =>
          a.tool.toLowerCase().localeCompare(b.tool.toLowerCase()),
        ),
      );
      setEditingTooltype(undefined);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const downloadExistingToolsCSV = async () => {
    let csvContent = '';
    (
      await axios.get<PortableAsset[]>(`${metadataUrl}/${cid}/${pid}/portableasset`, authedConfig)
    ).data
      .filter(asset => asset.type === 'tool' && !!asset.ibeacon)
      .sort((a, b) => (a.id ?? '').localeCompare(b.id ?? ''))
      .forEach((t, count) => {
        csvContent =
          csvContent +
          `${count + 1},${t.ibeacon?.uuid.toLowerCase()},${t.ibeacon?.major},${t.ibeacon?.minor},${
            t.id ?? ''
          },${t.label ?? ''},${t.tool}\n`;
      });

    const generatedCSV = `${CSV_HEADER}\n${csvContent}`;
    const dataUri = window.URL.createObjectURL(new Blob([generatedCSV], { type: 'text/csv' }));

    window.open(dataUri);
  };

  const downloadTemplateCSV = () => {
    const link = document.createElement('a');
    link.download = `Tool_Example.csv`;
    link.href = './Tool_Example.csv';
    link.click();
  };

  useEffect(() => {
    if (openToolsFilterDialog || editingType || selectedTab === 'TOOL_TYPES' || refreshToolTypes) {
      setRefreshToolTypes(false);
      getToolTypes();
    }
  }, [openToolsFilterDialog, selectedTab, editingType, getToolTypes, refreshToolTypes]);

  return (
    <>
      <Box
        sx={
          isMobile
            ? {
                width: '100%',
              }
            : {
                flexGrow: 1,
                bgcolor: 'background.paper',
                display: 'flex',
                position: 'absolute',
                '& .MuiButtonBase-root': {
                  textAlign: 'left',
                },
              }
        }
      >
        <Tabs
          orientation={isMobile ? 'horizontal' : 'vertical'}
          variant="scrollable"
          value={selectedTab}
          onChange={changeTab}
          sx={
            isMobile
              ? {
                  borderBottom: 1,
                  borderColor: 'divider',
                  background: '#23272D',
                  width: '20rem',
                }
              : {
                  borderRight: 1,
                  borderColor: 'divider',
                  background: '#23272D',
                  height: '19rem',
                }
          }
        >
          <Tab label="Tools" value={'TOOLS'} />
          <Tab label="Tool Types" value={'TOOL_TYPES'} />
        </Tabs>
      </Box>

      {/* Create Tools */}
      {!editingType && selectedTab === 'TOOLS' && (
        <Container
          style={{
            paddingBottom: '45px',
          }}
        >
          <Grid
            spacing={3}
            paddingY={2}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: '60px',
              paddingBottom: '10px',
            }}
          >
            <Header icon={<Build />}>Upload new tools</Header>
            <Typography>Bulk upload via CSV file</Typography>
            <FileInput id="csv" onFileSet={f => setCsvFile(f)} allowTypes={['.csv']} />
            <Grid
              container
              style={{
                display: 'grid',
                gridTemplateColumns: '195px 155px',
                width: '350px',
              }}
            >
              <Button onClick={downloadExistingToolsCSV} startIcon={<Download />}>
                Existing tools CSV
              </Button>
              <Button onClick={downloadTemplateCSV} startIcon={<Download />}>
                Template CSV
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{
              width: 'calc(100% - 22px)',
              paddingTop: '15px',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={!csvFile}
              style={{
                width: '25vw',
                maxWidth: '500px',
                minWidth: '255px',
              }}
              size="large"
              onClick={() => {
                uploadBulkTools();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Container>
      )}

      {/* Update Tools */}
      {!!editingType && selectedTab === 'TOOLS' && (
        <Container
          style={{
            paddingBottom: '45px',
          }}
        >
          <Grid
            spacing={3}
            paddingY={2}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: '60px',
              paddingBottom: '10px',
              marginLeft: '0px',
            }}
          >
            <Header icon={<Build />}>
              {editingType === 'BULK' ? 'Bulk edit tools' : 'Edit tool'}
            </Header>

            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{
                overflowY: 'auto',
                height: '400px',
                alignContent: 'start',
              }}
            >
              {/* ID */}
              {editingType === 'SINGLE' && singleUpdatedTool && (
                <Paper
                  variant="outlined"
                  style={{
                    width: 'calc(100% - 10vw)',
                    background: '#23272D',
                    marginTop: '20px',
                  }}
                >
                  <Box padding={2}>
                    <FormControl fullWidth>
                      <InputContainer
                        id="toolId"
                        label="ID"
                        key={'toolId'}
                        name={'toolId'}
                        value={singleUpdatedTool?.id ?? ''}
                        onChange={(e: { target: { value: string } }) => {
                          if (singleUpdatedTool && editingType === 'SINGLE') {
                            setSingleUpdatedTool({
                              ...singleUpdatedTool,
                              id: e.target.value,
                            });
                          }
                        }}
                        placeholder="Tool-111"
                      />
                    </FormControl>
                  </Box>
                </Paper>
              )}

              {/* Label/Serial */}
              <Paper
                variant="outlined"
                style={{
                  width: 'calc(100% - 10vw)',
                  background: '#23272D',
                  marginTop: '20px',
                  height: 'fit-content',
                }}
              >
                <Box padding={2}>
                  <FormControl fullWidth>
                    <InputContainer
                      id="toolId"
                      label="Label/Serial"
                      key={'toolLabel'}
                      name={'toolLabel'}
                      disableInput={bulkList.length === 0}
                      value={
                        editingType === 'SINGLE'
                          ? singleUpdatedTool?.label ?? ''
                          : editingType === 'BULK'
                          ? bulkUpdatedTool?.label ?? ''
                          : ''
                      }
                      onChange={(e: { target: { value: string } }) => {
                        if (singleUpdatedTool && editingType === 'SINGLE') {
                          setSingleUpdatedTool({
                            ...singleUpdatedTool,
                            label: e.target.value,
                          });
                        }
                        if (editingType === 'BULK') {
                          setBulkUpdatedTool({
                            ...(bulkUpdatedTool ?? {}),
                            label: e.target.value,
                          });
                        }
                      }}
                      placeholder="Tool1"
                    />
                  </FormControl>
                </Box>
              </Paper>

              {/* Tool Type */}
              <Paper
                variant="outlined"
                style={{
                  width: 'calc(100% - 10vw)',
                  marginTop: 20,
                  marginBottom: 20,
                  alignSelf: 'center',
                  background: '#23272D',
                }}
              >
                <Box padding={2}>
                  <FormControl fullWidth>
                    <InputLabel id="tool-type-option">Tool Type</InputLabel>
                    <Select
                      fullWidth
                      labelId="tool-type-action"
                      id="tool-type-dropdown"
                      disabled={bulkList.length === 0}
                      value={
                        editingType === 'SINGLE'
                          ? singleUpdatedTool?.tool ?? ''
                          : editingType === 'BULK'
                          ? bulkUpdatedTool?.tool ?? ''
                          : ''
                      }
                      label="Tool Type"
                      onChange={(e: { target: { value: string } }) => {
                        if (singleUpdatedTool && editingType === 'SINGLE') {
                          setSingleUpdatedTool({
                            ...singleUpdatedTool,
                            tool: e.target.value,
                          });
                        }
                        if (editingType === 'BULK') {
                          setBulkUpdatedTool({
                            ...(bulkUpdatedTool ?? {}),
                            tool: e.target.value,
                          });
                        }
                      }}
                    >
                      {toolTypes.map(toolType => (
                        <MenuItem
                          key={toolType.id}
                          value={toolType.tool}
                          disabled={bulkList.length === 0}
                        >
                          <Tooltip title={toolType.tool}>
                            <Typography
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'calc(100% - 50px)',
                              }}
                            >
                              {toolType.tool}
                            </Typography>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Paper>

              {editingType === 'SINGLE' && singleUpdatedTool?.ibeacon && (
                <Paper
                  variant="outlined"
                  style={{
                    width: 'calc(100% - 10vw)',
                    marginBottom: 20,
                    alignSelf: 'center',
                    background: '#23272D',
                  }}
                >
                  <Grid
                    item
                    container
                    direction={'row'}
                    xs={12}
                    fontSize="90%"
                    style={{
                      padding: '15px',
                    }}
                  >
                    UUID
                    <Grid item container>
                      <Tooltip title={'UUID'}>
                        <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                          UUID:
                        </Typography>
                      </Tooltip>
                      <Tooltip title={singleUpdatedTool?.ibeacon.uuid.toLowerCase()}>
                        <Typography>{singleUpdatedTool?.ibeacon.uuid.toLowerCase()}</Typography>
                      </Tooltip>
                    </Grid>
                    Major
                    <Grid item container>
                      <Tooltip title={'Major'}>
                        <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                          Major:
                        </Typography>
                      </Tooltip>
                      <Tooltip title={singleUpdatedTool?.ibeacon.major}>
                        <Typography>{singleUpdatedTool?.ibeacon.major}</Typography>
                      </Tooltip>
                    </Grid>
                    Minor
                    <Grid item container>
                      <Tooltip title={'Minor'}>
                        <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                          Minor:
                        </Typography>
                      </Tooltip>
                      <Tooltip title={singleUpdatedTool?.ibeacon.minor}>
                        <Typography>{singleUpdatedTool?.ibeacon.minor}</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </Grid>

            <Grid
              container
              direction="column"
              style={{
                width: 'calc(100% - 10vw)',
                marginTop: '20px',
                display: 'grid',
                gap: '2%',
                gridTemplateColumns: '49% 49%',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={bulkList.length === 0}
                onClick={() => {
                  setOpenSaveDialog(true);
                }}
              >
                Update
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  setEditingType(undefined);
                  setSingleUpdatedTool(undefined);
                  setBulkUpdatedTool(undefined);
                  setBulkList([]);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}

      {/* Create Tool Type */}
      {selectedTab === 'TOOL_TYPES' && (
        <Container
          style={{
            paddingBottom: '45px',
          }}
        >
          <Grid
            spacing={3}
            paddingY={2}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: '60px',
              paddingBottom: '10px',
              marginLeft: '0px',
            }}
          >
            <Header icon={<Build />}>Create new tool type</Header>

            {/*Label*/}
            <Paper
              variant="outlined"
              style={{
                width: 'calc(100% - 10vw)',
                background: '#23272D',
                marginTop: '20px',
              }}
            >
              <Box padding={2}>
                <InputContainer
                  id="toolTypeId"
                  label="Label"
                  key={'toolTypeLabel'}
                  name={'toolTypeLabel'}
                  value={editingTooltype ? editingTooltype.tool : newTooltype ?? ''}
                  placeholder="Drill"
                  onChange={(e: { target: { value: string } }) => {
                    if (editingTooltype) {
                      setEditingTooltype({
                        ...editingTooltype,
                        tool: e.target.value,
                      });
                    } else {
                      setNewTooltype(e.target.value);
                    }
                  }}
                />
              </Box>
            </Paper>

            <Grid
              container
              direction="column"
              style={
                editingTooltype
                  ? {
                      width: 'calc(100% - 10vw)',
                      marginTop: '20px',
                      display: 'grid',
                      gap: '2%',
                      gridTemplateColumns: '49% 49%',
                    }
                  : {
                      width: 'calc(100% - 10vw)',
                      marginTop: '20px',
                    }
              }
            >
              <Button
                variant="contained"
                color="secondary"
                style={
                  editingTooltype
                    ? {}
                    : {
                        width: '25vw',
                        maxWidth: '500px',
                        minWidth: '255px',
                        alignSelf: 'center',
                      }
                }
                size="large"
                onClick={() => {
                  if (editingTooltype) {
                    updateToolType();
                  } else {
                    createToolType();
                  }
                }}
              >
                {editingTooltype ? 'Update' : 'Create'}
              </Button>
              {editingTooltype && (
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setEditingTooltype(undefined);
                    setNewTooltype(undefined);
                  }}
                >
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      )}

      {selectedTab === 'TOOLS' && (
        <ToolsList
          refresh={refresh}
          setRefresh={setRefresh}
          pageRef={pageRef}
          page={page}
          setPage={setPage}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          openToolsFilterDialog={openToolsFilterDialog}
          setOpenToolsFilterDialog={setOpenToolsFilterDialog}
          availableTools={tools}
          setAvailableTools={setTools}
          toolsCount={toolsCount}
          setToolsCount={setToolsCount}
          toolTypes={toolTypes}
          editingType={editingType}
          setEditingType={setEditingType}
          bulkList={bulkList}
          setBulkList={setBulkList}
          singleUpdatedTool={singleUpdatedTool}
          setSingleUpdatedTool={setSingleUpdatedTool}
          setBulkUpdatedTool={setBulkUpdatedTool}
          activeTools={streamedTools}
        />
      )}

      {selectedTab === 'TOOL_TYPES' && (
        <ToolTypeList
          openToolTypeFilterDialog={openToolTypeFilterDialog}
          setOpenToolTypeFilterDialog={setOpenToolTypeFilterDialog}
          toolTypes={toolTypes}
          setToolTypes={setToolTypes}
          editingTooltype={editingTooltype}
          setEditingTooltype={setEditingTooltype}
          setNewTooltype={setNewTooltype}
          setRefreshToolTypes={setRefreshToolTypes}
        />
      )}

      <Dialog open={!!openSaveDialog} onClose={() => setOpenSaveDialog(false)}>
        <DialogTitle>
          {editingType === 'BULK' && bulkList.length === 0
            ? 'No tool selected to update'
            : 'Are you sure you want to make these changes?'}
        </DialogTitle>
        {editingType && (
          <DialogActions>
            <Button
              onClick={async () => {
                setOpenSaveDialog(false);
                (editingType === 'BULK' ? updateBulkTools() : updateTool()).then(() => {
                  setEditingType(undefined);
                  setSingleUpdatedTool(undefined);
                  setBulkUpdatedTool(undefined);
                });
              }}
            >
              Yes
            </Button>
            <Button color="secondary" onClick={() => setOpenSaveDialog(false)}>
              No
            </Button>
          </DialogActions>
        )}

        {editingType === 'BULK' && bulkList.length === 0 && (
          <DialogActions style={{ alignSelf: 'center' }}>
            <Button
              onClick={() => {
                setOpenSaveDialog(false);
              }}
            >
              OK
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {/* Duplicate Ibeacon values */}
      <Dialog open={duplicateIbeaconTools.length > 1} onClose={() => setDuplicateIbeaconTools([])}>
        <DialogTitle>Please ensure Ibeacon identifiers are unique.</DialogTitle>
        <DialogContent>
          <p>There are duplicate Ibeacon identifiers occurring on lines:</p>
          <p>{getDuplicateLines(duplicateIbeaconTools)}</p>
        </DialogContent>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              setDuplicateIbeaconTools([]);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Tools;
