import { Box, Paper, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { OVERLAY } from '../../Components/Map/MapOverlay';
import { DeviceLocation } from '../../Components/Map/Messages';
import { OverlayPortal } from './LiveMapOlFunctions';

type LocalBeacons = DeviceLocation[];

export const useLocalBeacons = () => {
  const [localBeacons, setLocalBeacons] = useState<LocalBeacons | undefined>(undefined);

  const setLocalBeaconsWrapped = useCallback(
    (localBeacons: LocalBeacons | undefined, coordinates: number[] | undefined) => {
      if (localBeacons) {
        if (coordinates) OVERLAY.setPosition(coordinates);
      } else {
        //OVERLAY.setPosition(undefined);
      }

      setLocalBeacons(localBeacons);
    },
    [setLocalBeacons],
  );

  return [localBeacons, setLocalBeaconsWrapped] as const;
};

export const LocalBeaconsPopover = ({ localBeacons }: { localBeacons: LocalBeacons }) => (
  <OverlayPortal>
    <Paper elevation={12}>
      <Box p={2} overflow={'auto'} maxWidth={275} maxHeight={300}>
        <Typography variant="h6">Nearby Beacons ({localBeacons.length})</Typography>
        {localBeacons
          .sort((a, b) => a.label?.localeCompare(b.label))
          .map((beacon, i) => (
            <Typography key={i}>{beacon.label ?? 'UNKNOWN'}</Typography>
          ))}
      </Box>
    </Paper>
  </OverlayPortal>
);
