import { Divider, Stack } from '@mui/material';
import { addMinutes, formatDistance } from 'date-fns';
import React, { ReactNode } from 'react';
import { OffsetPreTags, PaperBox, SubHeader } from '../../Common/Sidebar';
import { Contacts } from './useContacts';

export const ContactLayer = ({
  ts,
  icon,
  hits,
  title,
}: {
  icon: ReactNode;
  ts: Date;
  hits: number;
  title: ReactNode;
}) => (
  <PaperBox>
    <SubHeader icon={icon}>{title}</SubHeader>
    <Divider />
    <Stack direction="column" spacing={1} pt={1}>
      <pre>First contact: {formatDistance(ts, new Date(), { addSuffix: true })}</pre>
      <pre>Contact period: ~{formatDistance(new Date(), addMinutes(new Date(), hits * 5), {})}</pre>
    </Stack>
  </PaperBox>
);
