import { FilterList, Search, Sort } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Header } from '../../Common/Sidebar';
import InputContainer from '../Global/InputContainer';
import {
  OrderTypeValue,
  PortableAsset,
  ToolFilter,
  ToolOrderByTypeValue,
  ToolType,
} from '../Map/types';
import { OrderTypes, ToolOrderByTypes } from '../Map/values';

export const ToolsFilterDialog = ({
  setOpenToolsFilterDialog,
  setAvailableTools,
  toolFilter,
  setToolFilter,
  toolTypes,
  setRefresh,
  setPage,
}: {
  setOpenToolsFilterDialog: Dispatch<SetStateAction<boolean>>;
  setAvailableTools: Dispatch<SetStateAction<PortableAsset[]>>;
  toolFilter: ToolFilter;
  setToolFilter: Dispatch<SetStateAction<ToolFilter>>;
  toolTypes: ToolType[];
  setRefresh: Dispatch<SetStateAction<boolean>>;
  setPage: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <>
      {/*Filter*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '25px 0px 5px 25px',
        }}
      >
        <Header icon={<FilterList />}>Filters</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {/*Tool Type*/}
        <FormControl
          fullWidth
          style={{
            marginTop: 20,
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="tool-type-option">Tool Type</InputLabel>
          <Select
            fullWidth
            labelId="tool-type-action"
            id="tool-type-dropdown"
            value={toolFilter?.toolType ?? ''}
            label="Tool Type"
            onChange={e => {
              const toolType = toolTypes.find(t => t.tool === e.target.value);
              if (!toolType) return;
              setToolFilter({ ...toolFilter, toolType: toolType.tool });
            }}
          >
            {toolTypes.map(toolType => (
              <MenuItem key={toolType.id} value={toolType.tool}>
                <Tooltip title={toolType.tool}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {toolType.tool}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/*Search*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '25px 25px 0px 25px',
        }}
      >
        <Header icon={<Search />}>Search</Header>
      </div>
      <div
        style={{
          width: '300px',
          alignSelf: 'start',
          margin: '0px 0px 25px 25px',
        }}
      >
        Search Keywords:
      </div>

      {/*Search Label*/}
      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="searchLabel"
              label="Label"
              key={'searchLabel'}
              name={'searchLabel'}
              value={toolFilter?.searchLabel ?? ''}
              onChange={(e: { target: { value: string } }) => {
                setToolFilter({
                  ...toolFilter,
                  searchLabel: e.target.value,
                });
              }}
              placeholder="Tool1"
            />
          </FormControl>
        }
      </Grid>

      {/*Search ID*/}
      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="searchId"
              label="ID"
              key={'searchId'}
              name={'searchId'}
              value={toolFilter?.searchId ?? ''}
              onChange={(e: { target: { value: string } }) => {
                setToolFilter({
                  ...toolFilter,
                  searchId: e.target.value,
                });
              }}
              placeholder="Tool-111"
            />
          </FormControl>
        }
      </Grid>

      {/*Sort*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '0px 0px 5px 10px',
        }}
      >
        <Header icon={<Sort />}>Sort</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {/*Sort By*/}
        <FormControl
          fullWidth
          style={{
            marginTop: 20,
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="sort-option">Sort By</InputLabel>
          <Select
            fullWidth
            labelId="sort-action"
            id="sort-dropdown"
            value={toolFilter?.orderBy?.value ?? ''}
            label="Sort By"
            onChange={e => {
              const orderBy = ToolOrderByTypes.find(
                l => l.value === (e.target.value as ToolOrderByTypeValue),
              );
              if (!orderBy) return;
              setToolFilter({
                ...toolFilter,
                orderBy,
              });
            }}
          >
            {ToolOrderByTypes.map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Order*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="order-option">Order</InputLabel>
          <Select
            fullWidth
            labelId="order-action"
            id="order-dropdown"
            value={toolFilter?.order?.value ?? ''}
            label="Order"
            onChange={e => {
              const order = OrderTypes.find(l => l.value === (e.target.value as OrderTypeValue));
              setToolFilter({
                ...toolFilter,
                order,
              });
            }}
          >
            {OrderTypes.map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          alignSelf: 'end',
          margin: '25px',
          width: 'fit-content',
          display: 'grid',
          gap: '6%',
          gridTemplateColumns: '30% 30% 30%',
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => {
            setToolFilter({
              perPage: 50,
            });
          }}
        >
          Clear
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => setOpenToolsFilterDialog(false)}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            setAvailableTools([]);
            setPage(1);
            setRefresh(true);
            setOpenToolsFilterDialog(false);
          }}
        >
          Search
        </Button>
      </Grid>
    </>
  );
};
