import { Dispatch } from 'react';
import { StrongFeatureHolder } from '../../hooks/geomoby/useLiveMapLoader';
import {
  CreatorFilter,
  GeofenceFilter,
  LayerFilter,
  MicrofenceFilter,
  OrderTypeValue,
  SearchType,
  SearchTypeValue,
  ToolFilter,
  ToolType,
} from './types';
import { LayerFenceData, MicrofenceData } from '../../Components/Map/Messages';
import { GridRowData } from '@material-ui/data-grid';
import { LiveMapGeofenceFilter } from './LiveMapGeofenceFilter';
import { LiveMapMicrofenceFilter } from './LiveMapMicrofenceFilter';
import { Point } from 'ol/geom';
import { LiveMapBeaconFilter } from './LiveMapBeaconFilter';
import { LiveMapDeviceOrTrackerFilter } from './LiveMapDeviceOrTrackerFilter';
import { LiveMapToolFilter } from './LiveMapToolFilter';

export const LiveMapFilter = ({
  searchType,
  layerFilter,
  setLayerFilter,
  creatorFilter,
  setCreatorFilter,
  toolFilter,
  setToolFilter,
  geofenceFilter,
  setGeofenceFilter,
  microfenceFilter,
  setMicrofenceFilter,
  selectedMicrofence,
  clearFilter,
  setClearFilter,
  showFilter,
  setShowFilter,
  setRefreshSearch,
  toolTypes,
  setToolTypes,
}: {
  searchType: SearchType | undefined;
  layerFilter: LayerFilter | undefined;
  setLayerFilter: Dispatch<LayerFilter | undefined>;
  creatorFilter: CreatorFilter;
  setCreatorFilter: Dispatch<CreatorFilter>;
  toolFilter: ToolFilter;
  setToolFilter: Dispatch<ToolFilter>;
  geofenceFilter: GeofenceFilter;
  setGeofenceFilter: Dispatch<GeofenceFilter>;
  microfenceFilter: MicrofenceFilter | undefined;
  setMicrofenceFilter: Dispatch<MicrofenceFilter | undefined>;
  selectedMicrofence: GridRowData | undefined;
  clearFilter: boolean;
  setClearFilter: Dispatch<boolean>;
  showFilter: boolean;
  setShowFilter: Dispatch<boolean>;
  setRefreshSearch: Dispatch<boolean>;
  toolTypes: ToolType[];
  setToolTypes: Dispatch<ToolType[]>;
}) => {
  return (
    <>
      {searchType?.id === 'BEACONS' && (
        <LiveMapBeaconFilter
          creatorFilter={creatorFilter}
          setCreatorFilter={setCreatorFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></LiveMapBeaconFilter>
      )}

      {(searchType?.id === 'DEVICES' || searchType?.id === 'GPSTRACKERS') && (
        <LiveMapDeviceOrTrackerFilter
          creatorFilter={creatorFilter}
          setCreatorFilter={setCreatorFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></LiveMapDeviceOrTrackerFilter>
      )}

      {searchType?.id === 'GEOFENCES' && (
        <LiveMapGeofenceFilter
          layerFilter={layerFilter}
          setLayerFilter={setLayerFilter}
          geofenceFilter={geofenceFilter}
          setGeofenceFilter={setGeofenceFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></LiveMapGeofenceFilter>
      )}

      {searchType?.id === 'MICROFENCES' && (
        <LiveMapMicrofenceFilter
          microfenceFilter={microfenceFilter}
          setMicrofenceFilter={setMicrofenceFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></LiveMapMicrofenceFilter>
      )}

      {searchType?.id === 'TOOLS' && (
        <LiveMapToolFilter
          toolFilter={toolFilter}
          setToolFilter={setToolFilter}
          toolTypes={toolTypes}
          setToolTypes={setToolTypes}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></LiveMapToolFilter>
      )}
    </>
  );
};
