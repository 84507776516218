import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import { OVERLAY } from '../../Components/Map/MapOverlay';
import { DeviceLocation, ReportBeacon, SensedAsset } from '../../Components/Map/Messages';
import { OverlayPortal } from './LiveMapOlFunctions';
import { useAugmentedToolLabel } from '../../util/useToolsLabel';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const useSensedAssets = () => {
  const [sensedAssets, setSensedAssets] = useState<SensedAsset[] | undefined>(undefined);

  const setSensedAssetsWrapped = useCallback(
    (sensedAssets: SensedAsset[] | undefined, coordinates: number[] | undefined) => {
      if (sensedAssets) {
        if (coordinates) OVERLAY.setPosition(coordinates);
      } else {
        OVERLAY.setPosition(undefined);
      }

      setSensedAssets(sensedAssets);
    },
    [setSensedAssets],
  );

  return [sensedAssets, setSensedAssetsWrapped] as const;
};

export const SensedAssetsPopover = ({ sensedAssets }: { sensedAssets: SensedAsset[] }) => {
  const [openTools, setOpenTools] = useState<boolean>(false);
  const [openToolType, setOpenToolType] = useState<number | undefined>();
  const [openOther, setOpenOther] = useState<boolean>(false);
  const [toolTypes, setToolTypes] = useState<string[]>([]);

  return (
    <OverlayPortal>
      <Paper elevation={12}>
        <Box p={2} overflow={'auto'} width={400} maxHeight={350}>
          <Typography
            style={{
              fontSize: 'x-large',
            }}
          >
            Nearby Assets ({sensedAssets.length})
          </Typography>

          {/* When we also have tools */}
          {sensedAssets.filter(asset => asset.type === 'TOOL').length > 0 && (
            <Grid container direction={'row'}>
              {sensedAssets.length > 0 &&
                [{ type: 'TOOL' }, { type: undefined }].map((assetType, index) => {
                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      style={{
                        marginTop: '5px',
                      }}
                    >
                      <IconButton
                        style={{
                          height: '27px',
                          width: '27px',
                        }}
                        onClick={() => {
                          if (assetType.type === 'TOOL') {
                            setOpenTools(!openTools);
                            setOpenOther(false);
                          } else {
                            setOpenTools(false);
                            setOpenOther(!openOther);
                          }
                        }}
                      >
                        {(assetType.type === 'TOOL' ? openTools : openOther) ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </IconButton>
                      <Typography
                        style={{
                          fontSize: 'large',
                        }}
                      >
                        {assetType.type === 'TOOL' ? 'Tools' : 'Other Assets'} (
                        {sensedAssets.filter(asset => asset.type === assetType.type).length})
                      </Typography>

                      {assetType.type === 'TOOL' &&
                        openTools &&
                        Array.from(
                          new Set(
                            sensedAssets
                              .filter(asset => asset.type === 'TOOL')
                              .map(tool => tool.toolType),
                          ),
                        ).map((toolType, toolTypeIndex) => {
                          {
                            /* Tool Type */
                          }
                          return (
                            <Grid
                              key={toolTypeIndex}
                              container
                              style={{
                                display: 'grid',
                                gridTemplateColumns: 'max-content',
                                marginLeft: '10px',
                              }}
                            >
                              <Grid container>
                                <IconButton
                                  style={{
                                    height: '27px',
                                    width: '27px',
                                  }}
                                  onClick={() => {
                                    setOpenToolType(
                                      toolTypeIndex !== openToolType ? toolTypeIndex : undefined,
                                    );
                                  }}
                                >
                                  {openToolType === toolTypeIndex ? <ExpandMore /> : <ExpandLess />}
                                </IconButton>
                                <Typography
                                  style={{
                                    fontSize: 'large',
                                  }}
                                >
                                  {toolType} (
                                  {
                                    Array.from(
                                      new Set(
                                        sensedAssets.filter(tool => tool.toolType === toolType),
                                      ),
                                    ).length
                                  }
                                  )
                                </Typography>
                              </Grid>

                              {/* Tools */}
                              {openToolType === toolTypeIndex &&
                                assetType.type === 'TOOL' &&
                                openTools &&
                                sensedAssets
                                  .filter(
                                    asset => asset.type === 'TOOL' && asset.toolType === toolType,
                                  )
                                  .sort((a, b) => a.label.localeCompare(b.label))
                                  .map((tool, toolIndex) => {
                                    return (
                                      <Grid
                                        key={toolIndex}
                                        container
                                        direction="row"
                                        style={{
                                          margin: '0px 0px 3px 10px',
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: 'medium',
                                            marginLeft: '10px',
                                          }}
                                          key={`${toolIndex}_${tool.label}`}
                                        >
                                          {tool.label}
                                        </Typography>
                                      </Grid>
                                    );
                                  })}
                            </Grid>
                          );
                        })}

                      {/* Other Assets */}
                      {assetType.type !== 'TOOL' &&
                        openOther &&
                        sensedAssets
                          .filter(asset => asset.type === undefined)
                          .sort((a, b) => a.label.localeCompare(b.label))
                          .map((asset, i) => (
                            <Typography
                              style={{
                                fontSize: 'medium',
                                margin: '0px 0px 3px 20px',
                              }}
                              key={`${i}_${asset.label}`}
                            >
                              {asset.label}
                            </Typography>
                          ))}
                    </Grid>
                  );
                })}
            </Grid>
          )}

          {/* When we have no tools */}
          {sensedAssets.filter(asset => asset.type === 'TOOL').length === 0 && (
            <Grid container direction={'row'}>
              {sensedAssets
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((asset, i) => (
                  <Typography key={`${i}_${asset.label}`}>{asset.label}</Typography>
                ))}
            </Grid>
          )}
        </Box>
      </Paper>
    </OverlayPortal>
  );
};
