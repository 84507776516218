import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { Feature, Map } from 'ol';
import Point from 'ol/geom/Point';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { RefObject, useEffect, useRef, useState } from 'react';
import { MapContainer } from '../../Components/Map/MapContainer/MapContainer';
import {
  createMap,
  createOutdoorMapDefaults,
  initialLatitude,
  initialLongitude,
  MapSourceType,
} from '../../Components/Map/MapDefaults';
import { ChangeMapSourceType } from '../../Components/Map/Toolbar/LayerTools/ChangeMapSourceType';
import { MapToolbar } from '../../Components/Map/Toolbar/MapToolbar';
import { ZoomIn } from '../../Components/Map/Toolbar/ZoomTools/ZoomIn';
import { ZoomOut } from '../../Components/Map/Toolbar/ZoomTools/ZoomOut';
import { MapType } from '../../hooks/geomoby/LiveMapRenderer';
import { MAP_API_KEYS } from '../../store/map';
import { HeatMap as HeatMapType } from './useHeatMap';

const useMap = ({
  source,
  id,
  ref,
}: {
  source: MapSourceType;
  id: MapType;
  ref: RefObject<HTMLDivElement>;
}): { map?: Map } => {
  const mapApiKeys = useAtomValue(MAP_API_KEYS);
  const [{ map, setSource }, setState] = useState<{
    map?: Map;
    setSource?: (source: MapSourceType) => void;
  }>({});

  useEffect(
    () => {
      const map = createMap(
        id,
        createOutdoorMapDefaults({
          sourceType: source,
          edit: false,
          specifiedCoordinates: [initialLongitude, initialLatitude],
          mapApiKeys,
        }),
        ref,
      );
      setState(map);
      return () => map.map.dispose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, ref],
  );

  useEffect(() => {
    if (setSource) setSource(source);
  }, [source, setSource]);

  return { map };
};

export const HeatMap = ({ data }: { data?: HeatMapType }) => {
  const id = 'OUTDOOR_HEAT_MAP';
  const ref = useRef<HTMLDivElement>(null);
  const [source, setSource] = useState<MapSourceType>('Terrain & Roads');
  const { map } = useMap({ id, source, ref });

  useEffect(() => {
    if (!map || !data) return () => {};

    const pointSource = new VectorSource();

    data.points.forEach(({ intensity, longitude, latitude }) => {
      pointSource.addFeature(
        new Feature({
          geometry: new Point(transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857')),
          weight: intensity,
        }),
      );
    });

    const radiusM = data.radius * 100000;
    let heatMapLayer: HeatmapLayer | undefined = new HeatmapLayer({
      source: pointSource,
      radius: radiusM,
      opacity: 0.7,
    });

    const extent = pointSource.getExtent();
    if (extent && !extent.some(value => value === Infinity || value === -Infinity))
      map.getView().fit(extent, { padding: [50, 50, 50, 50] });

    map.getView().on(
      'change:resolution',
      _.debounce(event => {
        if (heatMapLayer) {
          const resolution = (event.target as { values_: { resolution: number } }).values_
            .resolution;
          const radius = Math.max(15, radiusM / resolution);
          if (heatMapLayer.get('radius') !== radius) heatMapLayer.set('radius', radius);
        }
      }, 10),
    );

    map.addLayer(heatMapLayer);

    return () => {
      if (heatMapLayer) map.removeLayer(heatMapLayer);
      heatMapLayer = undefined;
    };
  }, [data, map]);

  return (
    <MapContainer id={id} ref={ref}>
      <MapToolbar>
        <ChangeMapSourceType
          current={source}
          setType={value => {
            setSource(value);
          }}
        />
        <ZoomIn
          onClick={() => {
            map?.getView().adjustZoom(0.5);
          }}
        />
        <ZoomOut
          onClick={() => {
            map?.getView().adjustZoom(-0.5);
          }}
        />
      </MapToolbar>
    </MapContainer>
  );
};
