import { ConnectWithoutContact, DoNotTouch, PanTool } from '@mui/icons-material';
import { DateRangePicker, LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  CircularProgress,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { subDays } from 'date-fns';
import React, { useState } from 'react';
import { Header, Item, Sidebar } from '../Common/Sidebar';
import { SidebarAndMap } from '../Components/Map/SidebarAndMap/SidebarAndMap';
import { HeatMap } from './heatmap/HeatMap';
import { useHeatMap } from './heatmap/useHeatMap';
import { ContactLayer } from './tree/ContactLayer';
import { useContacts } from './tree/useContacts';
import { Creator, useCreators } from './useCreators';

export const ContactTracing = () => {
  const { data: ids, loading: loadingIds, error: errorIds } = useCreators();

  const [creator, setCreator] = useState<Creator | undefined>();
  const [range, setRange] = useState<[Date | null, Date | null]>([
    subDays(new Date(), 15),
    new Date(),
  ]);

  const {
    go: computeLevels,
    data: levels,
    loading: loadingLevels,
    error: errorLevels,
    computedLevels,
  } = useContacts({
    from: range[0],
    to: range[1],
    id: creator?.id,
  });

  const {
    go: goHeatMap,
    data: heatMap,
    loading: loadingHeatMap,
    error: errorHeatMap,
  } = useHeatMap({ from: range[0], to: range[1], id: creator?.id });

  const level1s = levels?.filter(c => c.indirection === 2) ?? [];
  const level2s = levels?.filter(c => c.indirection === 3) ?? [];

  return (
    <SidebarAndMap
      sidebar={
        <Sidebar>
          <Item>
            <Header icon={<ConnectWithoutContact />}>Contact Tracing</Header>
            <Autocomplete
              options={ids ?? []}
              getOptionLabel={o =>
                o.uniqueLabel && o.identifiers.uuid
                  ? `[beacon] ${o.label}`
                  : o.identifiers.uuid
                  ? `[beacon] ${o.label} (${o.identifiers.major}.${o.identifiers.minor}.${String(
                      o.identifiers.uuid,
                    )})`
                  : `[device] ${o.label}`
              }
              isOptionEqualToValue={(a, b) => a.id === b.id}
              onChange={(_, value) => setCreator(value ?? undefined)}
              loading={!ids}
              renderInput={params => (
                <TextField
                  {...params}
                  error={!!errorIds}
                  label="Find beacon or device to trace"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: !ids && <CircularProgress color="inherit" size={20} />,
                  }}
                />
              )}
            />
            <DateRangePicker
              value={range}
              onChange={value => setRange(value)}
              disableFuture
              renderInput={(startProps, endProps) => (
                <Stack direction="row" spacing={2}>
                  <TextField {...startProps} label="Start date" />
                  <TextField {...endProps} label="End date" />
                </Stack>
              )}
            />
            {errorIds && (
              <Typography color="error">
                Error while retrieving list of IDs, please try again later.
              </Typography>
            )}
            <LoadingButton
              color="secondary"
              variant="contained"
              onClick={() => {
                computeLevels();
                goHeatMap();
              }}
              disabled={!creator || !range[0] || !range[1]}
              loading={loadingLevels || loadingHeatMap}
            >
              Perform Contact Trace
            </LoadingButton>
            {errorHeatMap && (
              <Typography color="error">
                Error while performing heat map trace, please try again later.
              </Typography>
            )}
          </Item>
          {levels && (
            <>
              <Item>
                <Header icon={<PanTool color="error" />}>Direct contacts</Header>
                {loadingLevels && !level1s.length && <Skeleton height={200} width="100%" />}
                {errorLevels && (
                  <Typography color="error">
                    Error while trying to perform contact trace, please try again later.
                  </Typography>
                )}
                {level1s.map(contact => (
                  <ContactLayer
                    key={contact.creatorId}
                    icon={<PanTool color="error" fontSize="small" />}
                    title={contact.label}
                    hits={contact.hits}
                    ts={contact.earliestContact}
                  />
                ))}
                {computedLevels >= 1 && level1s.length === 0 && (
                  <Typography textAlign="center">No direct contacts for ID.</Typography>
                )}
              </Item>
              <Item>
                <Header icon={<DoNotTouch />}>In-direct contacts</Header>
                {loadingLevels && !level2s.length && <Skeleton height={200} width="100%" />}
                {errorLevels && (
                  <Typography color="error">
                    Error while trying to perform contact trace, please try again later.
                  </Typography>
                )}
                {level2s.map(contact => (
                  <ContactLayer
                    key={contact.creatorId}
                    icon={<DoNotTouch fontSize="small" />}
                    title={contact.label}
                    hits={contact.hits}
                    ts={contact.earliestContact}
                  />
                ))}
                {!loadingLevels && computedLevels < 2 && (
                  <Typography color="error" textAlign="center">
                    Too many direct contacts to compute in-direct contacts.
                  </Typography>
                )}
                {computedLevels >= 2 && level2s.length === 0 && (
                  <Typography textAlign="center">No in-direct contacts for ID.</Typography>
                )}
              </Item>
            </>
          )}
        </Sidebar>
      }
      map={<HeatMap data={heatMap} />}
    />
  );
};
