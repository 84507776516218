import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';

export const useAuthedStream = (tokenUrl: string, streamUrl: string, playing = true) => {
  const [stream, setStream] = useState<EventSource | undefined>(undefined);
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const [failed, setFailed] = useState(0);

  useEffect(() => {
    if (!playing) return;

    const sourcePromise = (async () => {
      const {
        data: { token },
      } = await axios.post(`${tokenUrl}`, {}, authedRequestConfig);

      const qOrA = streamUrl.includes('?') ? '&' : '?';

      return new EventSource(`${streamUrl}${qOrA}token=${token}`);
    })();

    sourcePromise.then(source => {
      setStream(source);
      source.addEventListener('error', async () => {
        source.close();
        console.warn('Lost connection to livestream');
        setTimeout(() => {
          setFailed(currentFailed => Math.max(currentFailed, failed + 1));
        }, 1000);
      });
    });

    sourcePromise.catch(() => {
      console.warn('Failed to connect to livestream');
      setTimeout(() => {
        setFailed(failed => failed + 1);
      }, 1000);
    });

    return () => {
      sourcePromise.then(source => source.close());
    };
  }, [tokenUrl, streamUrl, playing, authedRequestConfig, failed, setFailed]);

  return stream;
};

export const useStreamMessages = <T>(stream?: EventSource, onMessage?: (data: T) => void) => {
  useEffect(() => {
    if (!onMessage) return;

    let t = new Date().getTime();

    const onMessageRef = function (this: EventSource, message: MessageEvent<unknown>) {
      const now = new Date().getTime();
      const timeout = Math.max(0, 100 - (now - t));
      t = now;
      setTimeout(() => onMessage(JSON.parse(String(message.data)) as T), timeout);
    };

    if (onMessageRef) stream?.addEventListener('message', onMessageRef);
    return () => onMessageRef && stream?.removeEventListener('message', onMessageRef);
  }, [stream, onMessage]);
};
