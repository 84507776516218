import { Atom, atom, useAtomValue } from 'jotai';

export type KnownTool = {
  clientId: string;
  projectId: string;
  id: string;
  label: string;
  type: 'tool';
  tool: string;
  model: null;
  createdAt: string;
  updatedAt?: string;
  ibeacon?: {
    uuid: string;
    major: number;
    minor: number;
  };
};

export const KNOWN_TOOLS = atom<KnownTool[]>([]);

export const KNOWN_TOOLS_BY_ID: Atom<Record<string, KnownTool | undefined>> = atom(get =>
  Object.fromEntries(get(KNOWN_TOOLS).map(tool => [tool.id, tool])),
);
