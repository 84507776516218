import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
} from 'react';
import olMap from 'ol/Map';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { TRIGGERS_URL } from '../../../store/url';
import { CID, PID } from '../../../store/user';
import { MICROFENCE, MICROFENCE_LAYER_ID, MICROFENCE_LAYER_LABEL } from './../BeaconUtils';
import {
  GeofenceFilter,
  LayerFilter,
  MicrofenceFilter,
  MicrofenceType,
  SearchType,
  SearchTypeValue,
  FenceZoneTypeValue,
  FenceZoneType,
  MicrofenceZoneTypeValue,
} from './../types';
import { SearchTypes, FenceZoneTypes, MicrofenceZoneTypes } from './../values';
import Polygon from 'ol/geom/Polygon';
import { MicrofenceData } from '../../../Components/Map/Messages';
import { Geometry, LineString, MultiPolygon, Point } from 'ol/geom';
import { GridRowData } from '@material-ui/data-grid';
import {
  Assignment,
  Build,
  Cancel,
  CheckCircle,
  Clear,
  DashboardOutlined,
  Delete,
  Download,
  Edit,
  FilterList,
  LocationOn,
  NearMe,
  Search,
  Shield,
  ShieldOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { CenteredProgress } from '../../../Common/Sidebar';
import { Extent } from 'ol/extent';
import { none, some } from 'fp-ts/es6/Option';
import { transformExtent } from 'ol/proj';
import { Feature } from 'ol';
import { fromNullable, toNullable, Option, isNone, isSome } from 'fp-ts/es6/Option';
import {
  LocationDisplayType,
  LocationSearch,
  LocationSearchData,
} from './../Toolbar/LocationSearch';
import { Map as OlMap } from 'ol';
import { truncationStyle } from '../../Map/AssetRenderer';
import { useMobile } from '../../../util/useMobile';
import { option } from 'fp-ts';
import InputContainer from '../../Global/InputContainer';
import { getFenceLabel } from './labelling';
import {
  BufferShapeType,
  DrawType,
  EditType,
  FenceZone,
  MicrofenceZone,
  ReassignedFence,
} from '../MapDefaults';
import VectorSource from 'ol/source/Vector';
import { getFenceIcon, getMicroFenceIcon } from '../LiveMapSearch';
import {
  DEFAULT_BUFFER_METERS,
  FRESH,
  FRESH_UNKNOWN_LAYER,
} from '../GeofenceEditor/GeofenceEditor';
import { MicrofenceAssetId, NameId } from '../GeofenceEditor/GeofenceEditorFunctions';
import { debounce } from 'lodash';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';

const LoadingCircle = () => {
  return (
    <Grid
      container
      justifyContent={'center'}
      style={{
        marginTop: '100px',
      }}
    >
      <CenteredProgress />
    </Grid>
  );
};

export const DataTable = ({
  rows,
}: {
  rows: { index: number; name: string; values: string }[];
}) => (
  <TableContainer component={Paper}>
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell style={{ fontSize: '15px' }}>Property</TableCell>
          <TableCell style={{ fontSize: '15px' }} align="right">
            Values
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length === 0 && (
          <TableRow>
            <TableCell>No properties found</TableCell>
          </TableRow>
        )}
        {rows.map(row => (
          <TableRow
            key={row.name}
            sx={{ fontSize: '15px', '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <Tooltip title={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
            </Tooltip>
            <Tooltip title={row.values}>
              <TableCell align="right">{row.values}</TableCell>
            </Tooltip>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export const GeofenceEditorSearch = ({
  layerIds,
  setLayerIds,
  layers,
  setLayers,
  availableGeofences,
  setAvailableGeofences,
  layersFromMap,
  freshGeofences,
  availableMicrofences,
  setAvailableMicrofences,
  microfences,
  selectedGeofence,
  setSelectedGeofence,
  selectedMicrofence,
  setSelectedMicrofence,
  setExtent,
  searchType,
  setSearchType,
  layerFilter,
  setLayerFilter,
  geofenceFilter,
  setGeofenceFilter,
  microfenceFilter,
  setMicrofenceFilter,
  clearFilter,
  setClearFilter,
  showFilter,
  setShowFilter,
  showGhostGeofences,
  setShowGhostGeofences,
  createEditFence,
  setCreateEditFence,
  createEditLayer,
  setCreateEditLayer,
  refreshSearch,
  setRefreshSearch,
  setLocationSearchData,
  currentCenter,
  locationDisplay,
  setLocationDisplay,
  setDrawType,
  layersHaveChanged,
  setLayersHaveChanged,
  selectedLayer,
  setSelectedLayer,
  setRenamingLayer,

  reassignedFences,
  setReassignedFences,
  setDirtySave,
  dirtySave,
  openGenericDialog,
  setOpenGenericDialog,
  isLoading,
  setIsLoading,
  mapIsLoading,
  setNavigateTo,
  selectedFromMap,
  setSelectedFromMap,
  count,
  setCount,
  hasFences,
  deselectFence,
  setDeselectFence,
  createNewLayer,
  deleteLayer,
  deleteFence,
  changeVisibility,
  editing,
  setEditing,
  unsetEditing,
  deselectAllFences,
  moveUnknownFenceToExistingLayer,
  resetLayerChanges,
  saveLayerChanges,
  updateFenceIdentifiers,
  updateGeomobyProperties,
  setAsBufferZone,
  removeBufferZone,
  setAsBreachZone,
  unsetAsBreachZone,
  setAsClearedZone,
  unsetAsClearedZone,
}: {
  layerIds: NameId[];
  setLayerIds: Dispatch<SetStateAction<NameId[]>>;
  layers: Option<Map<string, { source: VectorSource<Geometry>; name: string }>>;
  setLayers: Dispatch<
    SetStateAction<Option<Map<string, { source: VectorSource<Geometry>; name: string }>>>
  >;
  availableGeofences: GridRowData[];
  setAvailableGeofences: Dispatch<SetStateAction<GridRowData[]>>;
  layersFromMap: VectorLayer<VectorSource<Geometry>>[];
  freshGeofences: GridRowData[];
  availableMicrofences: GridRowData[];
  setAvailableMicrofences: Dispatch<SetStateAction<GridRowData[]>>;
  microfences: Feature<Geometry>[];
  selectedGeofence: GridRowData | undefined;
  setSelectedGeofence: Dispatch<GridRowData | undefined>;
  selectedMicrofence: GridRowData | undefined;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  setExtent: Dispatch<SetStateAction<Option<Extent>>>;
  searchType: SearchType | undefined;
  setSearchType: Dispatch<SearchType | undefined>;
  layerFilter: LayerFilter | undefined;
  setLayerFilter: Dispatch<LayerFilter | undefined>;
  geofenceFilter: GeofenceFilter;
  setGeofenceFilter: Dispatch<GeofenceFilter>;
  microfenceFilter: MicrofenceFilter | undefined;
  setMicrofenceFilter: Dispatch<MicrofenceFilter | undefined>;
  clearFilter: boolean;
  setClearFilter: Dispatch<boolean>;
  showFilter: boolean;
  setShowFilter: Dispatch<boolean>;
  showGhostGeofences: boolean;
  setShowGhostGeofences: Dispatch<boolean>;
  createEditFence: 'CREATE' | 'EDIT' | undefined;
  setCreateEditFence: Dispatch<'CREATE' | 'EDIT' | undefined>;
  createEditLayer: 'CREATE' | 'EDIT' | undefined;
  setCreateEditLayer: Dispatch<'CREATE' | 'EDIT' | undefined>;
  refreshSearch: boolean;
  setRefreshSearch: Dispatch<boolean>;
  setLocationSearchData: Dispatch<SetStateAction<LocationSearchData | undefined>>;
  currentCenter: number[] | undefined;
  locationDisplay: LocationDisplayType | undefined;
  setLocationDisplay: Dispatch<SetStateAction<LocationDisplayType | undefined>>;
  setDrawType: Dispatch<SetStateAction<option.Option<DrawType>>>;
  layersHaveChanged: boolean;
  setLayersHaveChanged: Dispatch<SetStateAction<boolean>>;
  selectedLayer: option.Option<NameId>;
  setSelectedLayer: (l: option.Option<string>) => void;
  setRenamingLayer: Dispatch<SetStateAction<string | null>>;
  reassignedFences: ReassignedFence[];
  setReassignedFences: Dispatch<SetStateAction<ReassignedFence[]>>;
  setDirtySave: Dispatch<
    SetStateAction<{
      isDirty: boolean;
      issue: string | null;
    }>
  >;
  dirtySave: { isDirty: boolean; issue: string | null };
  openGenericDialog: boolean;
  setOpenGenericDialog: Dispatch<boolean>;
  isLoading: boolean;
  setIsLoading: Dispatch<boolean>;
  mapIsLoading: boolean;
  setNavigateTo: Dispatch<SetStateAction<string | null>>;
  selectedFromMap: boolean;
  setSelectedFromMap: Dispatch<boolean>;
  count: number;
  setCount: Dispatch<SetStateAction<number>>;
  hasFences: boolean;
  deselectFence: boolean;
  setDeselectFence: Dispatch<boolean>;

  createNewLayer: (newLayer: string) => string;
  deleteLayer: () => Promise<void>;
  deleteFence: (id?: string) => Promise<void>;
  changeVisibility: (lid: string, visible: boolean, opacity?: number) => void;
  editing: boolean;
  setEditing: () => void;
  unsetEditing: () => void;
  deselectAllFences: () => void;
  moveUnknownFenceToExistingLayer: () => void;
  resetLayerChanges: () => Promise<void>;
  saveLayerChanges: () => Promise<void>;
  updateFenceIdentifiers: (
    name: string,
    fenceZone: FenceZone | undefined,
    assetId: MicrofenceAssetId | undefined,
    microfenceZone: MicrofenceZone | undefined,
  ) => Promise<void>;
  updateGeomobyProperties: (
    geomobyProperties: Record<string, string>,
  ) => Promise<Record<string, string> | undefined>;
  setAsBufferZone: (fenceId: string) => Promise<void>;
  removeBufferZone: (fenceId: string) => Promise<void>;
  setAsBreachZone: (fenceId: string, offset: number, bufferShape: BufferShapeType) => Promise<void>;
  unsetAsBreachZone: (fenceId: string) => Promise<void>;
  setAsClearedZone: (fenceId: string) => Promise<void>;
  unsetAsClearedZone: (fenceId: string) => Promise<void>;
}) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const triggersUrl = useAtomValue(TRIGGERS_URL);

  const [bufferOffset, setBufferOffset] = useState<number | undefined>();
  const [bufferShape, setBufferShape] = useState<BufferShapeType>('Circle');
  const [deleting, setDeleting] = useState<'LAYER' | 'GEOFENCE' | 'MICROFENCE' | undefined>();
  const [displayGeomobyProperties, setDisplayGeomobyProperties] = useState<
    { index: number; name: string; values: string }[]
  >([]);
  const [fenceNameInput, setFenceNameInput] = useState<string>('');
  const [layerNameInput, setLayerNameInput] = useState<string>('');
  const [layerConflict, setLayerConflict] = useState<boolean>(false);
  const [microfenceIdInput, setMicrofenceIdInput] = useState<string>('');
  const [microfenceMajorInput, setMicrofenceMajorInput] = useState<number>(0);
  const [microfenceMinorInput, setMicrofenceMinorInput] = useState<number>(0);
  const [microfenceZoneInput, setMicrofenceZoneInput] = useState<MicrofenceZone | undefined>();
  const [properties, setProperties] = useState<{ label: string; id: string; isCustom: boolean }[]>(
    [],
  );
  const [reassigningToLayer, setReassigningToLayer] = useState<boolean>(false);
  const [reassignedLayerId, setReassignedLayerId] = useState<string>('');
  const [updatingBreachZone, setUpdatingBreachZone] = useState(false);

  const isMobile = useMobile();
  const deletedFenceIdsRef = useRef<string[]>([]);
  const bufferOffsetRef = useRef<HTMLInputElement>(null);
  const fenceNameRef = useRef<HTMLInputElement>(null);
  const layerNameRef = useRef<HTMLInputElement>(null);
  const microfenceIdRef = useRef<HTMLInputElement>(null);
  const microfenceMajorRef = useRef<HTMLInputElement>(null);
  const microfenceMinorRef = useRef<HTMLInputElement>(null);
  const pageRef = useRef<number>(1);

  const isLine = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase()?.includes('line') ||
      selectedGeofence?.points?.type?.toLowerCase()?.includes('line'),
    [selectedGeofence],
  );
  const isPolygon = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase() === 'polygon' ||
      selectedGeofence?.points?.type?.toLowerCase() === 'polygon',
    [selectedGeofence],
  );
  const isMultipolygon = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase()?.includes('multipolygon') ||
      selectedGeofence?.points?.type?.toLowerCase()?.includes('multipolygon'),
    [selectedGeofence],
  );

  const getGeofence = useCallback(
    async (fence: GridRowData) => {
      if (!selectedGeofence) return;
      setSelectedMicrofence(undefined);
      const type = isPolygon ? 'polygon' : isMultipolygon ? 'multipolygon' : 'line';
      if (!type) return;

      const geofence = (
        await axios.get<{
          id: string;
          name: string;
          points: { coordinates: Extent };
          geomobyProperties: Record<string, string>;
        }>(
          `${triggersUrl}/${cid}/${pid}/geofences/${fence.layerId}/${type}/${fence.id}`,
          authedConfig,
        )
      ).data;

      if (!geofence) return;
      const newFence = isPolygon
        ? new Polygon(geofence.points.coordinates)
        : isMultipolygon
        ? new MultiPolygon(geofence.points.coordinates)
        : new LineString(geofence.points.coordinates);

      const extent = transformExtent(newFence.getExtent(), 'EPSG:4326', 'EPSG:3857');
      setExtent(some(extent));
      return geofence;
    },
    [
      cid,
      pid,
      triggersUrl,
      authedConfig,
      setExtent,
      setSelectedMicrofence,
      selectedGeofence,
      isPolygon,
      isMultipolygon,
    ],
  );

  const paginateGeofences = useCallback(
    async (filter: GeofenceFilter, refresh?: boolean) => {
      if (layerIds.length === 0) {
        setAvailableGeofences([]);
        setCount(0);
        return;
      }

      const filterAllLayers = filter?.layerId === 'ALL';
      if (filterAllLayers) {
        filter.layerId = undefined;
      }
      setCount(0);
      if (pageRef.current === 1) {
        setIsLoading(true);
      }

      let filters = `?perPage=${filter.perPage}`;
      Object.entries(filter).map(([key, value]) => {
        if (key !== undefined && value !== undefined && key !== 'perPage') {
          filters = filters.concat(
            `&${key}=${typeof value === 'object' ? value.id : value.toString()}`,
          );
        }
      });

      if (layerIds.length === 1 && layerIds[0].id === MICROFENCE_LAYER_ID) {
        setIsLoading(false);
        return;
      }
      const { geofences, count } = (
        await axios.get<{ geofences: GridRowData[]; count: number }>(
          `${triggersUrl}/${cid}/${pid}/geofences/paginate/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;

      const currentLayerFromMap = layersFromMap.find(
        l => l.getSource().get('id') === filter.layerId,
      ) as VectorLayer<VectorSource<Geometry>>;
      const fencesFromMap = geofences.map(fence => {
        const fenceFromMap: Feature<Geometry> | undefined = currentLayerFromMap
          ?.getSource()
          .getFeatures()
          .find(f => f.get('id') === fence.id);
        return fenceFromMap?.getProperties() ?? fence;
      });

      let result = [];
      if (pageRef.current === 1) {
        if (filter.layerId && reassignedFences.length > 0 && !refresh) {
          result = fencesFromMap.filter(fence => {
            return (
              fence.layerId === filter.layerId &&
              !reassignedFences.find(f => f.id === fence.id) &&
              !deletedFenceIdsRef.current.find(id => id === fence.id)
            );
          });
        } else {
          result = fencesFromMap.filter(
            fence => !deletedFenceIdsRef.current.find(id => id === fence.id),
          );
        }
      } else {
        const nextPage = [...availableGeofences, ...fencesFromMap];
        result = nextPage.filter(fence => !deletedFenceIdsRef.current.find(id => id === fence.id));
      }

      setAvailableGeofences(
        !refresh
          ? [
              ...result,
              ...freshGeofences.filter(
                fence => !deletedFenceIdsRef.current.find(id => id === fence.id),
              ),
            ].sort((a, b) => a.name.localeCompare(b.name))
          : result,
      );

      setCount(count);
      setIsLoading(false);
    },
    [
      cid,
      pid,
      triggersUrl,
      authedConfig,
      availableGeofences,
      layersFromMap,
      freshGeofences,
      layerIds,
      reassignedFences,
      setAvailableGeofences,
      setCount,
      setIsLoading,
    ],
  );

  const getGeofenceProperties = useCallback(async () => {
    const defaultProperties = (
      await axios.get<{ label: string }[]>(
        `${triggersUrl}/${cid}/${pid}/geofences/properties/geomoby`,
        authedConfig,
      )
    ).data;
    const projectProperties = (
      await axios.get<{ label: string; id: string }[]>(
        `${triggersUrl}/${cid}/${pid}/geofences/properties/project`,
        authedConfig,
      )
    ).data;

    setProperties(
      [
        ...defaultProperties.map(p => {
          return { id: p.label, label: p.label, isCustom: false };
        }),
        ...projectProperties.map(p => {
          return {
            ...p,
            isCustom: true,
          };
        }),
      ].sort((a, b) => a.label.localeCompare(b.label)),
    );
  }, [cid, pid, triggersUrl, authedConfig]);

  const refreshFilter = useCallback(() => {
    setAvailableGeofences([]);
    setAvailableMicrofences(microfences.map(fence => fence.getProperties()));
    setCount(0);
    setGeofenceFilter({ perPage: 50 });
    setMicrofenceFilter(undefined);
    pageRef.current = 1;

    if (searchType?.id === 'GEOFENCES') {
      paginateGeofences({ perPage: 50, layerId: layerFilter?.layer?.id } as GeofenceFilter);
    }
  }, [
    layerFilter,
    paginateGeofences,
    setGeofenceFilter,
    microfences,
    setMicrofenceFilter,
    searchType,
    setAvailableGeofences,
    setAvailableMicrofences,
    setCount,
  ]);

  const debounceBufferOffset = useRef(
    debounce(
      async (geofence: GridRowData, newShape: BufferShapeType, offset: number) => {
        await unsetAsBreachZone(geofence.id);
        await setAsBreachZone(geofence.id, offset ?? DEFAULT_BUFFER_METERS, newShape);
        setSelectedGeofence({ ...geofence, zone: FenceZone.breach });
        setLayersHaveChanged(false);
        bufferOffsetRef.current?.focus();
      },
      2000,
      { leading: true },
    ),
  ).current;

  const clearAll = () => {
    setAvailableGeofences([]);
    setAvailableMicrofences(microfences.map(fence => fence.getProperties()));
    setCount(0);
    setGeofenceFilter({ perPage: 50 });
    setMicrofenceFilter(undefined);
    setLayerFilter(undefined);
    setSelectedLayer(none);
    pageRef.current = 1;
    setSearchType(undefined);
  };

  const saveLayer = () => {
    if (
      (isSome(selectedLayer) &&
        layerIds.find(lyr => lyr.name === layerNameInput && lyr.id !== selectedLayer.value.id)) ||
      (isNone(selectedLayer) && layerIds.find(lyr => lyr.name === layerNameInput))
    ) {
      setLayerConflict(true);
      return;
    }

    if (createEditLayer === 'CREATE') {
      // New Layer
      const id = createNewLayer(layerNameInput);
      layerIds
        ?.filter(layer => layer.id !== id)
        .forEach(layer => changeVisibility(layer.id, false));
      changeVisibility(id, true);
      setSelectedLayer(fromNullable(id));
      setAvailableGeofences([]);
    } else {
      // Existing Layer
      if (isSome(selectedLayer) && !selectedLayer.value.id.includes(FRESH)) {
        selectedLayer.value.name = layerNameInput;
        setRenamingLayer(selectedLayer.value.name);
        setLayersHaveChanged(true);
        setLayerFilter({
          ...layerFilter,
          layer: selectedLayer.value,
        });
        pageRef.current = 1;
        paginateGeofences(
          { ...geofenceFilter, layerId: selectedLayer.value.id } as GeofenceFilter,
          true,
        );
      } else {
        // Updating a new one
        if (isSome(selectedLayer) && selectedLayer.value.id.includes(FRESH)) {
          changeVisibility(String(selectedLayer.value.id), true);
          setSelectedLayer(some(selectedLayer.value.id));
          selectedLayer.value.name = layerNameInput;
          if (isSome(layers)) {
            layers.value.forEach((vl, id) => {
              if (id === selectedLayer.value.id) {
                vl.name = layerNameInput;
              }
            });
          }
          setLayerFilter({
            ...layerFilter,
            layer: selectedLayer.value,
          });
          pageRef.current = 1;
          paginateGeofences(
            {
              ...geofenceFilter,
              layerId: selectedLayer.value.id,
            } as GeofenceFilter,
            true,
          );
        }
      }
    }
    setCreateEditLayer(undefined);
    setLayerNameInput('');
  };

  const saveGeofence = async () => {
    if (!selectedGeofence) return;
    if (selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER) {
      setSelectedLayer(fromNullable(selectedGeofence?.layerId));
      moveUnknownFenceToExistingLayer();
    }
    setLayersHaveChanged(true);

    // Renaming
    if (fenceNameInput) {
      selectedGeofence.name = fenceNameInput;
      await updateFenceIdentifiers(
        selectedGeofence.name,
        selectedGeofence.zone,
        undefined,
        undefined,
      );
    }

    // Update Properties
    const updatedProperties: Record<string, string> = {};
    displayGeomobyProperties.map(prop => {
      updatedProperties[prop.name] = prop.values;
    });
    await updateGeomobyProperties(updatedProperties);
    const updatedFence = selectedGeofence;
    updatedFence.geomobyProperties = Object.assign({}, updatedProperties);
    setAvailableGeofences(
      [...availableGeofences.filter(fence => fence.id !== selectedGeofence?.id), updatedFence].sort(
        (a, b) => a.name.localeCompare(b.name),
      ),
    );

    // Reassigning
    if (
      reassignedLayerId &&
      selectedGeofence.layerId !== FRESH_UNKNOWN_LAYER &&
      selectedGeofence.previousLayer !== FRESH_UNKNOWN_LAYER &&
      (isLine || isPolygon || isMultipolygon)
    ) {
      setReassignedFences([
        ...reassignedFences.filter(f => f.id !== selectedGeofence.id),
        {
          id: selectedGeofence.id,
          newLayerId: reassignedLayerId,
          type: isPolygon ? 'polygon' : isMultipolygon ? 'multipolygon' : 'line',
        },
      ]);
    }
    setReassigningToLayer(false);
    setCreateEditFence(undefined);
  };

  const saveMicrofence = async () => {
    if (!selectedMicrofence) return;
    setLayersHaveChanged(true);

    // Rename and update ID
    let assetId;
    if (selectedMicrofence?.assetId?.deviceId !== undefined) {
      assetId = {
        deviceId: microfenceIdInput,
      };
    } else if (selectedMicrofence?.assetId?.gatewayId !== undefined) {
      assetId = {
        gatewayId: microfenceIdInput,
      };
    } else if (selectedMicrofence?.assetId?.uuid !== undefined) {
      assetId = {
        uuid: microfenceIdInput,
        major: String(microfenceMajorInput),
        minor: String(microfenceMinorInput),
      };
      selectedMicrofence.zone =
        !microfenceZoneInput || microfenceZoneInput === 'none' ? undefined : microfenceZoneInput;
    } else if (selectedMicrofence?.assetId?.smartplugId !== undefined) {
      assetId = {
        smartplugId: microfenceIdInput,
      };
    }
    selectedMicrofence.name = fenceNameInput;
    selectedMicrofence.assetId = assetId;
    await updateFenceIdentifiers(
      fenceNameInput,
      undefined,
      selectedMicrofence.assetId,
      selectedMicrofence.zone,
    );

    // Update Properties
    const updatedProperties: Record<string, string> = {};
    displayGeomobyProperties.map(prop => {
      updatedProperties[prop.name] = prop.values;
    });
    await updateGeomobyProperties(updatedProperties);
    const updatedFence = selectedMicrofence;
    updatedFence.geomobyProperties = Object.assign({}, updatedProperties);
    setAvailableMicrofences(
      [
        ...availableMicrofences.filter(fence => fence.id !== selectedMicrofence?.id),
        updatedFence,
      ].sort((a, b) => a.name.localeCompare(b.name)),
    );

    setCreateEditFence(undefined);
  };

  const saveAllChanges = async () => {
    setDrawType(none);
    if (layerIds.find(lyr => lyr.id === FRESH_UNKNOWN_LAYER)) {
      setLayerIds(layerIds.filter(lyr => lyr.id !== FRESH_UNKNOWN_LAYER));
    }
    await saveLayerChanges();
    if (editing && !dirtySave.isDirty) {
      unsetEditing();
    }

    pageRef.current = 1;
    cancelLayer();
    setReassignedFences([]);
    setCreateEditFence(undefined);
    setReassigningToLayer(false);
    setReassignedLayerId('');
    setAvailableMicrofences(microfences.map(fence => fence.getProperties()));
    if (searchType?.id === 'GEOFENCES') {
      paginateGeofences(
        {
          ...geofenceFilter,
          layerId: isSome(selectedLayer) ? selectedLayer.value?.id : 'ALL',
        } as GeofenceFilter,
        true,
      );
    }
  };

  const cancelLayer = () => {
    setCreateEditLayer(undefined);
    setLayerNameInput('');
  };

  const cancelGeofence = useCallback(async () => {
    setCreateEditFence(undefined);
    setReassigningToLayer(false);
    setFenceNameInput('');
    setMicrofenceIdInput('');
    setMicrofenceZoneInput(undefined);
    setReassignedLayerId('');
    setBufferOffset(undefined);
    setBufferShape('Circle');

    if (selectedGeofence?.zone === FenceZone.breach) {
      unsetAsBreachZone(selectedGeofence?.id);
    } else if (selectedGeofence?.zone === FenceZone.cleared) {
      unsetAsClearedZone(selectedGeofence?.id);
    }

    if (selectedGeofence && !selectedGeofence?.id?.includes(FRESH)) {
      const { id, layerId, type } = selectedGeofence;
      const selectedType = isPolygon ? 'polygon' : isMultipolygon ? 'multipolygon' : 'line';
      if (!selectedType) return;

      const oldFence = (
        await axios.get<GridRowData>(
          `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${selectedType}/${id}`,
          authedConfig,
        )
      ).data;
      setDisplayGeomobyProperties(
        Object.entries(oldFence.geomobyProperties).map(([name, value], index) => {
          return {
            index: index as number,
            name: name as string,
            values: value as string,
          };
        }),
      );
      setSelectedGeofence({
        ...oldFence,
        layerId,
        type,
      });
    }
  }, [
    authedConfig,
    cid,
    pid,
    selectedGeofence,
    setCreateEditFence,
    setSelectedGeofence,
    triggersUrl,
    isPolygon,
    isMultipolygon,
    unsetAsBreachZone,
    unsetAsClearedZone,
  ]);

  const cancelMicrofence = useCallback(() => {
    setCreateEditFence(undefined);
    setFenceNameInput('');
    setMicrofenceIdInput('');
    setMicrofenceMajorInput(0);
    setMicrofenceMinorInput(0);
    setMicrofenceZoneInput(undefined);
  }, [
    setCreateEditFence,
    setFenceNameInput,
    setMicrofenceIdInput,
    setMicrofenceMajorInput,
    setMicrofenceMinorInput,
    setMicrofenceZoneInput,
  ]);

  const cancelAllChanges = async () => {
    pageRef.current = 1;
    deletedFenceIdsRef.current = [];
    setDrawType(none);
    if (editing) {
      unsetEditing();
    }
    cancelLayer();
    await resetLayerChanges();
    setAvailableMicrofences(
      microfences
        .filter(fence => !fence.get('id').includes(FRESH))
        .map(fence => fence.getProperties()),
    );

    cancelGeofence();
    cancelMicrofence();
    setReassignedFences([]);
    paginateGeofences(
      { ...geofenceFilter, layerId: layerFilter?.layer?.id } as GeofenceFilter,
      true,
    );
  };

  const goBack = () => {
    deselectAllFences();
    setCreateEditFence(undefined);
    setReassignedLayerId('');
    setSelectedGeofence(undefined);
    setFenceNameInput('');
    setMicrofenceIdInput('');
    setMicrofenceMajorInput(0);
    setMicrofenceMinorInput(0);
    setMicrofenceZoneInput(undefined);
    setBufferOffset(undefined);
    setBufferShape('Circle');
    setSelectedMicrofence(undefined);

    if (!layerFilter) {
      setSearchType(undefined);
      layerIds?.forEach(layer => changeVisibility(layer.id, true));
      setSelectedLayer(none);
      return;
    }

    if (searchType?.id === 'GEOFENCES') {
      paginateGeofences({
        ...geofenceFilter,
        layerId: isSome(selectedLayer) ? selectedLayer.value?.id : 'ALL',
      } as GeofenceFilter);
    } else if (searchType?.id === 'MICROFENCES') {
      setAvailableMicrofences(microfences.map(fence => fence.getProperties()));
    }
  };

  const getZonesSelection = (): FenceZoneType[] => {
    if (
      selectedGeofence?.zone === FenceZone.breach ||
      selectedGeofence?.zone === FenceZone.cleared
    ) {
      return FenceZoneTypes.filter(
        zone => zone.id === selectedGeofence.zone || zone.id === FenceZone.none,
      );
    } else if (hasFences) {
      return FenceZoneTypes;
    }
    return FenceZoneTypes.filter(f => f.id !== FenceZone.buffer);
  };

  const resetlayer = () => {
    if (selectedGeofence) {
      setLayerFilter({
        layer: {
          name: layerIds.find(lyr => lyr.id === selectedGeofence.layerId)?.name ?? '',
          id: selectedGeofence.layerId,
        },
      });
      setSelectedLayer(fromNullable(selectedGeofence.layerId));
      paginateGeofences(
        {
          ...geofenceFilter,
          layerId: selectedGeofence.layerId,
          perPage: 50,
        } as GeofenceFilter,
        true,
      );
    } else if (selectedMicrofence) {
      setLayerFilter({
        ...layerFilter,
        layer: { name: 'Microfences', id: MICROFENCE_LAYER_ID },
      });
      setSelectedLayer(fromNullable(MICROFENCE_LAYER_ID));
      setAvailableMicrofences(microfences.map(fence => fence.getProperties()));
    }
  };

  const downloadGeoJSON = async () => {
    if (!selectedGeofence) return;
    const geofence = await getGeofence(selectedGeofence);
    const type = isPolygon
      ? 'Polygon'
      : isMultipolygon
      ? 'MultiPolygon'
      : isLine
      ? 'LineString'
      : undefined;
    if (!geofence?.points || !type) return;

    const layerId = selectedGeofence.layerId ?? toNullable(selectedLayer)?.id;
    const layerName = layerIds.find(lyr => lyr.id === layerId)?.name;
    const geoJSON = {
      type: 'Feature',
      geometry: {
        type,
        coordinates: geofence.points.coordinates,
      },
      properties: {
        id: geofence.id,
        name: geofence.name,
        geomobyProperties: geofence.geomobyProperties,
        zone: selectedGeofence.zone,
        parentId: selectedGeofence.parentId,
        layerId,
        layerName,
      },
    };

    const jsonString: string = JSON.stringify(geoJSON, null, 2);
    const dataUri = window.URL.createObjectURL(
      new Blob([jsonString], { type: 'application/json' }),
    );
    const link = document.createElement('a');
    link.download = `${selectedGeofence.name}.json`;
    link.href = dataUri;
    link.click();
  };

  useEffect(() => {
    getGeofenceProperties();
  }, [getGeofenceProperties]);

  // Created a new layer.
  useEffect(() => {
    if (!createEditLayer) return;
    layerNameRef.current?.focus();
  }, [createEditLayer]);

  // Created a new Geofence or Microfence.
  useEffect(() => {
    if (createEditFence !== 'CREATE') return;
    if (selectedGeofence || selectedMicrofence) {
      cancelGeofence();
      cancelMicrofence();
    }

    if (selectedGeofence) {
      if (selectedGeofence?.id.includes(FRESH)) {
        setDisplayGeomobyProperties(
          Object.entries(selectedGeofence?.geomobyProperties).map(([name, value], index) => {
            return {
              index: index as number,
              name: name as string,
              values: value as string,
            };
          }),
        );
      }
    } else if (selectedMicrofence) {
      if (selectedMicrofence?.id.includes(FRESH)) {
        setDisplayGeomobyProperties(
          Object.entries(selectedMicrofence?.geomobyProperties).map(([name, value], index) => {
            return {
              index: index as number,
              name: name as string,
              values: value as string,
            };
          }),
        );
      }
    }
  }, [
    createEditFence,
    cancelGeofence,
    cancelMicrofence,
    selectedGeofence,
    selectedMicrofence,
    displayGeomobyProperties,
  ]);

  // Clicked a geofence or microfence from the map.
  useEffect(() => {
    if (selectedFromMap) {
      if (selectedGeofence) {
        setDisplayGeomobyProperties(
          Object.entries(selectedGeofence.geomobyProperties).map(([name, value], index) => {
            return {
              index: index as number,
              name: name as string,
              values: value as string,
            };
          }),
        );
        setFenceNameInput(selectedGeofence.name);
        setBufferOffset(undefined);
        setBufferShape('Circle');
      }
      if (selectedMicrofence) {
        cancelMicrofence();
        setDisplayGeomobyProperties(
          Object.entries(selectedMicrofence.geomobyProperties).map(([name, value], index) => {
            return {
              index: index as number,
              name: name as string,
              values: value as string,
            };
          }),
        );
        setFenceNameInput(selectedMicrofence.name);
      }
      setSelectedFromMap(false);
    }
  }, [
    cancelGeofence,
    selectedFromMap,
    setSelectedFromMap,
    selectedGeofence,
    cancelMicrofence,
    selectedMicrofence,
  ]);

  // Filter
  useEffect(() => {
    if (!refreshSearch) return;
    if (searchType?.id === 'GEOFENCES') {
      pageRef.current = 1;
      setSelectedLayer(fromNullable(layerFilter?.layer?.id));
      paginateGeofences(
        { ...geofenceFilter, layerId: layerFilter?.layer?.id } as GeofenceFilter,
        freshGeofences.length === 0,
      );
    }

    // TODO: This will have to do for microfence searching, until we have the correct end-point - LTP-478.
    if (searchType?.id === 'MICROFENCES') {
      setSelectedLayer(fromNullable(MICROFENCE_LAYER_ID));
      let updatedMicrofences = microfences.map(fence => fence.getProperties());
      Object.entries(microfenceFilter ?? {}).map(([key, value]) => {
        if (
          !(
            key === 'orderBy' ||
            key === 'order' ||
            key === 'microfenceProperties' ||
            typeof value === 'string'
          )
        ) {
          updatedMicrofences = updatedMicrofences.filter(
            microfence => microfence.microfenceType === (value as MicrofenceType).id,
          );
        }
        if (key === 'searchName') {
          updatedMicrofences = updatedMicrofences.filter(microfence =>
            microfence.name.includes(value),
          );
        }
        if (key === 'searchId') {
          updatedMicrofences = updatedMicrofences.filter(microfence => {
            return (
              (microfence.microfenceType === 'beacon' && microfence.assetId.uuid.includes(value)) ||
              (microfence.microfenceType === 'gateway' &&
                microfence.assetId.gatewayId.includes(value)) ||
              (microfence.microfenceType === 'device' &&
                microfence.assetId.deviceId.includes(value)) ||
              (microfence.microfenceType === 'smartplug' &&
                microfence.assetId.smartplugId.includes(value))
            );
          });
        }
        if (key === 'microfenceProperties') {
          updatedMicrofences = updatedMicrofences.filter(microfence => {
            const found = Object.entries(microfence.geomobyProperties).find(([k, v]) => {
              if (microfenceFilter?.microfenceProperties?.operator?.id === 'EQUAL_TO') {
                return (
                  k === microfenceFilter?.microfenceProperties?.type?.value &&
                  Number(v) === microfenceFilter?.microfenceProperties?.value
                );
              } else if (microfenceFilter?.microfenceProperties?.operator?.id === 'GREATER_THAN') {
                return (
                  k === microfenceFilter?.microfenceProperties?.type?.value &&
                  Number(v) > microfenceFilter?.microfenceProperties?.value
                );
              } else {
                return (
                  k === microfenceFilter?.microfenceProperties?.type?.value &&
                  Number(v) < microfenceFilter?.microfenceProperties?.value
                );
              }
            });
            if (!found) return;
            return microfence;
          });
        }
      });

      updatedMicrofences =
        microfenceFilter?.order?.id === 'DESC'
          ? updatedMicrofences.sort((a, b) => {
              if (microfenceFilter?.orderBy?.id === 'microfenceType') {
                return String(b.microfenceType).localeCompare(String(a.microfenceType));
              } else {
                return String(b.name).localeCompare(String(a.name));
              }
            })
          : updatedMicrofences.sort((a, b) => {
              if (microfenceFilter?.orderBy?.id === 'microfenceType') {
                return String(a.microfenceType).localeCompare(String(b.microfenceType));
              } else {
                return String(a.name).localeCompare(String(b.name));
              }
            });

      setAvailableMicrofences(updatedMicrofences.map(fence => fence));
    }

    setRefreshSearch(false);
    setShowFilter(false);
  }, [
    refreshSearch,
    paginateGeofences,
    searchType,
    geofenceFilter,
    layerFilter,
    setSelectedLayer,
    setRefreshSearch,
    setShowFilter,
    microfenceFilter,
    microfences,
    refreshFilter,
    setAvailableMicrofences,
    freshGeofences,
  ]);

  // Clear filter.
  useEffect(() => {
    if (clearFilter) {
      refreshFilter();
      setClearFilter(false);
    }
  }, [clearFilter, refreshFilter, setClearFilter]);

  useEffect(() => {
    if (deselectFence) {
      setDeselectFence(false);
      if (!layerFilter) {
        setSearchType(undefined);
        layerIds?.forEach(layer => changeVisibility(layer.id, true));
        setSelectedLayer(none);
      }
    }
  }, [
    deselectFence,
    setDeselectFence,
    setSearchType,
    layerFilter,
    layerIds,
    changeVisibility,
    setSelectedLayer,
  ]);

  return (
    <>
      <Grid
        style={{
          contentVisibility: showFilter ? 'hidden' : 'visible',
          margin: '20px',
        }}
      >
        {(selectedGeofence || selectedMicrofence) && (
          <Button
            style={{
              marginBottom: '10px',
            }}
            disabled={
              selectedGeofence?.layerId === FRESH_UNKNOWN_LAYER ||
              selectedGeofence?.previousLayer === FRESH_UNKNOWN_LAYER
            }
            onClick={() => goBack()}
          >
            Back
          </Button>
        )}

        {/* Search type  */}
        <Grid
          container
          direction="row"
          sx={
            toNullable(selectedLayer)?.id === MICROFENCE_LAYER_ID && !selectedMicrofence
              ? {
                  display: 'grid',
                  gap: isMobile ? '0%' : '2%',
                  gridTemplateColumns: isMobile ? '92% 10%' : '88% 10%',
                }
              : {}
          }
        >
          {!(selectedGeofence || selectedMicrofence) && (
            <FormControl
              fullWidth
              style={{
                width: '98%',
                marginBottom: '20px',
              }}
            >
              <InputLabel id="search-option">Search For</InputLabel>
              <Select
                fullWidth
                labelId="search-option"
                id="search-option"
                value={searchType?.value ?? ''}
                label="Search For"
                disabled={!!createEditLayer || mapIsLoading || layersHaveChanged}
                onChange={e => {
                  const search = SearchTypes.find(
                    l => l.value === (e.target.value as SearchTypeValue),
                  );
                  clearAll();
                  setSearchType(search);
                  setShowGhostGeofences(false);

                  if (search?.id === 'GEOFENCES') {
                    layerIds?.forEach(layer =>
                      changeVisibility(
                        layer.id,
                        layer.id !== MICROFENCE_LAYER_ID,
                        layer.id === MICROFENCE_LAYER_ID ? 0 : 1,
                      ),
                    );
                    setLayerFilter({
                      ...layerFilter,
                      layer: { name: 'All Groups', id: 'ALL' },
                    });
                    pageRef.current = 1;
                    paginateGeofences({ ...geofenceFilter, layerId: 'ALL' } as GeofenceFilter);
                  }
                  if (search?.id === 'MICROFENCES') {
                    layerIds?.forEach(layer =>
                      changeVisibility(layer.id, layer.id === MICROFENCE_LAYER_ID),
                    );
                    setSelectedLayer(fromNullable(MICROFENCE_LAYER_ID));
                    setLayerFilter({
                      ...layerFilter,
                      layer: { name: 'Microfences', id: MICROFENCE_LAYER_ID },
                    });
                  }
                  if (search?.id === 'LOCATIONS') {
                    layerIds?.forEach(layer => changeVisibility(layer.id, true, 1));
                  }
                }}
              >
                {SearchTypes.filter(search => search.forEditing).map(search => (
                  <MenuItem key={search.id} value={search.value} disabled={search.disabled}>
                    <Tooltip title={search.value}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 30px)',
                        }}
                      >
                        {search.value}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {toNullable(selectedLayer)?.id === MICROFENCE_LAYER_ID && !selectedMicrofence && (
            <Tooltip title={'SHOW GEOFENCES'}>
              <IconButton
                style={{
                  color: showGhostGeofences ? '#4CB8C4' : '#fff',
                  justifySelf: 'right',
                  width: 'fit-content',
                  height: 'fit-content',
                  marginTop: '12px',
                }}
                onClick={() => {
                  const showAllFences = !showGhostGeofences;
                  layerIds?.forEach(layer =>
                    changeVisibility(
                      layer.id,
                      layer.id === MICROFENCE_LAYER_ID ? true : showAllFences,
                    ),
                  );
                  setShowGhostGeofences(showAllFences);
                }}
              >
                {showGhostGeofences ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>
          )}
        </Grid>

        {/* Add new layer */}
        {searchType?.id === 'GEOFENCES' && !selectedGeofence && (
          <Grid
            container
            direction="column"
            style={{
              width: '98%',
            }}
          >
            <Button
              variant="outlined"
              fullWidth
              disabled={isLoading || layersHaveChanged || !!createEditLayer}
              style={{
                marginBottom: '20px',
              }}
              onClick={() => setCreateEditLayer('CREATE')}
            >
              Create New Group
            </Button>

            {createEditLayer && (
              <Grid
                container
                style={{
                  display: 'grid',
                  gridTemplateColumns: '90% 10%',
                  marginTop: '-7.5px',
                }}
              >
                <OutlinedInput
                  inputRef={layerNameRef}
                  placeholder="Group 1"
                  value={layerNameInput}
                  style={{
                    width: '98%',
                    height: '57px',
                    marginTop: '7.5px',
                  }}
                  onChange={({ target: { value } }) =>
                    setLayerNameInput(value.replaceAll(' ', '_'))
                  }
                  onKeyPress={event => {
                    if (event.key === 'Enter') saveLayer();
                  }}
                />

                <Grid
                  item
                  container
                  direction="row"
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  <IconButton onClick={() => saveLayer()} color="primary">
                    <CheckCircle />
                  </IconButton>

                  <IconButton onClick={() => cancelLayer()}>
                    <Cancel />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}

        {/* Layers  */}
        {!(selectedGeofence || selectedMicrofence) && searchType?.id === 'GEOFENCES' && (
          <Grid
            container
            style={
              isSome(selectedLayer) && selectedLayer.value?.id !== FRESH_UNKNOWN_LAYER
                ? {
                    display: 'grid',
                    gridTemplateColumns: '90% 10%',
                  }
                : {}
            }
          >
            <FormControl
              fullWidth
              style={{
                width: '98%',
                contentVisibility: createEditLayer ? 'hidden' : 'visible',
              }}
            >
              <InputLabel id="search-group-option">Select a group</InputLabel>
              <Select
                fullWidth
                labelId="search-group-option"
                id="search-group-option"
                disabled={isLoading || reassignedFences.length > 0 || layersHaveChanged}
                value={
                  (layerFilter?.layer === undefined && availableGeofences.length > 0) ||
                  toNullable(selectedLayer)?.id === FRESH_UNKNOWN_LAYER
                    ? 'All Groups'
                    : toNullable(selectedLayer)?.name ?? layerFilter?.layer?.name ?? ''
                }
                label="Select a group"
                onChange={e => {
                  const layer = [...[{ name: 'All Groups', id: 'ALL' }], ...layerIds].find(
                    l => l.name === e.target.value,
                  );
                  if (layer?.id !== 'ALL') {
                    setSelectedLayer(fromNullable(layer?.id));
                  }

                  if (layer?.id.includes(FRESH)) return;
                  setLayerFilter({
                    ...layerFilter,
                    layer,
                  });
                  pageRef.current = 1;
                  paginateGeofences({ ...geofenceFilter, layerId: layer?.id } as GeofenceFilter);
                  if (layer?.id === 'ALL') {
                    setSelectedLayer(none);
                    setSelectedGeofence(undefined);
                    setSelectedMicrofence(undefined);
                    deselectAllFences();
                    setDrawType(none);
                    layerIds?.forEach(layer =>
                      changeVisibility(layer.id, layer.id !== MICROFENCE_LAYER_ID),
                    );
                    setLayerNameInput('');
                  }
                }}
              >
                {[
                  ...[{ name: 'All Groups', id: 'ALL' }],
                  ...layerIds.filter(lyr => lyr.id !== MICROFENCE_LAYER_ID),
                ].map(layer => (
                  <MenuItem key={layer.id} value={layer.name}>
                    <Tooltip title={layer.name}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 30px)',
                        }}
                      >
                        {layer.name}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {!createEditLayer &&
              isSome(selectedLayer) &&
              selectedLayer.value?.id !== FRESH_UNKNOWN_LAYER && (
                <Grid
                  item
                  container
                  direction="row"
                  style={{
                    marginTop: '-10px',
                  }}
                >
                  <Tooltip title={'EDIT'}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setLayerNameInput(selectedLayer.value.name);
                        setCreateEditLayer('EDIT');
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={'DELETE'}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        if (isSome(selectedLayer) && selectedLayer.value?.id.includes(FRESH)) {
                          setLayerIds(
                            layerIds.filter(
                              lyr => lyr.id.includes(FRESH) && lyr.name === layerNameInput,
                            ),
                          );
                          setCreateEditLayer(undefined);
                          setLayersHaveChanged(false);
                        } else {
                          setDeleting('LAYER');
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
          </Grid>
        )}

        {/* Filter button and count  */}
        {!createEditLayer &&
          layerFilter?.layer &&
          !(selectedGeofence || selectedMicrofence) &&
          !isLoading && (
            <Grid
              container
              direction="column"
              style={{
                display: 'grid',
                gridTemplateColumns: '50% 50%',
              }}
            >
              <Grid item>
                <Button onClick={() => setShowFilter(true)}>
                  <span style={{ fontSize: '10px' }}>Filter</span>
                  <FilterList />
                </Button>
                <Button onClick={() => setClearFilter(true)}>
                  <span style={{ fontSize: '10px' }}>Clear</span>
                  <Clear />
                </Button>
              </Grid>

              <Grid
                item
                style={{
                  margin: '8px 10px 0px 0px',
                  justifySelf: 'end',
                }}
              >
                {searchType?.id === 'GEOFENCES' && (
                  <Typography>{`${count} ${
                    Number(count) === 1 ? 'result' : 'results'
                  }`}</Typography>
                )}
                {searchType?.id === 'MICROFENCES' && (
                  <Typography>{`${availableMicrofences.length} ${
                    Number(availableMicrofences.length) === 1 ? 'result' : 'results'
                  }`}</Typography>
                )}
              </Grid>
            </Grid>
          )}

        {mapIsLoading && !searchType && LoadingCircle()}

        {/* Geofences, Microfences  */}
        {!createEditLayer && !(selectedGeofence || selectedMicrofence) && (
          <Grid
            container
            direction={'row'}
            style={{
              marginTop: '10px',
              height: 'fit-content',
              maxHeight: '620px',
              overflowY: 'auto',
            }}
            onScroll={e => {
              const target = e.target as HTMLTextAreaElement;
              if (
                target.scrollTop % target.offsetTop < 20 &&
                !isLoading &&
                searchType?.id === 'GEOFENCES' &&
                count > availableGeofences.length
              ) {
                pageRef.current += 1;
                paginateGeofences({
                  ...geofenceFilter,
                  layerId: layerFilter?.layer?.id,
                  perPage: 50,
                } as GeofenceFilter);
              }
            }}
          >
            {isLoading && LoadingCircle()}

            {/* Geofences */}
            {!isLoading &&
              searchType?.id === 'GEOFENCES' &&
              availableGeofences.map(fence => {
                return (
                  <Accordion id={fence.id} key={fence.id} expanded={false} style={{ width: '98%' }}>
                    <AccordionSummary
                      onClick={() => {
                        if (fence.id.includes(FRESH) && fence.layerId === FRESH_UNKNOWN_LAYER) {
                          setOpenGenericDialog(true);
                          return;
                        }
                        setDisplayGeomobyProperties(
                          Object.entries(fence.geomobyProperties).map(([name, value], index) => {
                            return {
                              index: index as number,
                              name: name as string,
                              values: value as string,
                            };
                          }),
                        );
                        fence.selected = true;
                        setSelectedGeofence(fence);
                        setFenceNameInput(fence.name);
                        setReassignedLayerId('');
                        setBufferOffset(undefined);
                        setBufferShape('Circle');
                        getGeofence(fence);
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      {getFenceIcon(isMultipolygon ? FenceZone.cleared : fence.zone)}
                      <Tooltip
                        title={`${fence.name}${
                          !fence.zone || fence.zone === FenceZone.none
                            ? ''
                            : ' (' + fence.zone.toUpperCase() + ')'
                        }`}
                      >
                        <Typography
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {fence.name}
                        </Typography>
                      </Tooltip>
                    </AccordionSummary>
                  </Accordion>
                );
              })}

            {/* Microfences */}
            {!isLoading &&
              searchType?.id === 'MICROFENCES' &&
              availableMicrofences.map(microfence => {
                return (
                  <Accordion
                    id={microfence.id}
                    key={microfence.id}
                    expanded={false}
                    style={{ width: '98%' }}
                  >
                    <AccordionSummary
                      onClick={() => {
                        const coords =
                          microfence.point?.coordinates ?? microfence.geometry?.getExtent();
                        const extent = transformExtent(
                          [...coords, ...coords],
                          'EPSG:4326',
                          'EPSG:3857',
                        );
                        microfence.selected = true;
                        setSelectedMicrofence(microfence);
                        if (coords) {
                          setExtent(some(extent));
                        }
                        setDisplayGeomobyProperties(
                          Object.entries(microfence.geomobyProperties).map(
                            ([name, value], index) => {
                              return {
                                index: index as number,
                                name: name as string,
                                values: value as string,
                              };
                            },
                          ),
                        );
                        setFenceNameInput(microfence.name);
                        const assetId = microfence.assetId;
                        setMicrofenceIdInput(
                          assetId.uuid ??
                            assetId.deviceId ??
                            assetId.gatewayId ??
                            assetId.smartplugId,
                        );
                        if (assetId.uuid && assetId.major && assetId.minor) {
                          setMicrofenceMajorInput(assetId.major);
                          setMicrofenceMinorInput(assetId.minor);
                        }
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      {getMicroFenceIcon(microfence.microfenceType)}
                      <Tooltip title={microfence.name}>
                        <Typography
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {microfence.name}
                        </Typography>
                      </Tooltip>
                    </AccordionSummary>
                  </Accordion>
                );
              })}
          </Grid>
        )}

        {/* Selected Geofence  */}
        {!createEditLayer && !createEditFence && selectedGeofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                '& .MuiTypography-root': {
                  marginLeft: '0px',
                  marginBottom: 'auto',
                  width: isMobile || window.innerWidth < 900 ? '78vw' : '14vw',
                  ...truncationStyle,
                },
                '& .MuiStack-root': {
                  width: isMobile || window.innerWidth < 900 ? '78vw' : '14vw',
                  justifyContent: 'start',
                },
                display: 'grid',
                gap: isMobile ? '0%' : '2%',
                gridTemplateColumns: isMobile ? '92% 10%' : '49% 49%',
              }}
            >
              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <Box color="primary.main">
                  {getFenceIcon(isMultipolygon ? FenceZone.cleared : selectedGeofence.zone)}
                </Box>
                <Tooltip title={selectedGeofence.name}>
                  <Typography variant="h5">{selectedGeofence.name}</Typography>
                </Tooltip>
              </Stack>

              <Grid
                item
                container
                direction="row"
                style={{
                  width: 'fit-content',
                  position: 'relative',
                  justifySelf: 'right',
                  justifyContent: 'flex-end',
                }}
              >
                <Tooltip title={'NAVIGATE TO'}>
                  <IconButton color="primary" onClick={() => setNavigateTo(selectedGeofence.id)}>
                    <NearMe />
                  </IconButton>
                </Tooltip>

                <Tooltip title={'DOWNLOAD'}>
                  <span>
                    <IconButton
                      id="download-geojson"
                      color="primary"
                      disabled={
                        selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                        selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER
                      }
                      style={{
                        justifySelf: 'end',
                      }}
                      onClick={() => downloadGeoJSON()}
                    >
                      <Download />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title={'EDIT'}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSelectedLayer(fromNullable(selectedGeofence.layerId));
                      if (!layerFilter) {
                        resetlayer();
                      }
                      setFenceNameInput(selectedGeofence.name);
                      setBufferOffset(undefined);
                      setBufferShape('Circle');
                      setCreateEditFence('EDIT');
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>

                {selectedGeofence.zone !== FenceZone.buffer && (
                  <Tooltip title={'DELETE'}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setSelectedLayer(fromNullable(selectedGeofence.layerId));
                        if (!layerFilter) {
                          resetlayer();
                        }
                        setDeleting('GEOFENCE');
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>

            {selectedGeofence.layerId !== FRESH_UNKNOWN_LAYER && (
              <Grid
                container
                direction="column"
                style={{
                  marginBottom: '10px',
                  width: '70px',
                }}
              >
                <Button
                  style={{
                    marginLeft: '-7px',
                    color: '#fff',
                  }}
                  onClick={() => {
                    if (!selectedGeofence) return;
                    setSelectedGeofence(undefined);
                    setFenceNameInput('');
                    setSelectedMicrofence(undefined);
                    setSearchType({ id: 'GEOFENCES', value: 'Geofences' });
                    setGeofenceFilter({ perPage: 50 });
                    pageRef.current = 1;
                    resetlayer();
                    deselectAllFences();
                  }}
                >
                  {layerIds.find(lyr => lyr.id === selectedGeofence.layerId)?.name ?? ''}
                </Button>
              </Grid>
            )}

            {selectedGeofence.layerId === FRESH_UNKNOWN_LAYER && (
              <Grid
                container
                direction="column"
                style={{
                  marginBottom: '10px',
                  color: '#924439',
                }}
              >
                No group assigned
              </Grid>
            )}

            {displayGeomobyProperties && (
              <>
                <Tooltip title={'Geofence Properties'}>
                  <Typography style={{ color: '#4CB8C4' }}>Geofence Properties</Typography>
                </Tooltip>
                <DataTable rows={displayGeomobyProperties}></DataTable>
              </>
            )}
          </Paper>
        )}

        {/* Create/Edit Geofence */}
        {createEditFence && selectedGeofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                '& .MuiTypography-root': {
                  marginLeft: '0px',
                  width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                  ...truncationStyle,
                },
                '& .MuiStack-root': {
                  width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                  justifyContent: 'start',
                },
                marginTop: '10px',
                display: 'grid',
                gridTemplateColumns: isMobile ? '92% 10%' : '84% 20%',
              }}
            >
              <FormControl
                style={{
                  width: '90%',
                  marginBottom: '15px',
                }}
              >
                <InputLabel id="rename">Name</InputLabel>
                <OutlinedInput
                  id="rename"
                  label="Name"
                  key={'rename'}
                  name={'rename'}
                  inputRef={fenceNameRef}
                  placeholder="Geofence 1"
                  disabled={
                    selectedGeofence.zone === FenceZone.buffer ||
                    selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                    selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER
                  }
                  value={fenceNameInput}
                  onChange={({ target: { value } }) => {
                    setFenceNameInput(value);
                  }}
                  onKeyPress={async event => {
                    if (event.key === 'Enter') {
                      await saveGeofence();
                      if (selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER) {
                        paginateGeofences({
                          ...geofenceFilter,
                          layerId: selectedGeofence.layerId,
                        } as GeofenceFilter);
                      }
                    }
                  }}
                />
              </FormControl>

              <Grid
                item
                container
                direction="row"
                style={{
                  width: 'fit-content',
                  height: 'fit-content',
                  position: 'relative',
                  marginTop: '10px',
                }}
              >
                <IconButton
                  color="primary"
                  onClick={async () => {
                    await saveGeofence();
                    if (selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER) {
                      paginateGeofences({
                        ...geofenceFilter,
                        layerId: selectedGeofence.layerId,
                      } as GeofenceFilter);
                    }
                  }}
                >
                  <CheckCircle />
                </IconButton>

                <IconButton
                  onClick={() => {
                    setReassignedFences(
                      reassignedFences.filter(fence => fence.id !== selectedGeofence.id),
                    );
                    cancelGeofence();
                  }}
                >
                  <Cancel />
                </IconButton>
              </Grid>
            </Grid>

            {isPolygon && selectedGeofence.zone !== FenceZone.buffer && (
              <FormControl
                style={{
                  width: '75%',
                  marginBottom: '15px',
                }}
              >
                <InputLabel id="zone-type-option">Zone</InputLabel>
                <Select
                  fullWidth
                  labelId="zone-type-action"
                  id="zone-type-dropdown"
                  disabled={
                    selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                    selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER
                  }
                  value={
                    FenceZoneTypes.find(z => String(z.id) === selectedGeofence.zone)?.value ?? ''
                  }
                  label="Zone"
                  style={{
                    height: '60px',
                  }}
                  onChange={async e => {
                    const zoneType = FenceZoneTypes.find(
                      z => z.value === (e.target.value as FenceZoneTypeValue),
                    );
                    if (!zoneType) return;
                    if (zoneType.id === FenceZone.breach) {
                      await setAsBreachZone(
                        selectedGeofence.id,
                        bufferOffset ?? DEFAULT_BUFFER_METERS,
                        bufferShape,
                      );
                    } else {
                      setBufferOffset(undefined);
                      if (zoneType.id === FenceZone.buffer) {
                        await setAsBufferZone(selectedGeofence.id);
                      } else if (zoneType.id === FenceZone.cleared) {
                        await setAsClearedZone(selectedGeofence.id);
                      } else if (zoneType.id === FenceZone.none) {
                        if (selectedGeofence.zone === FenceZone.breach) {
                          await unsetAsBreachZone(selectedGeofence.id);
                        } else if (selectedGeofence.zone === FenceZone.cleared) {
                          await unsetAsClearedZone(selectedGeofence.id);
                        }
                      }
                    }

                    setSelectedGeofence({
                      ...selectedGeofence,
                      zone: zoneType.id as FenceZone,
                    });
                    setLayersHaveChanged(false);
                  }}
                >
                  {getZonesSelection().map(zone => (
                    <MenuItem key={zone.id} value={zone.value}>
                      <Tooltip title={zone.value}>
                        <Typography
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 50px)',
                            '& .MuiSvgIcon-root': {
                              marginBottom: '-7px',
                            },
                          }}
                        >
                          {getFenceIcon(zone.id)}
                          <span
                            style={{
                              padding: '10px',
                            }}
                          ></span>{' '}
                          {zone.value}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {selectedGeofence?.zone === FenceZone.breach && (
              <Grid
                style={{
                  marginTop: '10px',
                }}
              >
                <Tooltip title={'Set Buffer Zone'}>
                  <Typography style={{ color: '#4CB8C4' }}>Set Buffer Zone</Typography>
                </Tooltip>

                <Grid
                  container
                  style={{
                    display: 'grid',
                    gap: '2%',
                    gridTemplateColumns: '49% 49%',
                    marginBottom: '20px',
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={bufferShape}
                    sx={{
                      '& .MuiButtonBase-root': {
                        width: '50%',
                      },
                    }}
                    exclusive
                    onChange={async e => {
                      const newShape = (e.target as EventTarget & HTMLInputElement)
                        .value as BufferShapeType;
                      setBufferShape(newShape);
                      setIsLoading(true);
                      await unsetAsBreachZone(selectedGeofence.id);
                      await setAsBreachZone(
                        selectedGeofence.id,
                        bufferOffset ?? DEFAULT_BUFFER_METERS,
                        newShape,
                      );
                      setSelectedGeofence({ ...selectedGeofence, zone: FenceZone.breach });
                      setLayersHaveChanged(false);
                      setIsLoading(false);
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Circle">Circle</ToggleButton>
                    <ToggleButton value="Scaled">Scaled</ToggleButton>
                  </ToggleButtonGroup>

                  <FormControl>
                    <InputLabel id="offset">Offset</InputLabel>
                    <OutlinedInput
                      id="offset"
                      label="Offset"
                      key={'offset'}
                      name={'offset'}
                      inputRef={bufferOffsetRef}
                      value={bufferOffset ?? DEFAULT_BUFFER_METERS}
                      onChange={async ({ target: { value } }) => {
                        if (!isNaN(Number(value))) {
                          setBufferOffset(Number(value));
                          debounceBufferOffset(selectedGeofence, bufferShape, Number(value));
                        }
                      }}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          setUpdatingBreachZone(true);
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {displayGeomobyProperties && (
              <Grid
                style={{
                  marginTop: '10px',
                }}
              >
                <Tooltip title={'Geofence Properties'}>
                  <Typography style={{ color: '#4CB8C4' }}>Geofence Properties</Typography>
                </Tooltip>

                <Grid
                  container
                  style={{
                    display: 'grid',
                    gap: '2%',
                    gridTemplateColumns: '49% 49%',
                  }}
                >
                  <Tooltip title={'Property'}>
                    <Typography>Property</Typography>
                  </Tooltip>
                  <Tooltip title={'Value'}>
                    <Typography>Value</Typography>
                  </Tooltip>
                </Grid>

                {displayGeomobyProperties
                  .sort((a, b) => {
                    return String(a.index).localeCompare(String(b.index));
                  })
                  .map(prop => {
                    return (
                      <Grid
                        container
                        key={prop.index}
                        sx={{
                          display: 'grid',
                          gap: '2%',
                          gridTemplateColumns: '45% 45% 5%',
                          '& .MuiInputBase-root': {
                            height: '35px',
                          },
                        }}
                      >
                        <FormControl
                          sx={{
                            marginTop: '8px',
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor:
                                !properties.find(p => p.label === prop.name) && prop.name
                                  ? 'red !important'
                                  : 'inherit',
                            },
                          }}
                        >
                          <Select
                            fullWidth
                            id={'prop-name-' + prop.index}
                            name={'prop' + prop.index}
                            value={prop.name}
                            onChange={(e: { target: { value: string } }) => {
                              setDisplayGeomobyProperties([
                                ...displayGeomobyProperties.filter(
                                  displayed => displayed.index !== prop.index,
                                ),
                                {
                                  index: prop.index,
                                  name: e.target.value,
                                  values: prop.values,
                                },
                              ]);
                            }}
                          >
                            {(properties.find(p => p.label === prop.name)
                              ? properties
                              : [{ id: -1, label: prop.name }, ...properties].sort((a, b) =>
                                  a.label.localeCompare(b.label),
                                )
                            ).map(propName => (
                              <MenuItem
                                key={propName.id}
                                value={propName.label}
                                disabled={!properties.find(p => p.label === propName.label)}
                              >
                                <Tooltip title={propName.label}>
                                  <Typography
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      width: 'calc(100% - 50px)',
                                    }}
                                  >
                                    {propName.label}
                                  </Typography>
                                </Tooltip>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl>
                          <InputContainer
                            id={'prop-value-' + prop.index}
                            label=""
                            key={'prop-value-' + prop.index}
                            name={'prop-value-' + prop.index}
                            value={prop.values}
                            disableInput={
                              prop.name === 'dwellSeconds' && (isPolygon || isMultipolygon)
                            }
                            onChange={(e: { target: { value: string } }) => {
                              setDisplayGeomobyProperties([
                                ...displayGeomobyProperties.filter(
                                  displayed => displayed.index !== prop.index,
                                ),
                                {
                                  index: prop.index,
                                  name: prop.name,
                                  values: e.target.value,
                                },
                              ]);
                            }}
                            placeholder=""
                          />
                        </FormControl>

                        <IconButton
                          color="primary"
                          disabled={prop.name === 'dwellSeconds' && (isPolygon || isMultipolygon)}
                          onClick={() => {
                            setDisplayGeomobyProperties(
                              displayGeomobyProperties.filter(
                                displayed => displayed.index !== prop.index,
                              ),
                            );
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    );
                  })}

                {displayGeomobyProperties.find(
                  prop => !properties.find(p => p.label === prop.name) && !!prop.name,
                ) && (
                  <Typography
                    style={{
                      marginTop: '10px',
                      color: 'red',
                    }}
                  >
                    {'Property names outlined red are INVALID'}
                  </Typography>
                )}

                <Button
                  variant="outlined"
                  style={{
                    marginTop: '10px',
                    width: '92%',
                  }}
                  disabled={
                    selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                    selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER
                  }
                  onClick={() => {
                    setDisplayGeomobyProperties([
                      ...displayGeomobyProperties,
                      {
                        index: displayGeomobyProperties.length,
                        name: '',
                        values: '',
                      },
                    ]);
                  }}
                >
                  Add
                </Button>
              </Grid>
            )}

            {/* Reassign to another layer */}
            {selectedGeofence.zone !== FenceZone.buffer &&
              ((!selectedGeofence.id.includes(FRESH) &&
                selectedGeofence?.layerId !== FRESH_UNKNOWN_LAYER) ||
                (selectedGeofence.id.includes(FRESH) &&
                  (selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                    selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER))) && (
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    style={{
                      margin: '50px 0px 10px 0px',
                    }}
                  >
                    <InputLabel id="reassign-layer">{`${
                      selectedGeofence.layerId === FRESH_UNKNOWN_LAYER
                        ? 'Assign to a group'
                        : 'Reassign to another group'
                    }`}</InputLabel>
                    <Select
                      id="reassign-layer"
                      labelId="reassign-layer"
                      label={`${
                        selectedGeofence.layerId === FRESH_UNKNOWN_LAYER
                          ? 'Assign to a group'
                          : 'Reassign to another group'
                      }`}
                      fullWidth
                      onChange={e => {
                        if (
                          selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                          selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER
                        ) {
                          setSelectedGeofence({
                            ...selectedGeofence,
                            layerId: e.target.value,
                            previousLayer: FRESH_UNKNOWN_LAYER,
                          });
                        }
                        setReassignedLayerId(e.target.value);
                      }}
                      value={reassignedLayerId ?? ''}
                    >
                      <MenuItem value="none" />
                      {layerIds
                        ?.filter(l => l.id !== MICROFENCE_LAYER_ID && l.id !== FRESH_UNKNOWN_LAYER)
                        .sort((a, b) => a.name?.localeCompare(b.name))
                        .map(layer => (
                          <MenuItem key={layer.id} value={layer.id}>
                            <Tooltip title={layer.name}>
                              <Typography
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: 'calc(100% - 30px)',
                                }}
                              >
                                {layer.name}
                              </Typography>
                            </Tooltip>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
          </Paper>
        )}

        {/* Selected Microfence */}
        {!createEditLayer && !createEditFence && selectedMicrofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                '& .MuiTypography-root': {
                  marginLeft: '0px',
                  marginBottom: 'auto',
                  width: isMobile || window.innerWidth < 900 ? '78vw' : '15vw',
                  ...truncationStyle,
                },
                '& .MuiStack-root': {
                  width: isMobile || window.innerWidth < 900 ? '78vw' : '15vw',
                  justifyContent: 'start',
                },
                display: 'grid',
                gap: isMobile ? '0%' : '2%',
                gridTemplateColumns: isMobile ? '92% 10%' : '49% 49%',
              }}
            >
              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <Box color="primary.main" style={{ marginTop: '10px' }}>
                  {getMicroFenceIcon(selectedMicrofence.microfenceType)}
                </Box>
                <Tooltip title={selectedMicrofence.name}>
                  <Typography variant="h5">{selectedMicrofence.name}</Typography>
                </Tooltip>
              </Stack>

              <Grid
                item
                container
                direction="row"
                style={{
                  width: 'fit-content',
                  position: 'relative',
                  justifySelf: 'right',
                }}
              >
                <Tooltip title={'NAVIGATE TO'}>
                  <IconButton color="primary" onClick={() => setNavigateTo(selectedMicrofence.id)}>
                    <NearMe />
                  </IconButton>
                </Tooltip>

                <Tooltip title={'EDIT'}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (!layerFilter) {
                        resetlayer();
                      }
                      setFenceNameInput(selectedMicrofence.name);
                      const assetId = selectedMicrofence.assetId;
                      setMicrofenceIdInput(
                        assetId.uuid ??
                          assetId.deviceId ??
                          assetId.gatewayId ??
                          assetId.smartplugId,
                      );
                      if (assetId.uuid && assetId.major && assetId.minor) {
                        setMicrofenceMajorInput(assetId.major);
                        setMicrofenceMinorInput(assetId.minor);
                      }
                      setCreateEditFence('EDIT');
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>

                <Tooltip title={'DELETE'}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (!layerFilter) {
                        resetlayer();
                      }
                      setDeleting('MICROFENCE');
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              style={{
                marginBottom: '10px',
                width: '70px',
              }}
            >
              <Button
                style={{
                  marginLeft: '-7px',
                  color: '#fff',
                }}
                onClick={() => {
                  setSearchType({ id: 'MICROFENCES', value: 'Microfences' });
                  setSelectedMicrofence(undefined);
                  setGeofenceFilter({ perPage: 50 });
                  pageRef.current = 1;
                  resetlayer();
                  deselectAllFences();
                }}
              >
                {MICROFENCE_LAYER_LABEL}
              </Button>
            </Grid>

            <Grid container direction={'row'} justifyContent={'center'}>
              {selectedMicrofence.microfenceType === 'beacon' && (
                <>
                  <Grid
                    item
                    container
                    direction={'row'}
                    xs={12}
                    fontSize="90%"
                    sx={{
                      '& .MuiTypography-root': {
                        width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                        ...truncationStyle,
                      },
                    }}
                  >
                    <Tooltip title={'UUID'}>
                      <Typography style={{ color: '#4CB8C4' }}>UUID:</Typography>
                    </Tooltip>
                    <Tooltip
                      title={
                        (
                          selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        )?.uuid
                      }
                    >
                      <Typography>
                        {
                          (
                            selectedMicrofence.assetId as {
                              uuid: string;
                              major: string;
                              minor: string;
                            }
                          )?.uuid
                        }
                      </Typography>
                    </Tooltip>
                  </Grid>
                </>
              )}
              {selectedMicrofence.microfenceType === 'smartplug' && (
                <>
                  <Grid
                    item
                    container
                    direction={'row'}
                    xs={12}
                    fontSize="90%"
                    sx={{
                      '& .MuiTypography-root': {
                        width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                        ...truncationStyle,
                      },
                    }}
                  >
                    <Tooltip title={'Smartplug ID'}>
                      <Typography style={{ color: '#4CB8C4' }}>Smartplug ID:</Typography>
                    </Tooltip>
                    <Tooltip
                      title={
                        (
                          selectedMicrofence.assetId as {
                            smartplugId: string;
                          }
                        )?.smartplugId
                      }
                    >
                      <Typography>
                        {
                          (
                            selectedMicrofence.assetId as {
                              smartplugId: string;
                            }
                          )?.smartplugId
                        }
                      </Typography>
                    </Tooltip>
                  </Grid>
                </>
              )}
              {selectedMicrofence.microfenceType === 'gateway' && (
                <>
                  <Grid
                    item
                    container
                    direction={'row'}
                    xs={12}
                    fontSize="90%"
                    sx={{
                      '& .MuiTypography-root': {
                        width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                        ...truncationStyle,
                      },
                    }}
                  >
                    <Tooltip title={'Gateway ID'}>
                      <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                        Gateway ID:
                      </Typography>
                    </Tooltip>
                    <Tooltip
                      title={(selectedMicrofence.assetId as { gatewayId: string })?.gatewayId}
                    >
                      <Typography>
                        {(selectedMicrofence.assetId as { gatewayId: string })?.gatewayId}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </>
              )}
              {selectedMicrofence.microfenceType === 'device' && (
                <>
                  <Grid
                    item
                    container
                    direction={'row'}
                    xs={12}
                    fontSize="90%"
                    sx={{
                      '& .MuiTypography-root': {
                        width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                        ...truncationStyle,
                      },
                    }}
                  >
                    <Tooltip title={'Device ID'}>
                      <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                        Device ID:
                      </Typography>
                    </Tooltip>
                    <Tooltip
                      title={(selectedMicrofence.assetId as { deviceId: string })?.deviceId || ''}
                    >
                      <Typography>
                        {(selectedMicrofence.assetId as { deviceId: string })?.deviceId || ''}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </>
              )}

              {selectedMicrofence.microfenceType === 'beacon' && (
                <Grid item container direction={'row'} xs={12} fontSize="90%">
                  <Tooltip title={'Major'}>
                    <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                      Major:
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={
                      (selectedMicrofence.assetId as { uuid: string; major: string; minor: string })
                        ?.major
                    }
                  >
                    <Typography>
                      {
                        (
                          selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        )?.major
                      }
                    </Typography>
                  </Tooltip>
                </Grid>
              )}

              {selectedMicrofence.microfenceType === 'beacon' && (
                <Grid item container direction={'row'} xs={12} fontSize="90%">
                  <Tooltip title={'Minor'}>
                    <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                      Minor:
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={
                      (selectedMicrofence.assetId as { uuid: string; major: string; minor: string })
                        ?.minor
                    }
                  >
                    <Typography>
                      {
                        (
                          selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        )?.minor
                      }
                    </Typography>
                  </Tooltip>
                </Grid>
              )}
            </Grid>

            <Grid item container direction={'row'} xs={12} fontSize="90%">
              <Tooltip title={'Zone'}>
                <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>Zone:</Typography>
              </Tooltip>
              <Tooltip
                title={
                  MicrofenceZoneTypes.find(
                    z => z.id === (selectedMicrofence?.zone as MicrofenceZone),
                  )?.value ?? 'No Zone'
                }
              >
                <Typography>
                  {MicrofenceZoneTypes.find(
                    z => z.id === (selectedMicrofence?.zone as MicrofenceZone),
                  )?.value ?? 'No Zone'}
                </Typography>
              </Tooltip>
            </Grid>

            {displayGeomobyProperties && (
              <>
                <Tooltip title={'Microfence Properties'}>
                  <Typography style={{ color: '#4CB8C4' }}>Microfence Properties</Typography>
                </Tooltip>
                <DataTable rows={displayGeomobyProperties}></DataTable>
              </>
            )}
          </Paper>
        )}

        {/* Create/Edit Microfence */}
        {createEditFence && selectedMicrofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                '& .MuiTypography-root': {
                  marginLeft: '0px',
                  width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                  ...truncationStyle,
                },
                '& .MuiStack-root': {
                  width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                  justifyContent: 'start',
                },
                marginTop: '10px',
                display: 'grid',
                gridTemplateColumns: isMobile ? '92% 10%' : '84% 20%',
              }}
            >
              <FormControl
                style={{
                  width: '90%',
                  marginBottom: '15px',
                }}
              >
                <InputLabel id="rename">Name</InputLabel>
                <OutlinedInput
                  id="rename"
                  label="Name"
                  key={'rename'}
                  name={'rename'}
                  inputRef={fenceNameRef}
                  placeholder="Geofence 1"
                  value={fenceNameInput}
                  onChange={({ target: { value } }) => {
                    setFenceNameInput(value);
                  }}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      saveMicrofence();
                    }
                  }}
                />
              </FormControl>

              <Grid
                item
                container
                direction="row"
                style={{
                  width: 'fit-content',
                  height: 'fit-content',
                  position: 'relative',
                  marginTop: '10px',
                }}
              >
                <IconButton color="primary" onClick={() => saveMicrofence()}>
                  <CheckCircle />
                </IconButton>

                <IconButton onClick={() => cancelMicrofence()}>
                  <Cancel />
                </IconButton>
              </Grid>
            </Grid>

            {/* Microfence ID */}
            <FormControl
              style={{
                width: '75.5%',
                marginBottom: '15px',
              }}
            >
              <InputLabel id="microfence-label">
                {selectedMicrofence.microfenceType === 'gateway'
                  ? 'Gateway ID'
                  : selectedMicrofence.microfenceType === 'device'
                  ? 'Device ID'
                  : 'UUID'}
              </InputLabel>
              <OutlinedInput
                id="microfence-id"
                label={
                  selectedMicrofence.microfenceType === 'gateway'
                    ? 'Gateway ID'
                    : selectedMicrofence.microfenceType === 'device'
                    ? 'Device ID'
                    : 'UUID'
                }
                key={'microfence-id'}
                name={'microfence-id'}
                inputRef={microfenceIdRef}
                placeholder="Microfence 1"
                value={microfenceIdInput}
                onChange={({ target: { value } }) => {
                  setMicrofenceIdInput(value);
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    saveMicrofence();
                  }
                }}
              />
            </FormControl>

            {selectedMicrofence.microfenceType === 'beacon' && (
              <Grid
                container
                direction="row"
                style={{
                  marginTop: '10px',
                  width: '75.5%',
                  display: 'grid',
                  gap: '2%',
                  gridTemplateColumns: '49% 49%',
                }}
              >
                {/* Major */}
                <FormControl
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  <InputLabel id="major-label">Major</InputLabel>
                  <OutlinedInput
                    id="major-id"
                    label={'Major'}
                    key={'major-id'}
                    name={'major-id'}
                    type={'number'}
                    inputRef={microfenceMajorRef}
                    value={microfenceMajorInput}
                    onChange={({ target: { value } }) => {
                      setMicrofenceMajorInput(Number(value) < 0 ? 0 : Number(value));
                    }}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        saveMicrofence();
                      }
                    }}
                  />
                </FormControl>

                {/* Minor */}
                <FormControl
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  <InputLabel id="minor-label">Minor</InputLabel>
                  <OutlinedInput
                    id="minor-id"
                    label={'Minor'}
                    key={'minor-id'}
                    name={'minor-id'}
                    type={'number'}
                    inputRef={microfenceMinorRef}
                    value={microfenceMinorInput}
                    onChange={({ target: { value } }) => {
                      setMicrofenceMinorInput(Number(value) < 0 ? 0 : Number(value));
                    }}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        saveMicrofence();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            )}

            {/* Microfence Zone */}
            <FormControl
              style={{
                marginTop: '10px',
                width: '75%',
                marginBottom: '15px',
              }}
            >
              <InputLabel id="zone-type-option">Zone</InputLabel>
              <Select
                fullWidth
                labelId="zone-type-action"
                id="zone-type-dropdown"
                value={
                  MicrofenceZoneTypes.find(z => String(z.id) === selectedMicrofence.zone)?.value ??
                  'No Zone'
                }
                label="Zone"
                style={{
                  height: '60px',
                }}
                onChange={async e => {
                  const zoneType = MicrofenceZoneTypes.find(
                    z => z.value === (e.target.value as MicrofenceZoneTypeValue),
                  );
                  if (!zoneType) return;
                  const zone = zoneType.id as MicrofenceZone;
                  setMicrofenceZoneInput(zone);
                  setSelectedMicrofence({
                    ...selectedMicrofence,
                    zone,
                  });
                }}
              >
                {MicrofenceZoneTypes.map(zone => (
                  <MenuItem key={zone.id} value={zone.value}>
                    <Tooltip title={zone.value}>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                          '& .MuiSvgIcon-root': {
                            marginBottom: '-7px',
                          },
                        }}
                      >
                        {zone.value}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Microfence Properties */}
            {displayGeomobyProperties && (
              <Grid
                style={{
                  marginTop: '10px',
                }}
              >
                <Tooltip title={'Microfence Properties'}>
                  <Typography style={{ color: '#4CB8C4' }}>Microfence Properties</Typography>
                </Tooltip>

                <Grid
                  container
                  style={{
                    display: 'grid',
                    gap: '2%',
                    gridTemplateColumns: '49% 49%',
                  }}
                >
                  <Tooltip title={'Property'}>
                    <Typography>Property</Typography>
                  </Tooltip>
                  <Tooltip title={'Value'}>
                    <Typography>Value</Typography>
                  </Tooltip>
                </Grid>

                {displayGeomobyProperties
                  .sort((a, b) => {
                    return String(a.index).localeCompare(String(b.index));
                  })
                  .map(prop => {
                    return (
                      <Grid
                        container
                        key={prop.index}
                        sx={{
                          display: 'grid',
                          gap: '2%',
                          gridTemplateColumns: '45% 45% 5%',
                          '& .MuiInputBase-root': {
                            height: '35px',
                          },
                        }}
                      >
                        <FormControl>
                          <InputContainer
                            id={'prop-name-' + prop.index}
                            label=""
                            key={'prop-name-' + prop.index}
                            name={'prop-name' + prop.index}
                            value={prop.name}
                            disableInput={true}
                            onChange={(e: { target: { value: string } }) => {
                              setDisplayGeomobyProperties([
                                ...displayGeomobyProperties.filter(
                                  displayed => displayed.index !== prop.index,
                                ),
                                {
                                  index: prop.index,
                                  name: e.target.value,
                                  values: prop.values,
                                },
                              ]);
                            }}
                            placeholder=""
                          />
                        </FormControl>

                        <FormControl>
                          <InputContainer
                            id={'prop-value-' + prop.index}
                            label=""
                            key={'prop-value-' + prop.index}
                            name={'prop-value-' + prop.index}
                            type={'number'}
                            value={prop.values}
                            onChange={(e: { target: { value: string } }) => {
                              setDisplayGeomobyProperties([
                                ...displayGeomobyProperties.filter(
                                  displayed => displayed.index !== prop.index,
                                ),
                                {
                                  index: prop.index,
                                  name: prop.name,
                                  values: e.target.value,
                                },
                              ]);
                            }}
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Paper>
        )}
      </Grid>

      {/* Location */}
      {searchType?.id === 'LOCATIONS' && layerIds && (
        <>
          <div
            style={{
              width: '100px',
              alignSelf: 'start',
              margin: '-20px 0px -20px 20px',
            }}
          >
            <Tooltip title={'Search'}>
              <Typography variant="h5">Search</Typography>
            </Tooltip>
          </div>
          <div
            style={{
              margin: '20px 0px 0px 20px',
            }}
          >
            <LocationSearch
              isGeofenceEditor={false}
              setLocationSearchData={setLocationSearchData}
              currentCenter={currentCenter}
              setLocationDisplay={setLocationDisplay}
            ></LocationSearch>
          </div>

          {locationDisplay && (
            <Paper
              variant="outlined"
              style={{
                padding: '10px',
                height: 'fit-content',
                width: 'fit-content',
                margin: '20px',
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  '& .MuiTypography-root': {
                    marginLeft: '0px',
                    width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                    ...truncationStyle,
                  },
                  '& .MuiStack-root': {
                    width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                    justifyContent: 'start',
                  },
                }}
              >
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                  <Box color="primary.main">
                    <LocationOn />
                  </Box>
                  <Tooltip title={locationDisplay.label}>
                    <Typography variant="h5">{locationDisplay.label}</Typography>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid
                style={{
                  marginLeft: '32px',
                }}
              >
                <Tooltip title={locationDisplay.coordinates}>
                  <Typography
                    style={{
                      marginLeft: '-7px',
                      color: '#fff',
                    }}
                  >
                    {`${locationDisplay.coordinates[0]}, ${locationDisplay.coordinates[1]}`}
                  </Typography>
                </Tooltip>
              </Grid>
            </Paper>
          )}
        </>
      )}

      {/* Save */}
      {(isSome(selectedLayer) || selectedGeofence || selectedMicrofence || createEditLayer) &&
        searchType?.id !== 'LOCATIONS' &&
        !showFilter && (
          <Grid
            container
            style={{
              display: 'grid',
              gridTemplateColumns: '50% 50%',
              gap: '2%',
              marginLeft: '20px',
              width: '88%',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={!layersHaveChanged || isLoading}
              onClick={() => {
                if (reassignedFences.length === 0) {
                  saveAllChanges();
                } else if (reassignedFences.length > 0) {
                  setReassigningToLayer(true);
                }
              }}
            >
              {toNullable(selectedLayer)?.id === MICROFENCE_LAYER_ID
                ? 'Save microfence changes'
                : 'Save group changes'}
            </Button>
            <Button
              variant="outlined"
              disabled={!layersHaveChanged || isLoading}
              onClick={() => cancelAllChanges()}
            >
              Cancel
            </Button>
          </Grid>
        )}

      {/* Dirty */}
      <Dialog
        open={dirtySave.isDirty}
        onClose={() => setDirtySave({ isDirty: false, issue: null })}
      >
        <DialogTitle>{dirtySave.issue}</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              if (
                selectedGeofence?.layerId === FRESH_UNKNOWN_LAYER &&
                reassignedFences.length === 0
              ) {
                setFenceNameInput(selectedGeofence.name);
                setBufferOffset(undefined);
                setBufferShape('Circle');
                setCreateEditFence('EDIT');
              }
              setDirtySave({ isDirty: false, issue: null });
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Deleting Layer */}
      <Dialog open={deleting === 'LAYER'} onClose={() => setDeleting(undefined)}>
        <DialogTitle>Are you sure you want to delete this group?</DialogTitle>
        <DialogContent>
          <p>All associated {getFenceLabel('OUTDOOR')}s will also be deleted.</p>
          <p>This action is irreversible.</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              await deleteLayer();
              setDeleting(undefined);
              const layer = { name: 'All Groups', id: 'ALL' };
              setLayerFilter({
                ...layerFilter,
                layer,
              });
              setSelectedLayer(fromNullable(layer.id));
              pageRef.current = 1;
              setLayerNameInput('');
              paginateGeofences({ ...geofenceFilter, layerId: layer.id } as GeofenceFilter);
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setDeleting(undefined);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Deleting Fence */}
      <Dialog
        open={deleting === 'GEOFENCE' || deleting === 'MICROFENCE'}
        onClose={() => setDeleting(undefined)}
      >
        <DialogTitle>Are you sure you want to delete this {deleting?.toLowerCase()}?</DialogTitle>
        <DialogActions>
          <Button
            onClick={async () => {
              if (selectedGeofence?.zone === FenceZone.breach) {
                const buffer = availableGeofences.find(
                  buffer => buffer.parentId === selectedGeofence?.id,
                );
                if (buffer) {
                  deletedFenceIdsRef.current.push(buffer?.id);
                }
                deletedFenceIdsRef.current.push(selectedGeofence?.id);
                await removeBufferZone(buffer?.id);
                await deleteFence(selectedGeofence?.id);
              } else {
                deletedFenceIdsRef.current.push(selectedGeofence?.id);
                await deleteFence(selectedGeofence?.id);
              }
              setDeleting(undefined);
              goBack();
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setDeleting(undefined)}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Duplicate Layer */}
      <Dialog open={layerConflict} onClose={() => setLayerConflict(false)}>
        <DialogTitle>A Group with this name already exists. Please choose another one.</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              setLayerConflict(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reassigning Fences */}
      <Dialog open={reassigningToLayer} onClose={() => setReassigningToLayer(false)}>
        <DialogTitle>{`Any unsaved data for ${
          reassignedFences.length > 1 ? 'these geofences' : 'this geofence'
        } will be lost`}</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button onClick={() => saveAllChanges()}>OK</Button>
          <Button
            onClick={() => {
              setReassigningToLayer(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Generic Save Changes Dialog */}
      <Dialog open={openGenericDialog} onClose={() => setOpenGenericDialog(false)}>
        <DialogTitle>Please save group changes before editing this geofence</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              setOpenGenericDialog(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
