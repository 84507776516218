import { useAtomValue } from 'jotai';
import { KNOWN_TOOLS_BY_ID, KnownTool } from '../store/tools';
import { useCallback, useEffect } from 'react';

export const toToolLabel = (
  knownToolsRecord: Record<string, KnownTool | undefined>,
  ids: Record<string, string | undefined>,
  label: string | undefined,
  fallback?: string,
) => {
  const isBeacon = ids.beaconId || (ids.uuid && ids.major && ids.minor);
  if (!isBeacon || !label) return fallback ?? label;

  const labelFromTool = knownToolsRecord[label];
  const ibeaconFromTool = Array.from(Object.values(knownToolsRecord)).find(
    tool =>
      tool?.ibeacon &&
      tool.ibeacon.uuid?.toLowerCase() === ids.uuid?.toLowerCase() &&
      tool.ibeacon.major === Number(ids.major) &&
      tool.ibeacon.minor === Number(ids.minor),
  );

  if (!(labelFromTool || ibeaconFromTool)) return fallback ?? label;

  const toolLabel = (labelFromTool || ibeaconFromTool)?.label;
  const toolId = (labelFromTool || ibeaconFromTool)?.id;
  const tool = (labelFromTool || ibeaconFromTool)?.tool;

  if (toolLabel) {
    return `${toolLabel} (${toolId}; ${tool})`;
  }
  return `${toolId} (${tool})`;
};

export const useAugmentedToolLabel = () => {
  const knownToolsRecord = useAtomValue(KNOWN_TOOLS_BY_ID);
  return useCallback(
    (ids: Record<string, string | undefined>, label: string | undefined, fallback?: string) =>
      toToolLabel(knownToolsRecord, ids, label, fallback),
    [knownToolsRecord],
  );
};
