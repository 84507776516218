import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { ReactNode, useEffect } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { METADATA_URL } from '../store/url';
import { CID, PID } from '../store/user';
import { KNOWN_TOOLS, KnownTool } from '../store/tools';

// Use at the top of the "logged in" app tree
export const KnownToolsProvider = ({ children }: { children: ReactNode }) => {
  const setKnownTools = useSetAtom(KNOWN_TOOLS);
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const metadataUrl = useAtomValue(METADATA_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const tools = (
        await axios.post<KnownTool[]>(
          `${metadataUrl}/${cid}/${pid}/portableasset/search`,
          { type: 'tool' },
          {
            ...authedRequestConfig,
            signal: abortController.signal,
          },
        )
      ).data;
      setKnownTools(tools);
    })();
    return () => {
      abortController.abort();
    };
  }, [setKnownTools, authedRequestConfig, metadataUrl, cid, pid]);

  return <>{children}</>;
};
