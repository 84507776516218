import { atom } from 'jotai';
import { CMContainer } from '../Components/Map/Messages';
import { toMapUpdateOrUndefined } from '../hooks/geomoby/useGeomobyLiveStream';
import { AssetUpdateType } from '../hooks/geomoby/LiveMapActions';
import { KNOWN_TOOLS_BY_ID } from './tools';
import { toToolLabel } from '../util/useToolsLabel';

export const LIVE_LATEST_EVENTS = atom<CMContainer[]>([]);
export const LIVE_LATEST_NOW = atom(null); // `null` means always use current date

export const LIVE_MESSAGES = atom<CMContainer[]>([]);
export const LIVE_NEW_MESSAGES = atom<CMContainer[]>([]);
export const LIVE_NEW_NOTIFICATIONS = atom<CMContainer[]>([]);

export const LIVE_STREAM = atom(get =>
  get(LIVE_MESSAGES).sort(
    event => new Date(event.created).getTime() - new Date(event.created).getTime(),
  ),
);

export const LIVE_UPDATES = atom(get => {
  const knownTools = get(KNOWN_TOOLS_BY_ID);
  return get(LIVE_STREAM).flatMap(event =>
    toMapUpdateOrUndefined(event, (id, label, fallback) =>
      toToolLabel(knownTools, id, label, fallback),
    ),
  );
});

export const LIVE_UPDATES_LATEST = atom(
  get =>
    get(LIVE_UPDATES)
      .filter(x => x?.type !== AssetUpdateType.NotificationUpdate)
      .slice(-1)[0],
);

export const LIVE_NOTIFICATIONS_LATEST = atom(
  get =>
    get(LIVE_UPDATES)
      .filter(x => x?.type === AssetUpdateType.NotificationUpdate)
      .slice(-1)[0],
);
