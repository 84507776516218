import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { KnownTool } from '../store/tools';
import { PERSISTOR_URL } from '../store/url';
import { CID, PID } from '../store/user';
import { AsyncTask } from '../util/AsyncTask';

export type Creator = {
  id?: number;
  identifiers: Record<string, unknown>;
  label: string;
  updatedAt?: string;
  createdAt?: string;
  uniqueLabel?: boolean;
  tool?: KnownTool;
};

export const useCreators = (): AsyncTask<Creator[]> => {
  const [data, setData] = useState<Creator[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  useEffect(() => {
    (async () => {
      try {
        setData(undefined);
        setError(undefined);
        setLoading(true);
        const response = await axios.get<Creator[]>(
          `${persistorUrl}/${cid}/${pid}/analytics/creators`,
          authedConfig,
        );
        if (response.status === 200) {
          setData(
            response.data
              .sort(
                (a, b) =>
                  Number(!!a.identifiers.uuid) - Number(!!b.identifiers.uuid) ||
                  a.label.localeCompare(b.label),
              )
              .map((x, y, z) => ({
                ...x,
                uniqueLabel: z[y - 1]?.label !== x.label && z[y + 1]?.label !== x.label,
              })),
          );
        } else {
          setError(response);
        }
      } catch (error: unknown) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [cid, pid, persistorUrl, authedConfig]);

  return { data, loading, error };
};
