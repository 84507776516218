import { PersonPinCircle } from '@mui/icons-material';
import { PaperBox, SubHeader } from '../../../../Common/Sidebar';
import { truncationStyle } from '../../AssetRenderer';
import { Tooltip, Typography } from '@mui/material';
import { AssetLocation } from '../../Toolbar/LocationSearch';

export const DeviceOrTagLocation = ({
  heading,
  deviceLocation,
}: {
  heading: string;
  deviceLocation: AssetLocation | undefined;
}) => {
  return (
    <PaperBox>
      {deviceLocation && (
        <SubHeader icon={<PersonPinCircle />} timestamp={deviceLocation.timestamp}>
          {heading}
        </SubHeader>
      )}

      {deviceLocation && (
        <div style={{ marginLeft: '1rem' }}>
          <Tooltip title={deviceLocation.lat.toFixed(5)}>
            <pre style={truncationStyle}>lat: {deviceLocation.lat.toFixed(5)},</pre>
          </Tooltip>
          <Tooltip title={deviceLocation.lon.toFixed(5).toString()}>
            <pre style={truncationStyle}>lon: {deviceLocation.lon.toFixed(5)}</pre>
          </Tooltip>
          <Tooltip title={new Date(deviceLocation.timestamp).toLocaleString()}>
            <pre style={truncationStyle}>
              at: {new Date(deviceLocation.timestamp).toLocaleString()}
            </pre>
          </Tooltip>
        </div>
      )}

      {!deviceLocation && heading !== 'Location' && <Typography>NO LAST KNOWN LOCATION</Typography>}
    </PaperBox>
  );
};
