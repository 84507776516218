import { array, map, option } from 'fp-ts/es6';
import { ordString } from 'fp-ts/es6/Ord';
import { Collection, Feature, Map as OlMap, View } from 'ol';
import { Control, defaults as DefaultControls } from 'ol/control';
import ScaleLine, { Units as ScaleUnits } from 'ol/control/ScaleLine';
import { Extent, getCenter } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { Image as ImageLayer, Layer, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import * as olProj from 'ol/proj';
import Projection from 'ol/proj/Projection';
import Units from 'ol/proj/Units';
import RenderFeature from 'ol/render/Feature';
import {
  Cluster,
  ImageStatic as Static,
  Vector as VectorSource,
  XYZ,
  XYZ as XYZSource,
} from 'ol/source';
import {
  Fill,
  Stroke,
  Stroke as StrokeStyle,
  Style,
  Text,
  Circle as CircleStyle,
  Icon,
} from 'ol/style';
import { StyleFunction } from 'ol/style/Style';
import { MapType, SENSED_ASSETS } from '../../hooks/geomoby/LiveMapRenderer';
import { StrongFeatureHolder } from '../../hooks/geomoby/useLiveMapLoader';
import {
  beaconSensorIcon,
  beaconSensorIconStyled,
  deviceSensorIcon,
  deviceSensorIconStyled,
  gatewaySensorIcon,
  gatewaySensorIconStyled,
  createStyleCache,
  fenceEventStyle,
  MICROFENCE_LAYER_ID,
  MICROFENCE_LAYER_LABEL,
  microfenceStyle,
  walkerStyle,
  POINT,
  PIN_ELEMENT_SCALE,
  RED,
  LOCALISED_MAX_DISTANCE_M,
} from './BeaconUtils';
import { ClusterBeaconData, LayerFenceData, MicrofenceData, PointData } from './Messages';
import { OVERLAY } from './MapOverlay';
import { Ref, RefObject } from 'react';
import { get } from 'ol/proj';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { CLUSTER_DISTANCE, CLUSTER_FONT_FAMILY } from './ClusteringFunctions';
import { FenceZIndexes } from '../../util/ZIndexes';
import { fromNullable, isNone, none, some } from 'fp-ts/es6/Option';
import { MultiPolygon } from 'ol/geom';
import {
  MULTIPOLYGON_LAYER_ID,
  MULTIPOLYGON_LAYER_LABEL,
} from '../../hooks/geomoby/LiveMapOlFunctions';
import { MapApiKey } from '../../Authn/useServcieConfig';
import { useAtomValue } from 'jotai';
import { MapApiKeysRecord, MAP_API_KEYS } from '../../store/map';

// For now the light map is the only one required from MapBox. If we want any more in the future we can apply the style type dynamically.
const lightMapType = 'light-v11';
export const MapBoxToken =
  'pk.eyJ1IjoidmFsZ2VvbW9ieSIsImEiOiJjazdteW5oNzcwMXU0M2ZtZjk5aGw2dHpkIn0.yudO2qXxDBAow-2-JyWspA';
export const MapBoxSource = `https://api.mapbox.com/styles/v1/mapbox/${lightMapType}/tiles/256/{z}/{x}/{y}?access_token=${MapBoxToken}`;
export const MAP_SOURCE_TYPES = [
  'Road map',
  'Satellite',
  'Satellite & Roads',
  'Terrain & Roads',
  'Road without Building',
  'Light map',
  'Nearmap',
] as const;
export type MapSourceType = typeof MAP_SOURCE_TYPES[number];

export const apiKeyNameByMapSource: Record<MapSourceType, MapApiKey['provider'] | undefined> = {
  'Road map': undefined,
  Satellite: undefined,
  'Satellite & Roads': undefined,
  'Terrain & Roads': undefined,
  'Road without Building': undefined,
  'Light map': undefined,
  Nearmap: 'nearmap',
};

export const GOOGLE_FLAG_FOR_MAP_TYPE: Record<MapSourceType, string> = {
  'Road map': 'm',
  Satellite: 's',
  'Satellite & Roads': 'y',
  'Terrain & Roads': 'p',
  'Road without Building': 'r',
  'Light map': 'lm',
  Nearmap: 's',
};

const nearmapSource =
  'https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.img?tertiary=satellite&apikey=';

export const createMapSource = (mapType: MapSourceType, keys: MapApiKeysRecord) => {
  switch (mapType) {
    case 'Light map':
      return MapBoxSource;
    case 'Nearmap':
      return nearmapSource + keys['nearmap'];
    default:
      return `https://mt0.google.com/vt/lyrs=${GOOGLE_FLAG_FOR_MAP_TYPE[mapType]}&hl=en&x={x}&y={y}&z={z}`;
  }
};

export type Bounds = {
  latitude: number;
  longitude: number;
  extentInDegrees: number;
};

export const initialLatitude = -31.953512;
export const initialLongitude = 115.857048;
export const initialExtentInDegrees = 0.512;
export const initialZoomHeight = 11;

export const GEOFENCE = 'Geofence';
export const LABEL = 'label';
export const TRACKED_DEVICE = 'Tracked device';
export const TRACKING_BOUNDS = 'tracking_bounds';
const FONT_FAMILY_VALUE = `Open Sans, Montserrat, Arial, sans-serif`;

export type BufferShapeType = 'Circle' | 'Scaled';
export type DrawType =
  | 'CIRCLE'
  | 'POLYGON'
  | 'TRIPWIRE'
  | 'MULTIPOLYGON'
  | 'MICROFENCE_GATEWAY'
  | 'MICROFENCE_BEACON'
  | 'MICROFENCE_DEVICE'
  | 'MEASURE';

export type MeasurementType = 'M' | 'FT' | 'KM' | 'MI' | `M2` | 'FT2' | 'KM2' | 'MI2';
export type EditType = 'SHAPE_CHANGE' | 'SCALE_ROT';
export type GeofenceTypes = 'polygon' | 'line' | 'multipolygon';
export type MicrofenceTypes = 'smartplug' | 'device' | 'beacon' | 'gateway';
export enum FenceZone {
  none = 'none',
  breach = 'breach',
  buffer = 'buffer',
  cleared = 'cleared',
}
export enum MicrofenceZone {
  none = 'none',
  loading = 'loading',
  dumping = 'dumping',
  maintenance = 'maintenance',
}

export type ReassignedFence = { id: string; newLayerId: string; type: GeofenceTypes };

export const UserMapView = (edit: boolean, specifiedCoordinates: [number, number]) =>
  new View({
    center: olProj.fromLonLat(specifiedCoordinates),
    projection: 'EPSG:3857',
    extent: edit ? get('EPSG:3857').getExtent() : undefined,
    zoom: initialZoomHeight,
  });

export const SKOView = new View({
  center: olProj.fromLonLat([121.613252, -31.030456]),
  zoom: 14,
});

export function getStaticImageLayer(urlMapLevel: string, projection: Projection, extent: Extent) {
  return new ImageLayer({
    source: new Static({
      url: urlMapLevel,
      projection: projection,
      imageExtent: extent,
    }),
  });
}

export enum BaseLayerKey {
  WorldMap,
  FMGLevel2,
  FMGLevel3,
}

export interface MapDefaults {
  baseLayer: TileLayer<XYZ> | ImageLayer<Static>;
  view: View;
  controls: Collection<Control>;
  setSource?: (type: MapSourceType) => void;
}

export const createIndoorMapDefaults = (): MapDefaults => {
  const x = 1641;
  const y = 636;
  const extent: Extent = [0, 0, x, y];
  const projection = new Projection({
    code: 'indoor-map',
    units: Units.PIXELS,
    extent: extent,
  });

  const controls = DefaultControls();
  controls.clear();
  return {
    baseLayer: getStaticImageLayer('IndoorMap/GeoMoby_offices.svg', projection, extent),
    view: new View({
      projection: projection,
      center: getCenter(extent),
      zoom: 1.5,
      minZoom: 1,
      maxZoom: 8,
    }),
    controls,
  };
};

export const createOutdoorMapDefaults = ({
  sourceType,
  edit,
  specifiedCoordinates,
  mapApiKeys,
}: {
  sourceType: MapSourceType;
  edit: boolean;
  specifiedCoordinates: [number, number];
  mapApiKeys: MapApiKeysRecord;
}): MapDefaults => {
  const controls = DefaultControls();
  controls.clear();

  const baseLayer = new TileLayer({
    source: new XYZSource({
      url: createMapSource(sourceType, mapApiKeys),
    }),
  });

  return {
    baseLayer,
    view: UserMapView(edit, specifiedCoordinates),
    controls,
    setSource: (type: MapSourceType) =>
      baseLayer.setSource(
        new XYZSource({
          url: createMapSource(type, mapApiKeys),
        }),
      ),
  };
};

export function createMap(
  mapType: MapType,
  defaults: MapDefaults,
  ref?: RefObject<HTMLDivElement>,
): { map: OlMap; setSource?: (source: MapSourceType) => void } {
  return {
    map: new OlMap({
      target: ref?.current ?? mapType,
      overlays: [OVERLAY],
      layers: [defaults.baseLayer],
      controls: defaults.controls,
      view: defaults.view,
    }),
    setSource: defaults.setSource,
  };
}

export interface FenceLayerIdAndLayer {
  lid: string;
  l: { layer: VectorLayer<VectorSource<Geometry>>; name: string };
}

export interface ClusterLayerIdAndLayer {
  lid: string;
  l: { layer: AnimatedCluster; name: string };
}

export function olFenceLayers(
  ls: Map<string, { layer: StrongFeatureHolder<Polygon, LayerFenceData>[]; name: string }>,
): Map<string, { layer: VectorLayer<VectorSource<Geometry>>; name: string }> {
  const lids = map.keys(ordString)(ls);
  const styleCache = new Map<string, Style[]>();
  const forResult = array.filterMap<string, FenceLayerIdAndLayer>((lid: string) => {
    const osfh = fromNullable(ls.get(lid));
    if (isNone(osfh)) {
      return none;
    }
    const vs = new VectorSource();
    vs.setProperties({ id: lid, name: ls.get(lid)?.name });
    const vl = new VectorLayer({
      source: vs,
      style: setDefaultFenceStyle(styleCache),
    });

    osfh.value.layer.forEach(f => vs.addFeature(f.feature));
    return some({
      lid: lid,
      l: { layer: vl, name: ls.get(lid)?.name ?? lid },
    });
  })(lids);
  const result = new Map<string, { layer: VectorLayer<VectorSource<Geometry>>; name: string }>();
  forResult.forEach(({ lid, l }) => result.set(lid, l));
  return result;
}

export function olMicrofenceLayer(
  microfences: StrongFeatureHolder<Point, MicrofenceData>[],
  styleCache: Map<string, Style[]>,
): AnimatedCluster {
  const id = MICROFENCE_LAYER_ID;
  const name = MICROFENCE_LAYER_LABEL;
  const vs = new VectorSource();

  vs.addFeatures(microfences.map(microfence => microfence.feature));

  const layer = new AnimatedCluster({
    source: new Cluster({
      source: vs,
      distance: CLUSTER_DISTANCE,
    }),
    style: microfenceClusterStyle(styleCache),
    properties: { name, id },
  });
  return layer;
}

export function olClusterLayer(fences: StrongFeatureHolder<Point, PointData>[]): AnimatedCluster {
  const id = POINT;
  const styleCache = new Map<string, Style[]>();
  const vs = new VectorSource();

  vs.addFeatures(
    fences.map(fence => {
      fence.feature.set('id', fence.data.fenceId);
      fence.feature.set('name', fence.data.name);
      fence.feature.set('layerId', fence.data.layerId);
      fence.feature.set('type', fence.data.type);
      fence.feature.set('fenceType', fence.data.fenceType);
      return fence.feature;
    }),
  );

  const layer = new AnimatedCluster({
    source: new Cluster({
      source: vs,
      distance: CLUSTER_DISTANCE,
    }),
    style: styleForPoints(styleCache),
    properties: { id },
  });
  return layer;
}

export const fenceColors = {
  default: {
    stroke: [76, 153, 196],
    fill: [76, 153, 196, 0.3],
  },
  breach: {
    stroke: [209, 27, 21],
    fill: [209, 27, 21, 0.3],
  },
  buffer: {
    stroke: [235, 138, 12],
    fill: [235, 138, 12, 0.3],
  },
  cleared: {
    stroke: [182, 66, 245],
    fill: [182, 66, 245, 0.3],
  },
  tracking: {
    stroke: [255, 255, 255],
    fill: [255, 255, 255, 0.3],
  },
};

const defaultFenceStroke = (selected: boolean, mutlipolygon?: boolean) =>
  new StrokeStyle({
    color: mutlipolygon ? fenceColors.cleared.stroke : fenceColors.default.stroke,
    width: selected ? 4 : 2,
    lineDash: selected ? [4, 8] : [],
  });

const unknownFenceStyle = (selected: boolean) =>
  new Style({
    stroke: defaultFenceStroke(selected),
  });

/* Default Zone */
export const defaultFenceStyle = new Style({
  fill: new Fill({ color: fenceColors.default.fill }),
  stroke: new Stroke({ color: fenceColors.default.stroke, width: 3 }),
  zIndex: FenceZIndexes.FENCE_HIGHEST_Z_INDEX,
});
export const selectedDefaultFenceStyle = new Style({
  fill: new Fill({ color: fenceColors.default.fill }),
  stroke: new Stroke({ color: fenceColors.default.stroke, width: 4.5, lineDash: [4, 8] }),
  zIndex: FenceZIndexes.FENCE_MID_Z_INDEX,
});

/* Breach Zone */
export const breachFenceStyle = new Style({
  fill: new Fill({ color: fenceColors.breach.fill }),
  stroke: new Stroke({ color: fenceColors.breach.stroke, width: 3 }),
  zIndex: FenceZIndexes.FENCE_HIGHEST_Z_INDEX,
});
export const selectedBreachFenceStyle = new Style({
  fill: new Fill({ color: fenceColors.breach.fill }),
  stroke: new Stroke({ color: fenceColors.breach.stroke, width: 4.5, lineDash: [4, 8] }),
  zIndex: FenceZIndexes.FENCE_MID_Z_INDEX,
});

/* Buffer Zone */
export const bufferFenceStyle = new Style({
  fill: new Fill({ color: fenceColors.buffer.fill }),
  stroke: new Stroke({ color: fenceColors.buffer.stroke, width: 3 }),
  zIndex: FenceZIndexes.FENCE_LOWEST_Z_INDEX,
});
export const selectedBufferFenceStyle = new Style({
  fill: new Fill({ color: fenceColors.buffer.fill }),
  stroke: new Stroke({ color: fenceColors.buffer.stroke, width: 4.5, lineDash: [4, 8] }),
  zIndex: FenceZIndexes.FENCE_LOWEST_Z_INDEX,
});

/* Cleared Zone */
export const clearedFenceStyle = new Style({
  fill: new Fill({ color: fenceColors.cleared.fill }),
  stroke: new Stroke({ color: fenceColors.cleared.stroke, width: 3 }),
  zIndex: FenceZIndexes.FENCE_LOWEST_Z_INDEX,
});
export const selectedClearedFenceStyle = new Style({
  fill: new Fill({ color: fenceColors.cleared.fill }),
  stroke: new Stroke({ color: fenceColors.cleared.stroke, width: 4.5, lineDash: [4, 8] }),
  zIndex: FenceZIndexes.FENCE_LOWEST_Z_INDEX,
});

/* Tracking Bounds */
export const trackingFenceStyle = new Style({
  fill: new Fill({ color: fenceColors.tracking.fill }),
  stroke: new Stroke({ color: fenceColors.tracking.stroke, width: 4.5 }),
  zIndex: FenceZIndexes.FENCE_LOWEST_Z_INDEX,
});

export const selectedTrackingFenceStyle = new Style({
  fill: new Fill({ color: fenceColors.tracking.fill }),
  stroke: new Stroke({ color: fenceColors.tracking.stroke, width: 4.5, lineDash: [4, 8] }),
  zIndex: FenceZIndexes.FENCE_LOWEST_Z_INDEX,
});

/* Microfence Gateways */
export const defaultMicrofenceGatewayStyle = new Style({
  image: gatewaySensorIconStyled({ color: [192, 192, 192], scale: 0.8 }),
  zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
});
export const selectedMicrofenceGatewayStyle = new Style({
  image: gatewaySensorIconStyled({ opacity: 1 }),
  zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
});

/* Microfence Beacons */
export const defaultMicrofenceBeaconStyle = new Style({
  image: beaconSensorIconStyled({ color: [192, 192, 192], scale: 0.8 }),
  zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
});
export const selectedMicrofenceBeaconStyle = new Style({
  image: beaconSensorIconStyled({ opacity: 1 }),
  zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
});

/* Microfence Devices */
export const defaultMicrofenceDeviceStyle = new Style({
  image: deviceSensorIconStyled({ color: [192, 192, 192], scale: 0.8 }),
  zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
});
export const selectedMicrofenceDeviceStyle = new Style({
  image: deviceSensorIconStyled({ opacity: 1 }),
  zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
});

const innerCircleFill = new Fill({
  color: 'rgba(0, 184, 217, 0.7)',
});
const outerCircleFill = new Fill({
  color: 'rgba(0, 184, 217, 0.3)',
});
const textStroke = new Stroke({
  color: 'rgba(0, 0, 0, 0.6)',
  width: 3,
});
const innerCircle = new CircleStyle({
  radius: 10,
  fill: innerCircleFill,
});
const outerCircle = new CircleStyle({
  radius: 18,
  fill: outerCircleFill,
});

const innerCircleFillMicrofence = new Fill({
  color: 'rgba(0,246,255,0.7)',
});
const outerCircleFillMicrofence = new Fill({
  color: 'rgba(17,246,255,0.3)',
});
export const innerCircleMicrofence = new CircleStyle({
  radius: 10,
  fill: innerCircleFillMicrofence,
});
export const outerCircleMicrofence = new CircleStyle({
  radius: 18,
  fill: outerCircleFillMicrofence,
});

export const cacheKeyForFeature = (feature: Feature<Geometry> | RenderFeature) => {
  const id = feature.get('id');
  const zoneSuffix =
    feature.get('zone') === FenceZone.breach
      ? '-breach'
      : feature.get('zone') === FenceZone.buffer
      ? '-buffer'
      : '';
  return `${id}${zoneSuffix}`;
};

export function styleForFence(
  fid: string,
  props: {
    selected?: boolean;
    name?: string;
    zone?: FenceZone;
    layerName?: string;
    points?: { type: string; coordinates: [longitude: number, latitude: number][][] };
    numberOfArrows?: number;
  },
  geometry?: Geometry | undefined,
  styleType?: string,
): Style[] {
  let styles: Style[] = [];
  if (props.zone === FenceZone.breach) {
    styles = [props.selected ? selectedBreachFenceStyle : breachFenceStyle];
  } else if (props.zone === FenceZone.buffer) {
    styles = [props.selected ? selectedBufferFenceStyle : bufferFenceStyle];
  } else if (props.zone === FenceZone.cleared) {
    styles = [props.selected ? selectedClearedFenceStyle : clearedFenceStyle];
  } else if (props.layerName === TRACKING_BOUNDS) {
    styles = [props.selected ? selectedTrackingFenceStyle : trackingFenceStyle];
  } else {
    styles = [props.selected ? selectedDefaultFenceStyle : defaultFenceStyle];
  }

  if (geometry) styles[0].setGeometry(geometry);

  if (props?.points?.type === 'LineString') {
    const coords = props.points.coordinates;
    const x1 = Number(coords[0][0]);
    const x2 = Number(coords[1][0]);
    const y1 = Number(coords[0][1]);
    const y2 = Number(coords[1][1]);
    const dx = x1 - x2;
    const dy = y2 - y1;
    const x1Direction = x1 > x2 ? -Math.abs(x2 - x1) : Math.abs(x2 - x1);
    const x2Direction = x1 > x2 ? Math.abs(x2 - x1) : -Math.abs(x2 - x1);
    const y1Direction = y1 > y2 ? -Math.abs(y2 - y1) : Math.abs(y2 - y1);
    const y2Direction = y1 > y2 ? Math.abs(y2 - y1) : -Math.abs(y2 - y1);
    const rotation = -Math.atan2(dx, dy);

    const point1 = new Point(
      olProj.transform([x1 + x1Direction * 0.1, y1 + y1Direction * 0.1], 'EPSG:4326', 'EPSG:3857'),
    );
    const point2 = new Point(
      olProj.transform([x1 + x1Direction * 0.3, y1 + y1Direction * 0.3], 'EPSG:4326', 'EPSG:3857'),
    );
    const point3 = new Point(
      olProj.transform([x2 + x2Direction * 0.5, y2 + y2Direction * 0.5], 'EPSG:4326', 'EPSG:3857'),
    );
    const point4 = new Point(
      olProj.transform([x2 + x2Direction * 0.3, y2 + y2Direction * 0.3], 'EPSG:4326', 'EPSG:3857'),
    );
    const point5 = new Point(
      olProj.transform([x2 + x2Direction * 0.1, y2 + y2Direction * 0.1], 'EPSG:4326', 'EPSG:3857'),
    );

    const arrows: Style[] =
      props.numberOfArrows === 2
        ? [...styles, styleForArrow(point1, rotation), styleForArrow(point5, rotation)]
        : props.numberOfArrows === 3
        ? [
            ...styles,
            styleForArrow(point1, rotation),
            styleForArrow(point3, rotation),
            styleForArrow(point5, rotation),
          ]
        : [
            ...styles,
            styleForArrow(point1, rotation),
            styleForArrow(point2, rotation),
            styleForArrow(point4, rotation),
            styleForArrow(point5, rotation),
          ];
    return arrows;
  }
  return styles;
}

function styleForLine(): Style {
  return new Style({
    fill: new Fill({ color: '#f24439' }),
    stroke: new Stroke({ color: '#f24439', width: 3 }),
    zIndex: FenceZIndexes.LINE_Z_INDEX,
  });
}

function styleForLabel(): StyleFunction {
  return feature => {
    return new Style({
      text: new Text({
        text: feature.get(LABEL),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3,
        }),
      }),
    });
  };
}

export function getExtentDifference(e1: Extent, e2: Extent): number {
  return (
    Math.abs(e1[0] - e2[0]) +
    Math.abs(e1[1] - e2[1]) +
    Math.abs(e1[2] - e2[2]) +
    Math.abs(e1[3] - e2[3])
  );
}

export function styleForPoints(cache: Map<string, Style | Style[]>): StyleFunction {
  return (f0: Feature<Geometry> | RenderFeature, p1) => {
    const size = f0.get('features').length;
    let extentDifference = Number.MAX_SAFE_INTEGER;
    let styles: Style[] = [];
    if (size > 1) {
      const features: Feature<Geometry>[] = f0.get('features');
      const firstExent = f0.get('features')[0].getGeometry()?.getExtent();
      const center = getCenter(firstExent);
      styles.push(styleForPin(new Point([center[0], center[1]])));

      for (let f = 1; f < features.length; f++) {
        const currentExtent = f0.get('features')[f].getGeometry()?.getExtent();
        if (firstExent && currentExtent) {
          extentDifference = getExtentDifference(firstExent, currentExtent);
          if (extentDifference <= 1) {
            const center = getCenter(currentExtent);
            styles.push(styleForPin(new Point([center[0] + f * 20, center[1]])));
          } else {
            styles = [];
            break;
          }
        }
      }
    }

    if (styles.length > 0 && extentDifference <= 1) {
      return styles;
    }

    if (size <= 1) {
      return styleForPin();
    }

    return [
      new Style({
        image: outerCircle,
        zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
      }),
      new Style({
        image: innerCircle,
        zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX + 1,
        text: new Text({
          text: size.toString(),
          font: CLUSTER_FONT_FAMILY,
          fill: new Fill({
            color: '#fff',
          }),
          stroke: textStroke,
        }),
      }),
    ];
  };
}

function styleForPin(geometry?: Point) {
  // Need to include markerLine.png for tripwires. This will require adding the fenceType to our points array coming from Triggers. Next time.
  return new Style({
    geometry,
    image: new Icon({
      src: '../../../markerPolygon.png',
    }),
    zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
  });
}

function styleForArrow(point: Point, rotation: number): Style {
  return new Style({
    geometry: point,
    image: new Icon({
      src: '../../../triangle.svg',
      rotateWithView: true,
      rotation: rotation,
      scale: 1,
    }),
  });
}

export function defaultPopUpStyle(name: string): string {
  return (
    '<div style="' +
    'width: max-content;' +
    'padding: 5px;' +
    'border-radius: 12px;' +
    'background: rgba(169,169,169,0.7);' +
    'color: rgb(0,0,0,1);' +
    'box-shadow: 0 3px 14px rgba(0,0,0,0.4);' +
    'line-height: 1.4;' +
    'text-align: left;' +
    'font-family: ' +
    FONT_FAMILY_VALUE +
    ';' +
    'position: absolute;' +
    'font-weight: 700;">' +
    name.replace(/\n/g, '<br />') +
    '</div>'
  );
}

export function eventPopUpStyle(event: {
  latitude: string;
  longitude: string;
  fenceName: string;
  deviceId: string;
  timestamp: Date;
}): string {
  return (
    '<div style="' +
    'width: max-content;' +
    'padding: 5px;' +
    'border-radius: 12px;' +
    'background: rgba(169,169,169,0.7);' +
    'color: rgb(0,0,0,1);' +
    'box-shadow: 0 3px 14px rgba(0,0,0,0.4);' +
    'line-height: 1.4;' +
    'text-align: left;' +
    'font-family: ' +
    FONT_FAMILY_VALUE +
    ';' +
    'position: absolute;' +
    'font-weight: 700;">' +
    '<div><span style="font-weight: bolder">Latitude: </span>' +
    Number(event.latitude).toFixed(6) +
    '</div>' +
    '<div><span style="font-weight: bolder">Longitude: </span>' +
    Number(event.longitude).toFixed(6) +
    '</div>' +
    '<div><span style="font-weight: bolder">Geofence Name: </span>' +
    event.fenceName?.replace(/\n/g, '<br /></div>') +
    '<div><span style="font-weight: bolder">Device ID: </span>' +
    event.deviceId?.replace(/\n/g, '<br /></div>') +
    '<div><span style="font-weight: bolder">Occurred on: </span>' +
    event.timestamp +
    '</div>' +
    '</div>'
  );
}

export function selectedGeoOrMicroFenceStyle(cache: Map<string, Style[]>): StyleFunction {
  return (feature: Feature<Geometry> | RenderFeature, p1) => {
    const innerFeatures = feature.get('features');
    if (
      Array.isArray(innerFeatures) &&
      innerFeatures.length > 0 &&
      innerFeatures[0].get('layerId') === MICROFENCE_LAYER_ID
    ) {
      return defaultMicrofenceClusterStyle(cache)(feature, p1);
    }

    if (
      feature.getGeometry() instanceof Point &&
      Array.isArray(innerFeatures) &&
      innerFeatures.length > 0 &&
      innerFeatures[0].get('assetId') &&
      innerFeatures[0].get('layerId') === MICROFENCE_LAYER_ID
    ) {
      return microfenceStyle(cache)(innerFeatures[0], p1);
    } else {
      return setDefaultFenceStyle(cache)(feature, p1);
    }
  };
}

export function microfenceClusterStyle(cache: Map<string, Style[]>): StyleFunction {
  return (feature: Feature<Geometry> | RenderFeature, p1) => {
    const innerFeatures = feature.get('features');
    if (
      Array.isArray(innerFeatures) &&
      innerFeatures.length > 1 &&
      innerFeatures[0].get('layerId') === MICROFENCE_LAYER_ID
    ) {
      return defaultMicrofenceClusterStyle(cache)(feature, p1);
    }
    return microfenceStyle(cache)(innerFeatures[0], p1);
  };
}

export interface LayerParts {
  src: VectorSource<Geometry>;
  lyr: VectorLayer<VectorSource<Geometry>>;
}

export function setDefaultFenceStyle(
  cache: Map<string, Style[]>,
  styleType?: string,
): StyleFunction {
  return (f0: Feature<Geometry> | RenderFeature, p1) => {
    const fid = fromNullable(f0.get('id'));
    if (isNone(fid)) {
      return unknownFenceStyle(f0.get('selected'));
    }
    const styles = styleForFence(fid.value, f0.getProperties(), undefined, styleType);
    return styles;
  };
}

export function defaultMicrofenceClusterStyle(cache: Map<string, Style[]>): StyleFunction {
  return (feature: Feature<Geometry> | RenderFeature, p1) => {
    const size = feature.get('features').length;
    if (size === 1) {
      return microfenceStyle(cache)(feature.get('features')[0], p1);
    }
    return [
      new Style({
        image: outerCircleMicrofence,
        zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
      }),
      new Style({
        image: innerCircleMicrofence,
        zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX + 1,
        text: new Text({
          text: size.toString(),
          font: CLUSTER_FONT_FAMILY,
          fill: new Fill({
            color: '#fff',
          }),
          stroke: textStroke,
        }),
      }),
    ];
  };
}

export function defaultWalkerLayer(): LayerParts {
  const wsc = createStyleCache();
  const src = new VectorSource();
  return {
    src: src,
    lyr: new VectorLayer({
      source: src,
      style: walkerStyle(wsc),
      zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
    }),
  };
}

export function defaultFenceEventLayer(): LayerParts {
  const wsc = createStyleCache();
  const src = new VectorSource();
  return {
    src: src,
    lyr: new VectorLayer({
      source: src,
      style: fenceEventStyle(wsc),
      zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
    }),
  };
}

export function defaultLinesLayer(): LayerParts {
  const src = new VectorSource();
  return {
    src: src,
    lyr: new VectorLayer({
      source: src,
      style: styleForLine(),
      zIndex: FenceZIndexes.FENCE_LOWEST_Z_INDEX,
    }),
  };
}

export function defaultLabelLayer(): LayerParts {
  const src = new VectorSource();
  return {
    src: src,
    lyr: new VectorLayer({
      source: src,
      style: styleForLabel(),
      zIndex: FenceZIndexes.FENCE_LOWEST_Z_INDEX,
    }),
  };
}

export function updateMicrofenceStyle(
  cache: Map<string, Style[]>,
  microfences: Feature<Geometry>[],
  resolution: number,
) {
  cache.forEach((s, id) => {
    const microfence = microfences.find(m => id.includes(m.get('id')));
    if (!microfence) return;
    const sensedAssets = microfence.get(SENSED_ASSETS) ?? [];
    const sensedCount = Array.isArray(sensedAssets) ? sensedAssets.length : 0;

    if (sensedCount > 0 && Array.isArray(s)) {
      s[0].setZIndex(
        microfence.get('selected')
          ? FenceZIndexes.MARKER_FENCE_Z_INDEX + 1
          : FenceZIndexes.MARKER_FENCE_Z_INDEX,
      );

      // Create counter
      s[1] = new Style({
        image: new CircleStyle({
          displacement: [12.5, 13],
          radius: 10,
          fill: new Fill({
            color: RED,
          }),
        }),
        text: new Text({
          text: sensedCount > 99 ? '99+' : sensedCount.toString(),
          fill: new Fill({
            color: '#fff',
          }),
          font: `10px Open Sans, Montserrat, Arial, sans-serif`,
          stroke: new Stroke({
            color: '#fff',
            width: 1,
          }),
          offsetX: 13,
          offsetY: -13,
          scale: 0.9,
        }),
        zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX + 1,
      });

      // Create radial field
      const fieldOpacity = LOCALISED_MAX_DISTANCE_M / resolution > 10;
      s[2] = new Style({
        image: new CircleStyle({
          radius: LOCALISED_MAX_DISTANCE_M / resolution,
          fill: new Fill({
            color: `rgb(136,61,232,${fieldOpacity ? 0.07 : 0})`,
          }),
          stroke: new Stroke({
            color: `rgb(136,61,232,${fieldOpacity ? 1 : 0})`,
            width: 2,
          }),
        }),
        zIndex: 1,
      });
    }
  });
}
