import {
  ActionType,
  CrossingType,
  CrossingTypeId,
  CrossingTypeValue,
  EnteredType,
  GeofenceType,
  ListenerType,
  OrderByType,
  OrderType,
  TriggerSubTypeId,
  TriggerSubTypeValue,
  TriggerType,
} from './types';

export const ActionTypes: ActionType[] = [
  { id: 'EMAIL', value: 'Email' },
  { id: 'NOTIFICATION', value: 'Notification', disabled: true },
  { id: 'WEBHOOK', value: 'Webhook' },
];

export const Crossings: CrossingType[] = [
  { key: 1, value: 'LEFT', label: 'Left-to-right' },
  { key: 2, value: 'RIGHT', label: 'Right-to-left' },
];

export const EnteredTypes: EnteredType[] = [
  { id: 'ABOVE', value: 'Above', booleanValue: true, listenerTypeId: 'sensor' },
  { id: 'BELOW', value: 'Below', booleanValue: false, listenerTypeId: 'sensor' },
  { id: 'ENTER_GEOFENCE', value: 'Enter', booleanValue: true, listenerTypeId: 'geofence' },
  { id: 'EXIT_GEOFENCE', value: 'Exit', booleanValue: false, listenerTypeId: 'geofence' },
  { id: 'DWELL_GEOFENCE', value: 'Dwell', booleanValue: true, listenerTypeId: 'geofence' },
  { id: 'ENTER_MICROFENCE', value: 'Enter', booleanValue: true, listenerTypeId: 'microfence' },
  { id: 'EXIT_MICROFENCE', value: 'Exit', booleanValue: false, listenerTypeId: 'microfence' },
  {
    id: 'LEFT',
    value: 'Right-to-left',
    booleanValue: true,
    listenerTypeId: 'geofence',
    geofenceTypeId: 'line',
  },
  {
    id: 'RIGHT',
    value: 'Left-to-right',
    booleanValue: false,
    listenerTypeId: 'geofence',
    geofenceTypeId: 'line',
  },
];

export const GeofenceTypes: GeofenceType[] = [
  { id: 'line', value: 'Line' },
  { id: 'multipolygon', value: 'Multipolygon' },
  { id: 'polygon', value: 'Polygon' },
];

export const ListenerTypes: ListenerType[] = [
  { id: 'geofence', value: 'Location' },
  { id: 'microfence', value: 'Proximity' },
  { id: 'sensor', value: 'Sensor (eg. Battery, Temperature, Spo2, etc)' },
];

export const OrderTypes: OrderType[] = [
  { id: 'ASC', value: 'Ascending' },
  { id: 'DESC', value: 'Descending' },
];

export const OrderByTypes: OrderByType[] = [
  { id: 'entered', value: 'Event Trigger Option' },
  { id: 'listenerType', value: 'Event Trigger Type' },
  { id: 'geofenceType', value: 'Geofence Type' },
  { id: 'method', value: 'Method', parentTypeId: 'WEBHOOK' },
  { id: 'notifierType', value: 'Notification Type' },
  { id: 'recipients', value: 'Recipients', parentTypeId: 'EMAIL' },
  { id: 'subject', value: 'Subject', parentTypeId: 'EMAIL' },
  { id: 'text', value: 'Text', parentTypeId: 'EMAIL' },
  { id: 'url', value: 'Url', parentTypeId: 'WEBHOOK' },
];

export const TriggerSubTypes: {
  key: number;
  value: TriggerSubTypeId;
  label: TriggerSubTypeValue;
}[] = [
  { key: 1, value: 'CLEARED_ZONE', label: 'Cleared Zone' },
  { key: 2, value: 'GEOFENCE', label: 'Geofence' },
];

export const TriggerTypes: TriggerType[] = [
  {
    id: 'BATTERY',
    value: 'Battery',
    options: [
      { id: 'ABOVE', value: 'Above' },
      { id: 'BELOW', value: 'Below' },
    ],
  },
  {
    id: 'CUSTOM',
    value: 'Custom',
    disabled: true,
    options: [
      { id: 'NUMBER', value: 'Number' },
      { id: 'STRING', value: 'String' },
    ],
  },
  {
    id: 'ENVIRONMENTALMONITORING',
    value: 'Environmental Monitoring',
    disabled: true,
    options: [],
  },
  {
    id: 'EXTENSOMETER',
    value: 'Extensometer',
    disabled: true,
    options: [],
  },
  {
    id: 'INACTIVITY',
    value: 'Inactivity',
    disabled: true,
    options: [],
  },
  {
    id: 'LOCATION',
    value: 'Location',
    options: [
      { id: 'CROSSING', value: 'Crossing' },
      { id: 'DWELL', value: 'Dwell' },
      { id: 'ENTRY', value: 'Entry' },
      { id: 'EXIT', value: 'Exit' },
    ],
    subTypes: [
      { id: 'CLEARED_ZONE', value: 'Cleared Zone' },
      { id: 'GEOFENCE', value: 'Geofence' },
    ],
  },
  {
    id: 'MANDOWN',
    value: 'ManDown',
    disabled: true,
    options: [],
  },
  {
    id: 'PROXIMITY',
    value: 'Proximity',
    options: [
      { id: 'ENTRY', value: 'Entry' },
      { id: 'EXIT', value: 'Exit' },
    ],
  },

  {
    id: 'SCHEDULER',
    value: 'Scheduler',
    disabled: true,
    options: [{ id: 'NEW_RULE', value: 'New rule' }],
  },
  {
    id: 'SPO2',
    value: 'Spo2',
    options: [
      { id: 'ABOVE', value: 'Above' },
      { id: 'BELOW', value: 'Below' },
    ],
  },
  {
    id: 'TEMPERATURE',
    value: 'Temperature',
    options: [
      { id: 'ABOVE', value: 'Above' },
      { id: 'BELOW', value: 'Below' },
    ],
  },
  {
    id: 'WEBHOOK',
    value: 'Webhook',
    disabled: true,
    options: [
      { id: 'GET', value: 'GET' },
      { id: 'POST', value: 'POST' },
    ],
  },
  {
    id: 'WELFARECHECKRESPONSE',
    value: 'Welfare Check Response',
    disabled: true,
    options: [],
  },
];
