/* eslint-disable react/no-children-prop */
import { Toolbar } from './Toolbar';
import React, { ReactNode } from 'react';
import { useMobile } from '../../../util/useMobile';
import { Grid } from '@mui/material';

export const PenToolbar = ({
  children,
  marginLeft,
}: {
  children: ReactNode[];
  marginLeft?: string;
}) => {
  const isMobile = useMobile();

  return (
    <Grid
      sx={{
        position: 'relative',
        top: `calc(40% - ${isMobile || window.innerHeight < 750 ? '0' : '8'}rem)`,
        '& .MuiBox-root': {
          overflow: 'auto',
          height: isMobile || window.innerHeight < 750 ? '200px' : 'auto',
          borderRadius: '0px',
        },
        marginLeft: marginLeft ?? '0px',
      }}
    >
      <Toolbar children={children} /*top={'40%'}*/ left={8} />
    </Grid>
  );
};
