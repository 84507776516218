/* eslint-disable no-var */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array } from 'fp-ts';
import { option } from 'fp-ts/es6';
import { sequenceT } from 'fp-ts/es6/Apply';
import {
  fromNullable,
  none,
  chain,
  toNullable,
  some,
  Option,
  isSome,
  isNone,
  toUndefined,
} from 'fp-ts/es6/Option';
import { ordString } from 'fp-ts/es6/Ord';
import { pipe } from 'fp-ts/es6/pipeable';
import { Collection, Feature, Overlay, View } from 'ol';
import { always, click, never, platformModifierKeyOnly, primaryAction } from 'ol/events/condition';
import Circle from 'ol/geom/Circle';
import Geometry from 'ol/geom/Geometry';
import GeometryType from 'ol/geom/GeometryType';
import Polygon, { fromCircle } from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Modify, Select, Snap, Translate } from 'ol/interaction';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { Vector as VectorLayer } from 'ol/layer';
import olMap from 'ol/Map';
import 'ol/ol.css';
import { Cluster, Vector as VectorSource } from 'ol/source';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { polygonPointsFromJson, jsonFromLayer } from '../../../hooks/geomoby/LiveMapOlFunctions';
import { fitToExtent, getCoordinateDifference, panTo } from '../../../hooks/geomoby/MapAnimation';
import { useApiBase } from '../../../hooks/geomoby/useApiBase';
import {
  Bounds,
  createMap,
  createOutdoorMapDefaults,
  initialLatitude,
  initialLongitude,
  initialExtentInDegrees,
  GEOFENCE,
  MapSourceType,
  selectedMicrofenceBeaconStyle,
  defaultMicrofenceBeaconStyle,
  initialZoomHeight,
  selectedMicrofenceGatewayStyle,
  selectedMicrofenceDeviceStyle,
  defaultMicrofenceGatewayStyle,
  defaultMicrofenceDeviceStyle,
  FenceZone,
  MicrofenceTypes,
  DrawType,
  EditType,
  ReassignedFence,
  BufferShapeType,
  MeasurementType,
  selectedBreachFenceStyle,
  breachFenceStyle,
  selectedBufferFenceStyle,
  bufferFenceStyle,
  selectedClearedFenceStyle,
  clearedFenceStyle,
  selectedDefaultFenceStyle,
  defaultFenceStyle,
  selectedTrackingFenceStyle,
  trackingFenceStyle,
  TRACKING_BOUNDS,
  MicrofenceZone,
} from '../MapDefaults';
import { GlobalProjectId, gpidToPath, LayerFenceData } from '../Messages';
import {
  getMicrofencesVectorLayer,
  getVectorLayer,
  getVectorLayers,
  setSelectedFenceStyle,
  setSelectedLayerStyle,
  updateMicroFenceIds,
  updateFenceOnMap,
  FenceNameIdZone,
  NameId,
  MicrofenceAssetId,
} from './GeofenceEditorFunctions';
import { MapContainer } from '../MapContainer/MapContainer';
import { SidebarAndMap } from '../SidebarAndMap/SidebarAndMap';
import { ZoomIn } from '../Toolbar/ZoomTools/ZoomIn';
import { ZoomOut } from '../Toolbar/ZoomTools/ZoomOut';
import { MapToolbar } from '../Toolbar/MapToolbar';
import { ChangeLayer } from '../Toolbar/LayerTools/ChangeLayer';
import { ChangeMapSourceType } from '../Toolbar/LayerTools/ChangeMapSourceType';
import { useAtomValue, useSetAtom } from 'jotai';
import { CID, PID } from '../../../store/user';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { TRIGGERS_URL } from '../../../store/url';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import { containsExtent, Extent, getCenter, getHeight, getWidth } from 'ol/extent';
import { StyleFunction } from 'ol/style/Style';
import RenderFeature from 'ol/render/Feature';
import CircleStyle from 'ol/style/Circle';
import {
  CLUSTER_MAX_ZOOM,
  FENCE_VIEWING_HEIGHT,
  selectedFenceClusterPointStyle,
  selectedLayerClusterPointStyle,
  styleFunctionForClusteredFeatures,
  ZOOM_THRESHOLD,
} from '../ClusteringFunctions';
import { newIORef } from 'fp-ts/lib/IORef';
import { transform, transformExtent } from 'ol/proj';
import { debounce } from 'lodash';
import LineString from 'ol/geom/LineString';
import {
  beaconSensorIcon,
  beaconSensorIconStyled,
  MICROFENCE,
  MICROFENCE_DEFAULT_PROPS,
  MICROFENCE_LAYER_ID,
  MICROFENCE_LAYER_LABEL,
  MICROFENCE_PROP_LABELS,
} from '../BeaconUtils';
import BaseVectorLayer from 'ol/layer/BaseVector';
import CanvasVectorLayerRenderer from 'ol/renderer/canvas/VectorLayer';
import CanvasVectorTileLayerRenderer from 'ol/renderer/canvas/VectorTileLayer';
import CanvasVectorImageLayerRenderer from 'ol/renderer/canvas/VectorImageLayer';
import WebGLPointsLayerRenderer from 'ol/renderer/webgl/PointsLayer';
import { ModifyEvent } from 'ol/interaction/Modify';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { SaveResult, SAVE_NOTIFICATION } from '../../../store/notifications';
import { FenceZIndexes } from '../../../util/ZIndexes';
import { LoadIndicator } from '../Toolbar/LayerTools/LoadIndicator';

import { getArea, getLength } from 'ol/sphere';
import { Button, Dialog, DialogActions, DialogTitle, Grid } from '@mui/material';
import { ToolPanel } from '../ControlPanels/ToolPanel';
import { MAP_API_KEYS } from '../../../store/map';
import { LocationDisplayType, LocationSearch, LocationSearchData } from '../Toolbar/LocationSearch';
import { GeofenceEditorSearch } from '../ControlPanels/GeofenceEditorSearch';
import { GridRowData } from '@material-ui/data-grid';
import { GeofenceFilter, LayerFilter, MicrofenceFilter, SearchType } from '../types';
import { GeofenceEditorFilter } from '../ControlPanels/GeofenceEditorFilter';
import { StrongFeatureHolder } from '../../../hooks/geomoby/useLiveMapLoader';

type AnyRenderer =
  | CanvasVectorLayerRenderer
  | CanvasVectorTileLayerRenderer
  | CanvasVectorImageLayerRenderer
  | WebGLPointsLayerRenderer;
type HitDetectionLayer = BaseVectorLayer<VectorSource<Geometry>, AnyRenderer>;

type ReassignedProperties = FenceNameIdZone & {
  points: { type: 'Polygon' | 'LineString' | 'MultiPolygon'; coordinates: number[][][] };
};
type SavedFeature = { id: string; name: string; parentId: string | undefined };

const styleCache = new Map<string, Style[]>();

export const DEFAULT_BUFFER_METERS = 250;
export const FRESH = 'fresh';
export const FRESH_UNKNOWN_LAYER = 'fresh-UNKNOWN-LAYER';

const geometryIsLine = (coords: number[]): boolean => {
  return coords.length === 4;
};

const getCoordsForNewTripwire = (
  coords: number[],
  numberOfArrows: number,
): { type: string; coordinates: number[][]; numberOfArrows: number } => {
  const coordsA: number[] = transform([coords[0], coords[1]], 'EPSG:3857', 'EPSG:4326');
  const coordsB: number[] = transform([coords[2], coords[3]], 'EPSG:3857', 'EPSG:4326');
  return { type: 'LineString', coordinates: [coordsA, coordsB], numberOfArrows };
};

function styleForArrow(point: Point, rotation: number): Style {
  return new Style({
    geometry: point,
    image: new Icon({
      src: '../../../triangle.svg',
      rotateWithView: true,
      rotation: rotation,
      scale: 1,
    }),
    zIndex: FenceZIndexes.FENCE_LOWEST_Z_INDEX,
  });
}

function stylesForLineFence(
  points: { type: string; coordinates: number[][]; numberOfArrows?: number },
  selectedFence?: boolean,
  selectedLayer?: boolean,
): Style[] {
  const coords = points.coordinates;
  const x1 = Number(coords[0][0]);
  const x2 = Number(coords[1][0]);
  const y1 = Number(coords[0][1]);
  const y2 = Number(coords[1][1]);
  const dx = x1 - x2;
  const dy = y2 - y1;
  const x1Direction = x1 > x2 ? -Math.abs(x2 - x1) : Math.abs(x2 - x1);
  const x2Direction = x1 > x2 ? Math.abs(x2 - x1) : -Math.abs(x2 - x1);
  const y1Direction = y1 > y2 ? -Math.abs(y2 - y1) : Math.abs(y2 - y1);
  const y2Direction = y1 > y2 ? Math.abs(y2 - y1) : -Math.abs(y2 - y1);
  const rotation = -Math.atan2(dx, dy);

  const point1 = new Point(
    transform([x1 + x1Direction * 0.1, y1 + y1Direction * 0.1], 'EPSG:4326', 'EPSG:3857'),
  );
  const point2 = new Point(
    transform([x1 + x1Direction * 0.3, y1 + y1Direction * 0.3], 'EPSG:4326', 'EPSG:3857'),
  );
  const point3 = new Point(
    transform([x1 + x1Direction * 0.5, y1 + y1Direction * 0.5], 'EPSG:4326', 'EPSG:3857'),
  );
  const point4 = new Point(
    transform([x2 + x2Direction * 0.3, y2 + y2Direction * 0.3], 'EPSG:4326', 'EPSG:3857'),
  );
  const point5 = new Point(
    transform([x2 + x2Direction * 0.1, y2 + y2Direction * 0.1], 'EPSG:4326', 'EPSG:3857'),
  );

  const arrows: Style[] =
    points.numberOfArrows === 2
      ? [styleForArrow(point1, rotation), styleForArrow(point5, rotation)]
      : points.numberOfArrows === 3
      ? [
          styleForArrow(point1, rotation),
          styleForArrow(point3, rotation),
          styleForArrow(point5, rotation),
        ]
      : [
          styleForArrow(point1, rotation),
          styleForArrow(point2, rotation),
          styleForArrow(point4, rotation),
          styleForArrow(point5, rotation),
        ];
  return arrows;
}

function styleForDraw(layerSource: VectorSource<Geometry>, type: string, color: string): Draw {
  return new Draw({
    source: layerSource,
    type,
    stopClick: true,
    style: [
      new Style({
        image: new CircleStyle({
          radius: 6.5,
          fill: new Fill({
            color,
          }),
        }),
        stroke: new Stroke({
          color,
          width: 4.5,
        }),
        zIndex: FenceZIndexes.LINE_Z_INDEX,
      }),
      new Style({
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color,
          }),
        }),
        stroke: new Stroke({
          color,
          width: 3,
        }),
        zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX,
      }),
    ],
  });
}

const selectedLayerFeatureStyle =
  (): StyleFunction => (f0: Feature<Geometry> | RenderFeature, p1) => {
    const props = f0.getProperties();

    if (f0.getGeometry() instanceof Point) {
      if (props['selected']) {
        return props['microfenceType'] === 'beacon'
          ? selectedMicrofenceBeaconStyle
          : props['microfenceType'] === 'device'
          ? selectedMicrofenceDeviceStyle
          : selectedMicrofenceGatewayStyle;
      } else {
        return props['microfenceType'] === 'beacon'
          ? defaultMicrofenceBeaconStyle
          : props['microfenceType'] === 'device'
          ? defaultMicrofenceDeviceStyle
          : defaultMicrofenceGatewayStyle;
      }
    }

    if (props['zone'] === FenceZone.breach) {
      return props['selected'] ? selectedBreachFenceStyle : breachFenceStyle;
    } else if (props['zone'] === FenceZone.buffer) {
      return props['selected'] ? selectedBufferFenceStyle : bufferFenceStyle;
    } else if (props['zone'] === FenceZone.cleared) {
      return props['selected'] ? selectedClearedFenceStyle : clearedFenceStyle;
    } else if (
      props['layerName'] === TRACKING_BOUNDS &&
      !(
        props.type?.toLowerCase().includes('line') ||
        props.points?.type?.toLowerCase().includes('line')
      )
    ) {
      return props['selected'] ? selectedTrackingFenceStyle : trackingFenceStyle;
    }

    if (props['selected']) {
      if (
        props.type?.toLowerCase().includes('line') ||
        props.points?.type?.toLowerCase().includes('line')
      ) {
        return [
          selectedDefaultFenceStyle,
          ...stylesForLineFence(
            getCoordsForNewTripwire(
              props.geometry.flatCoordinates ?? props?.points,
              props.numberOfArrows,
            ),
            true,
            true,
          ),
        ];
      }
      if (props.fresh && geometryIsLine(props.geometry.flatCoordinates)) {
        return [
          selectedDefaultFenceStyle,
          ...stylesForLineFence(
            getCoordsForNewTripwire(props.geometry.flatCoordinates, props.numberOfArrows),
            true,
            true,
          ),
        ];
      }
      return selectedDefaultFenceStyle;
    } else if (!props['isMeasurementTool'] && geometryIsLine(props.geometry.flatCoordinates)) {
      return [
        defaultFenceStyle,
        ...stylesForLineFence(
          getCoordsForNewTripwire(props.geometry.flatCoordinates, props.numberOfArrows),
          false,
          true,
        ),
      ];
    } else if (props['isMeasurementTool']) {
      return new Style({
        stroke: new Stroke({ color: [1, 1, 1, 0] }),
      });
    }
    return defaultFenceStyle;
  };

const defaultLayerFeatureStyle: StyleFunction = (f0: Feature<Geometry> | RenderFeature, p1) => {
  if (f0.getGeometry() instanceof Point) {
    return f0.get('microfenceType') === 'beacon'
      ? defaultMicrofenceBeaconStyle
      : f0.get('microfenceType') === 'device'
      ? defaultMicrofenceDeviceStyle
      : defaultMicrofenceGatewayStyle;
  }
  const props = f0.getProperties();
  if (props['zone'] === FenceZone.breach) {
    return breachFenceStyle;
  } else if (props['zone'] === FenceZone.buffer) {
    return bufferFenceStyle;
  } else if (props['zone'] === FenceZone.cleared) {
    return clearedFenceStyle;
  } else if (
    props['layerName'] === TRACKING_BOUNDS &&
    !(
      props.type?.toLowerCase().includes('line') ||
      props.points?.type?.toLowerCase().includes('line')
    )
  ) {
    return trackingFenceStyle;
  }
  if (geometryIsLine(props.geometry.flatCoordinates)) {
    return [
      defaultFenceStyle,
      ...stylesForLineFence(
        getCoordsForNewTripwire(props.geometry.flatCoordinates, props.numberOfArrows),
      ),
    ];
  }
  return defaultFenceStyle;
};

const selectedFenceFeatureStyle: StyleFunction = (f0: Feature<Geometry> | RenderFeature, _p1) => {
  if (f0.getGeometry() instanceof Point) {
    return f0.get('microfenceType') === 'beacon'
      ? selectedMicrofenceBeaconStyle
      : f0.get('microfenceType') === 'device'
      ? selectedMicrofenceDeviceStyle
      : selectedMicrofenceGatewayStyle;
  }
  const props = f0.getProperties();
  if (props['zone'] === FenceZone.breach) {
    return selectedBreachFenceStyle;
  } else if (props['zone'] === FenceZone.buffer) {
    return selectedBufferFenceStyle;
  } else if (props['zone'] === FenceZone.cleared) {
    return selectedClearedFenceStyle;
  } else if (
    props['layerName'] === TRACKING_BOUNDS &&
    !(
      props.type?.toLowerCase().includes('line') ||
      props.points?.type?.toLowerCase().includes('line')
    )
  ) {
    return trackingFenceStyle;
  }
  if (geometryIsLine(props.geometry.flatCoordinates)) {
    if (props.numberOfArrows === 0) {
      return f0.get('selected') ? selectedDefaultFenceStyle : defaultFenceStyle;
    }
    return [
      f0.get('selected') ? selectedDefaultFenceStyle : defaultFenceStyle,
      ...stylesForLineFence(
        getCoordsForNewTripwire(props.geometry.flatCoordinates, props.numberOfArrows),
        true,
      ),
    ];
  }
  return f0.get('selected') ? selectedDefaultFenceStyle : defaultFenceStyle;
};

const selectedLayerStyle = (
  cache: Map<string, Style | Style[]>,
  focusedFenceId?: string | null,
): StyleFunction =>
  styleFunctionForClusteredFeatures(
    cache,
    `selectedLayer_${focusedFenceId || ''}`,
    selectedLayerFeatureStyle(),
    selectedLayerClusterPointStyle,
  );

const selectedFenceStyle = (cache: Map<string, Style | Style[]>): StyleFunction =>
  styleFunctionForClusteredFeatures(
    cache,
    'selectedFence',
    selectedFenceFeatureStyle,
    selectedFenceClusterPointStyle,
  );

const EDIT_MAP_ID = 'OUTDOOR_EDIT_MAP';

interface EditState {
  draw: Draw;
  snap: Snap;
  modify: Modify;
  modifyScaleRot: Modify;
  translate: Translate;
}

export const dropPin = (coords: number[], address?: string): Feature<Point> => {
  const feature = new Feature(new Point(coords));
  const displayedCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');

  feature.set(
    'searchedCoordinates',
    parseFloat(displayedCoords[1].toFixed(5)) + ', ' + parseFloat(displayedCoords[0].toFixed(5)),
  );
  feature.set('searchedAddress', address);
  return feature;
};

export const GeofenceEditor: React.FC = () => {
  const MAX_NUMBER_OF_POLYGONS = 10000;
  const M_TO_FT = 3.28084;
  const M_TO_KM = 0.001;
  const M_TO_MI = 0.000621371;

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const mapApiKeys = useAtomValue(MAP_API_KEYS);

  const currentlyDisplayedTripwiresRef = useRef<Feature<Geometry>[]>([]);
  const editRef = useRef<Option<EditState>>(none);
  const extentInDegreesRef = useRef<number>(initialExtentInDegrees);
  const knownExtents = useRef<Extent[]>([]);
  const layerRef = useRef<Option<{ name: string; source: VectorSource<Geometry> }>>(none);
  const mapRef = useRef<Option<olMap>>(none);
  const measureTooltipRef = useRef<Overlay>();
  const measureTooltipElementRef = useRef<HTMLElement>();
  const microfenceDrawTypeRef = useRef<MicrofenceTypes | undefined>(undefined);
  const freshGeofencesRef = useRef<GridRowData[]>([]);
  const selectedFenceRef = useRef<Feature<Geometry>>();
  const setSourceRef = useRef<Option<(type: MapSourceType) => void>>(none);
  const showGhostGeofencesRef = useRef<boolean>(false);

  const [availableGeofences, setAvailableGeofences] = useState<GridRowData[]>([]);
  const [availableMicrofences, setAvailableMicrofences] = useState<GridRowData[]>([]);
  const [bounds, setBounds] = useState<Bounds>({
    latitude: initialLatitude,
    longitude: initialLongitude,
    extentInDegrees: initialExtentInDegrees,
  });
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [createEditFence, setCreateEditFence] = useState<'CREATE' | 'EDIT' | undefined>();
  const [createEditLayer, setCreateEditLayer] = useState<'CREATE' | 'EDIT' | undefined>();
  const [currentCenter, setCurrentCenter] = useState<number[] | undefined>();
  const [deletedFenceIds, setDeletedFenceIds] = useState<
    { type: 'line' | 'polygon' | 'multipolygon'; id: string }[]
  >([]);
  const [deselectFence, setDeselectFence] = useState<boolean>(false);
  const [dirtySave, setDirtySave] = useState<{
    isDirty: boolean;
    issue: string | null;
  }>({
    isDirty: false,
    issue: null,
  });
  const [drawType, setDrawType] = useState<Option<DrawType>>(none); // shape of draw
  const [dropLocationPin, setDropLocationPin] = useState<Feature<Point> | undefined>();
  const [editing, setEditing] = useState<boolean>(false);
  const [editType, setEditType] = useState<Option<EditType>>(none);
  const [errorCode, serErrorCode] = useState<number | undefined>();
  const [fencesLoading, setFencesLoading] = useState<boolean>(true);
  const [fenceReassignmentConflict, setFenceReassignmentConflict] = useState<{
    fenceName: string | null;
    layerName: string | null;
  }>({ fenceName: null, layerName: null });
  const [geofenceFilter, setGeofenceFilter] = useState<GeofenceFilter>({ perPage: 50 });
  const [geomobyProperties, setGeomobyProperties] = useState<Record<string, string> | undefined>();
  const [hasFences, setHasFences] = useState<boolean>(false);
  const [paginating, setPaginating] = useState<boolean>(false);
  const [layerFilter, setLayerFilter] = useState<LayerFilter | undefined>();
  const [layersHaveChanged, setLayersHaveChanged] = useState<boolean>(false); // Given to UI - once this is set, the user has to either save or discard changes
  const [layers, setLayers] =
    useState<Option<Map<string, { source: VectorSource<Geometry>; name: string }>>>(none);
  const [layerIds, setLayerIds] = useState<NameId[]>([]);
  const [locationDisplay, setLocationDisplay] = useState<LocationDisplayType>();
  const [locationSearchData, setLocationSearchData] = useState<LocationSearchData | undefined>();
  const [mapIsLoading, setMapIsLoading] = useState<boolean>(true);
  const [mapSourceType, setMapSourceType] = useState<MapSourceType>('Terrain & Roads');
  const [measurementType, setMeasurementType] = useState<MeasurementType | undefined>();
  const [microfenceFilter, setMicrofenceFilter] = useState<MicrofenceFilter>();
  const [freshGeofences, setFreshGeofences] = useState<GridRowData[]>([]);
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const [openGenericDialog, setOpenGenericDialog] = useState<boolean>(false);
  const [paginatedCount, setPaginatedCount] = useState<number>(0);
  const [reassignedFences, setReassignedFences] = useState<ReassignedFence[]>([]);
  const [renamingLayer, setRenamingLayer] = useState<string | null>(null);
  const [refreshSearch, setRefreshSearch] = useState<boolean>(false);
  const [selectedFromMap, setSelectedFromMap] = useState<boolean>(false);
  const [selectedGeofence, setSelectedGeofence] = useState<GridRowData | undefined>();
  const [selectedMicrofence, setSelectedMicrofence] = useState<GridRowData | undefined>();
  const [selectedLayer, setSelectedLayer] = useState<Option<string>>(none); // Set by UI
  const [searchType, setSearchType] = useState<SearchType | undefined>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showGhostGeofences, setShowGhostGeofences] = useState<boolean>(false);
  const [specifiedCoordinates, setSpecifiedCoordinates] = useState<[number, number]>();
  const [totalFences, setTotalFences] = useState<number>(0);
  const [userExtent, setUserExtent] = useState<option.Option<Extent>>(option.none);
  const [zoneChange, setZoneChange] = useState<Date | null>(null);

  if (!specifiedCoordinates) {
    navigator.geolocation?.getCurrentPosition(
      loc => {
        setSpecifiedCoordinates([loc?.coords.longitude, loc?.coords.latitude]);
      },
      () => {
        setSpecifiedCoordinates([initialLongitude, initialLatitude]);
      },
    );
  }

  const isLine = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase()?.includes('line') ||
      selectedGeofence?.points?.type?.toLowerCase()?.includes('line'),
    [selectedGeofence],
  );
  const isPolygon = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase() === 'polygon' ||
      selectedGeofence?.points?.type?.toLowerCase() === 'polygon',
    [selectedGeofence],
  );
  const isMultipolygon = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase()?.includes('multipolygon') ||
      selectedGeofence?.points?.type?.toLowerCase()?.includes('multipolygon'),
    [selectedGeofence],
  );

  const debouncedOnMapMoved = useRef(
    debounce(
      (olmap: olMap) => {
        const view = olmap?.getView();
        if (!view) return;
        const viewCenter = view.getCenter();
        if (!viewCenter) return;
        const center = transform(viewCenter, view.getProjection(), 'EPSG:4326');
        setBounds({
          latitude: center[1],
          longitude: center[0],
          extentInDegrees: extentInDegreesRef.current ?? initialExtentInDegrees,
        });
      },
      1000,
      { leading: true },
    ),
  ).current;

  const animateToSearchedLocation = useCallback(
    async (
      view: View,
      coords: number[],
      address?: string,
      isStreetAddress?: boolean,
    ): Promise<Feature<Point>> => {
      return new Promise(
        debounce((resolve, reject) => {
          const duration = Math.min(
            6000,
            Math.max(
              300,
              getCoordinateDifference(view.getCenter() ?? [0, 0], coords ?? [0, 0]) / 1000,
            ),
          );

          view.animate(
            {
              center: coords,
              zoom: duration > 300 ? 9 - duration / 1000 : view.getZoom(),
              duration: duration,
            },
            () => {
              view.animate(
                {
                  resolution: isStreetAddress ? 18 : 14,
                  duration: 2000,
                },
                () => {
                  resolve(dropPin(coords, address));
                },
              );
            },
          );
        }, 2000),
      );
    },
    [],
  );

  const getGeofence = useCallback(async () => {
    return (
      await axios.get<{
        id: string;
        name: string;
        points: { coordinates: Extent };
        geomobyProperties: Record<string, string>;
      }>(
        `${triggersUrl}/${cid}/${pid}/geofences/${
          toNullable(selectedLayer) ?? selectedGeofence?.layerId
        }/${isPolygon ? 'polygon' : isMultipolygon ? 'multipolygon' : 'line'}/${
          selectedGeofence?.id
        }`,
        authedRequestConfig,
      )
    ).data;
  }, [
    triggersUrl,
    cid,
    pid,
    authedRequestConfig,
    selectedLayer,
    selectedGeofence,
    isPolygon,
    isMultipolygon,
  ]);

  const animateToLocationGlobally = useCallback(
    async ([lon1, lat1, lon2, lat2]: [number, number, number, number]) => {
      if (isNone(mapRef.current)) return;
      const duration = Math.min(
        6000,
        Math.max(
          300,
          getCoordinateDifference(
            toNullable(mapRef.current)?.getView().getCenter() ?? [0, 0],
            getCenter([lon1, lat1, lon2, lat2]) ?? [0, 0],
          ) / 1000,
        ),
      );
      mapRef.current.value.getView().animate(
        {
          center: [lon1, lat1, lon2, lat2],
          zoom: duration > 300 ? 9 - duration / 1000 : mapRef.current.value.getView().getZoom(),
          duration: duration,
        },
        () => {
          if (selectedFenceRef.current?.get('layerId') === MICROFENCE_LAYER_ID) {
            const extent = selectedFenceRef.current.getGeometry()?.getExtent() ?? [];
            if (extent.length === 0) return;
            toNullable(mapRef.current)
              ?.getView()
              .animate({
                center: getCenter(extent),
                duration: 250,
                zoom: CLUSTER_MAX_ZOOM,
              });
            return;
          }
          if (isNone(mapRef.current)) return;
          fitToExtent(mapRef.current.value.getView(), [lon1, lat1, lon2, lat2]);
        },
      );
    },
    [],
  );

  const animateToFeature = useCallback(async () => {
    if (selectedMicrofence || selectedGeofence?.id.includes(FRESH)) {
      animateToLocationGlobally(
        (selectedFenceRef.current?.getGeometry()?.getExtent() ||
          selectedMicrofence?.geometry?.getExtent()) as [number, number, number, number],
      );
      return;
    }

    if (deletedFenceIds.find(f => f.id === selectedGeofence?.id) || !selectedGeofence) return;
    const geofence = await getGeofence();
    if (!geofence) return;
    const newFence = isPolygon
      ? new Polygon(geofence.points.coordinates)
      : isMultipolygon
      ? new MultiPolygon(geofence.points.coordinates)
      : new LineString(geofence.points.coordinates);
    const extent = transformExtent(newFence.getExtent(), 'EPSG:4326', 'EPSG:3857');
    if (!extent || isNone(mapRef.current)) return;
    animateToLocationGlobally(extent as [number, number, number, number]);
  }, [
    selectedGeofence,
    selectedMicrofence,
    deletedFenceIds,
    getGeofence,
    isMultipolygon,
    isPolygon,
    animateToLocationGlobally,
  ]);

  const getLayerFromMap = (name: string) => {
    const layer = toNullable(mapRef.current)
      ?.getAllLayers()
      .find(layer =>
        layer.getProperties().id === MICROFENCE_LAYER_ID
          ? layer instanceof AnimatedCluster && layer.get('name') === name
          : layer instanceof VectorLayer &&
            (layer.getSource().get('name') ?? layer.get('name')) === name,
      );
    if (!layer) {
      console.log('map layer not found for name', name);
      return;
    }

    if (layer.getProperties().id === MICROFENCE_LAYER_ID) return layer as AnimatedCluster;
    return layer as VectorLayer<VectorSource<Geometry>>;
  };

  const setMapClickDeselection = (m: olMap) => {
    m.on('click', e => {
      uiDeselectAllFences();
      if (freshGeofencesRef.current.find(f => f.layerId === FRESH_UNKNOWN_LAYER)) {
        setOpenGenericDialog(true);
        return;
      }
      if (m.getFeaturesAtPixel(e.pixel).length === 0 && isNone(editRef.current)) {
        setSelectedLayer(none);
        setSelectedGeofence(undefined);
        setSelectedMicrofence(undefined);
        selectedFenceRef.current = undefined;
        setDeselectFence(true);
        setRefreshSearch(true);
      } else {
        if (isSome(editRef.current)) return;
        const fences: GeoJSONFeature[] = m.getFeaturesAtPixel(e.pixel) ?? [];
        if (!fences || !fences.length) {
          setSelectedLayer(none);
          setSelectedGeofence(undefined);
          setSelectedMicrofence(undefined);
          selectedFenceRef.current = undefined;
          setRefreshSearch(true);
          return;
        }

        let smallestExtentArea = Number.MAX_SAFE_INTEGER;
        let selectedFence = fences[0];
        fences.forEach(f => {
          const extent = f.getGeometry()?.getExtent();
          const extentArea = extent
            ? Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2])
            : Number.MAX_SAFE_INTEGER;
          if (smallestExtentArea > extentArea) {
            smallestExtentArea = extentArea;
            selectedFence = f;
          }
        });
        const layerId =
          selectedFence.get('layerId') ??
          (selectedFence.getGeometry() instanceof Point ? MICROFENCE_LAYER_ID : undefined);
        if (!layerId) return;

        if (layerId === MICROFENCE_LAYER_ID) {
          setSelectedLayer(fromNullable(MICROFENCE_LAYER_ID));
          if (selectedFence.get('features').length > 0) {
            selectedFence.get('features')[0].set('selected', true);
            selectedFenceRef.current = selectedFence.get('features')[0] as Feature<Geometry>;
            setSelectedMicrofence(selectedFence.get('features')[0]?.getProperties() as GridRowData);
            setSearchType({ id: 'MICROFENCES', value: 'Microfences' });
          }
        } else {
          if (showGhostGeofencesRef.current) return;
          setShowGhostGeofences(false);
          selectedFence.set('selected', true);
          selectedFenceRef.current = selectedFence as Feature<Geometry>;
          setSelectedGeofence(selectedFence?.getProperties() as GridRowData);
          setSelectedLayer(fromNullable(layerId));
          setSearchType({ id: 'GEOFENCES', value: 'Geofences' });
          if (
            selectedFence.get('type')?.toLowerCase() === 'polygon' ||
            selectedFence.get('points')?.type?.toLowerCase() === 'polygon'
          ) {
            const geom = selectedFence?.getGeometry();
            if (geom) {
              setHasFences(!!featureContainsFeature(geom));
            }
          }
        }

        setSelectedFromMap(true);
        layerIds.forEach(layer =>
          changeVisibility(layer.id, layer.id === layerId || showGhostGeofencesRef.current),
        );
      }
    });
  };

  const unsetMapClickSelection = (m: olMap) => {
    m.getInteractions().forEach(i => i instanceof Select && m.removeInteraction(i));
  };

  const setMapMoveHandler = (m: olMap) => {
    m.on('moveend', () => {
      setCurrentCenter(m.getView().getCenter());
      debouncedOnMapMoved(m);
    });
  };

  const layerChanged = () => {
    if (
      !currentlyDisplayedTripwiresRef.current ||
      currentlyDisplayedTripwiresRef.current.length === 0
    ) {
      currentlyDisplayedTripwiresRef.current =
        toNullable(layerRef.current)
          ?.source.getFeatures()
          .filter(f => f.get('points')?.type === 'LineString' || f.get('type') === 'LineString') ??
        [];
    }
    if (
      !(selectedGeofence ?? selectedMicrofence) &&
      toNullable(mapRef.current)
        ?.getAllLayers()
        .find(lyr => lyr.get('id') === FRESH_UNKNOWN_LAYER)
    )
      return;
    setLayersHaveChanged(true);
  };

  const featureModified = (e: ModifyEvent, source: VectorSource<Geometry>) => {
    const modifiedFids = e.features.getArray();
    source.forEachFeature(feature => {
      const modifiedFeature = modifiedFids.find(f => f.get('id').includes(feature.get('id')));
      if (modifiedFeature) {
        feature.setProperties({ updated: true });

        if (modifiedFeature && modifiedFeature.get('layerId') !== MICROFENCE_LAYER_ID) {
          feature.setGeometry((modifiedFeature as Feature<Geometry>).getGeometry());
          const geometry = modifiedFeature?.getGeometry();

          if (geometry) {
            const type =
              feature.get('points')?.type === 'LineString' ||
              feature.get('type') === 'LineString' ||
              feature.get('type') === 'line'
                ? 'line'
                : feature.get('points')?.type?.toLowerCase() ?? feature.get('type').toLowerCase();

            if (type === 'polygon') {
              const coordinates = (geometry as Polygon).getCoordinates()[0];
              const newCoords = coordinates.map(coord =>
                transform(coord, 'EPSG:3857', 'EPSG:4326'),
              );
              const points = (modifiedFeature as Feature<Geometry>).get('points');
              if (newCoords && feature.get('points')) {
                feature.get('points').coordinates = [newCoords];
              }
            } else if (type === 'multipolygon') {
              const coordinates = (geometry as MultiPolygon).getCoordinates()[0][0];
              const newCoords = coordinates.map(coord =>
                transform(coord, 'EPSG:3857', 'EPSG:4326'),
              );
              const points = (modifiedFeature as Feature<Geometry>).get('points');
              if (newCoords && feature.get('points')) {
                feature.get('points').coordinates = [[newCoords]];
              }
            } else {
              const coordinates = (geometry as LineString).getCoordinates();
              const newCoords = coordinates.map(coord =>
                transform(coord, 'EPSG:3857', 'EPSG:4326'),
              );
              const points = (modifiedFeature as Feature<Geometry>).get('points');
              if (newCoords && feature.get('points')) {
                feature.get('points').coordinates = newCoords;
              }
            }
          }
        }
      }
    });
    layerChanged();
  };

  const onMicrofenceDrawEnd = ({ feature }: { feature: Feature<Geometry> }) => {
    feature.set(
      'geomobyProperties',
      Object.fromEntries(Object.entries(MICROFENCE_DEFAULT_PROPS).map(([k, v]) => [k, String(v)])),
    );
    setLayersHaveChanged(true);
  };

  const uiUpdateFenceIdentifiers = async (
    name: string,
    fenceZone: FenceZone | undefined,
    assetId: MicrofenceAssetId | undefined,
    microfenceZone: MicrofenceZone | undefined,
  ): Promise<void> => {
    if (!selectedGeofence && !selectedMicrofence)
      throw new Error(
        'UI should not be able to update a feature name or ID while no feature is selected',
      );
    if (isNone(layerRef.current))
      throw new Error(
        'UI should not be able to update a feature name or ID while no layer is selected',
      );

    if (selectedGeofence) {
      await findFeature(selectedGeofence.id);
    }

    if (isNone(layerRef.current))
      throw new Error(
        'UI should not be able to update a feature name or ID while no layer is selected',
      );

    updateFenceOnMap(
      layerRef.current.value.source,
      layerChanged,
      selectedGeofence?.id ?? selectedMicrofence?.id,
      name,
      fenceZone,
      assetId,
      microfenceZone,
    );

    const updatedNameFeature = freshGeofencesRef.current.find(f => f.id === selectedGeofence?.id);
    if (updatedNameFeature) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== selectedGeofence?.id),
        { ...updatedNameFeature, name },
      ];
    }

    if (selectedGeofence) {
      const bufferZoneFence = layerRef.current.value.source
        .getFeatures()
        .find(f => f.get('parentId') === selectedGeofence.id && f.get('zone') === FenceZone.buffer);
      const relatedFenceName = bufferZoneFence ? `${name}_warning` : undefined;
      if (relatedFenceName) {
        updateFenceOnMap(
          layerRef.current.value.source,
          layerChanged,
          bufferZoneFence?.get('id'),
          relatedFenceName,
          fenceZone === FenceZone.breach ? FenceZone.buffer : undefined,
        );
        const bufferIsFresh = freshGeofencesRef.current.find(
          f => f.id === bufferZoneFence?.get('id'),
        );
        if (bufferIsFresh) {
          freshGeofencesRef.current = [
            ...freshGeofencesRef.current.filter(f => f.id !== bufferZoneFence?.get('id')),
            { ...bufferIsFresh, name: relatedFenceName },
          ];
        }
      }
      setAvailableGeofences(fenceIds =>
        availableGeofences.map(fence => {
          if (fence.id === selectedGeofence.id) {
            return { ...fence, name };
          } else if (relatedFenceName && fence.id === bufferZoneFence?.get('id')) {
            return { ...fence, name: relatedFenceName };
          }
          return fence;
        }),
      );
    } else if (selectedMicrofence) {
      if (isNone(mapRef.current)) return;
      setAvailableMicrofences(fenceIds =>
        availableMicrofences.map(fence => {
          if (fence.id === selectedMicrofence.id && assetId) {
            return { ...fence, name, assetId };
          }
          return fence;
        }),
      );
    }
    setFreshGeofences(freshGeofencesRef.current);
    setLayersHaveChanged(true);
  };

  const uiDeselectAllFences = () => {
    toNullable(mapRef.current)
      ?.getAllLayers()
      .filter(l => l instanceof VectorLayer)
      .forEach(l => {
        l.getSource()
          .getFeatures()
          .filter((f: Feature<Geometry>) => f.get('selected'))
          .forEach((f: Feature<Geometry>) => {
            f.set('selected', false);
          });
      });
    toNullable(mapRef.current)
      ?.getAllLayers()
      .filter(l => l instanceof AnimatedCluster)
      .forEach(l => {
        l.getSource()
          .getFeatures()
          .flatMap((f: Feature<Geometry>) => f.get('features') as Feature<Geometry>[])
          .filter((f: Feature<Geometry>) => f.get('selected'))
          .forEach((f: Feature<Geometry>) => {
            f.set('selected', false);
          });
      });
  };

  const changeVisibility = (layerId: string, visible: boolean, opacity?: number) => {
    if (isNone(layers) || isNone(mapRef.current)) return;

    const layer = fromNullable(layers.value.get(layerId));
    if (isNone(layer)) return;

    const foundLayer = getLayerFromMap(layer.value.name);
    if (foundLayer) {
      foundLayer.setVisible(visible);
      if (visible && toNullable(selectedLayer) === MICROFENCE_LAYER_ID) {
        foundLayer.setOpacity(layerId === MICROFENCE_LAYER_ID ? 1 : 0.6);
      } else if (visible) {
        foundLayer.setOpacity(1);
      }
      if (opacity) foundLayer.setOpacity(opacity);
    }
  };

  const checkIfVisible = (layerId: Option<string>) => {
    if (isNone(layers) || isNone(layerId)) throw new Error('No layers detected');
    const layer = fromNullable(layers.value.get(layerId.value));
    if (isNone(layer)) throw new Error('No layer detected');
    const visibility = getLayerFromMap(layer.value.name)?.getVisible();
    return visibility ?? false;
  };

  const uiResetLayerChanges = async (): Promise<void> => {
    if (isNone(selectedLayer))
      throw new Error('UI should not be trying to reset a layer when no layer is selected');
    if (isNone(layerRef.current))
      throw new Error('UI should not be trying to reset a layer when no layer is selected');
    if (isNone(mapRef.current)) return;
    if (isNone(layers)) return;

    changeVisibility(selectedLayer.value, false);
    setLayersHaveChanged(false);
    setSelectedGeofence(undefined);
    setSelectedMicrofence(undefined);
    setAvailableGeofences([]);
    setReassignedFences([]);
    selectedFenceRef.current = undefined;
    freshGeofencesRef.current = [];
    setFreshGeofences([]);
    const mapLayer = getLayerFromMap(layerRef.current.value.name);
    if (mapLayer) {
      mapRef.current.value.removeLayer(mapLayer);
    }

    const scaleRotLayer = mapRef.current.value
      .getAllLayers()
      .find(l => l.getClassName() === 'Scalable-rotatable-layer');
    if (scaleRotLayer) {
      mapRef.current.value.removeLayer(scaleRotLayer);
    }

    setMapIsLoading(true);
    const vl =
      selectedLayer.value === MICROFENCE_LAYER_ID
        ? some({
            layer: await getMicrofencesVectorLayer(
              triggersUrl,
              authedRequestConfig,
              { cid, pid },
              bounds,
            ),
            name: MICROFENCE_LAYER_LABEL,
          })
        : await getVectorLayer(
            triggersUrl,
            authedRequestConfig,
            { cid, pid },
            selectedLayer.value,
            bounds,
          );
    setMapIsLoading(false);

    // If no layer with these credentials is returned from db, then remove it from front-end.
    if (isNone(vl)) {
      if (layerIds.length === 0) return;
      if (isNone(layerRef.current)) return;
      const removedLid: string = layerIds.filter(i => i.id === selectedLayer.value)[0].id;
      layers.value.delete(removedLid);
      const remainingLayers: Map<string, { source: VectorSource<Geometry>; name: string }> =
        layers.value;
      setLayers(some(remainingLayers));

      if (removedLid) {
        setLayerIds(
          Array.from(remainingLayers.entries()).map(([key, value]) => {
            return { id: key, name: value.name } as NameId;
          }),
        );

        const olmap = mapRef.current.value;
        olmap.getAllLayers().forEach((l, index) => {
          if (l.get('id') === removedLid) {
            olmap.removeLayer(l);
          }
        });
      }
      setSelectedLayer(none);
      setMapIsLoading(false);
      return;
    }
    vl.value.layer.setStyle(
      selectedLayerStyle(styleCache, selectedGeofence?.id ?? selectedMicrofence?.id),
    );
    const newLayer = vl.value;
    mapRef.current.value.addLayer(newLayer.layer);
    const newLayerSourceAndName = {
      source:
        newLayer.layer.getProperties().id === MICROFENCE_LAYER_ID
          ? (newLayer.layer.getSource() as Cluster).getSource()
          : newLayer.layer.getSource(),
      name: newLayer.name,
    };
    const lyrs = layers.value;
    lyrs.set(selectedLayer.value, newLayerSourceAndName);
    setLayers(some(lyrs));
    layerRef.current = some(newLayerSourceAndName);
    selectedFenceRef.current = undefined;
    setDeletedFenceIds([]);

    // Force modified/undeleted features to be redrawn
    mapRef.current.value.getView().adjustZoom(-1);
    mapRef.current.value.getView().adjustZoom(+1);
  };

  const processNextReassignedFence = (reassignedFence: ReassignedFence, layerId: string) => () =>
    axios
      .patch(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${reassignedFence.type}/change-layer/${reassignedFence.id}/${reassignedFence.newLayerId}`,
        {},
        authedRequestConfig,
      )
      .then(() => true);

  const saveMicrofenceLayerChanges = async () => {
    if (
      isNone(layerRef.current) ||
      isNone(selectedLayer) ||
      selectedLayer.value !== MICROFENCE_LAYER_ID
    )
      return;

    try {
      await Promise.all(
        deletedFenceIds
          .filter(f => !f.id.includes(FRESH))
          .map(({ id }) =>
            axios.delete(`${triggersUrl}/${cid}/${pid}/microfences/${id}`, authedRequestConfig),
          ),
      );

      setDeletedFenceIds([]);

      const geojson = jsonFromLayer(layerRef.current.value.source);

      const newIdsbyOldId: { [oldId: string]: string | undefined } = Object.fromEntries(
        await Promise.all(
          geojson.features
            .filter(
              (feature: GeoJSONFeature) => feature.properties?.updated || feature.properties?.fresh,
            )
            .map(async (feature: GeoJSONFeature) => {
              const typeIsPoint = feature.geometry.type === 'Point';
              if (!typeIsPoint) {
                console.error(
                  'Should not be trying to save non-point feature as a microfence:',
                  feature,
                );
              }
              const boundaryRssi = Number(
                feature.properties.geomobyProperties[MICROFENCE_PROP_LABELS.boundaryRssi],
              );
              const timeoutSeconds = Number(
                feature.properties.geomobyProperties[MICROFENCE_PROP_LABELS.timeoutSeconds],
              );
              const name = feature.properties.name;
              const type = feature.properties.microfenceType?.replace('smartplug', 'gateway'); // fix legacy type
              const assetId = feature.properties.assetId;
              const zone = feature.properties.zone;

              const body = {
                assetId,
                name,
                type,
                zone: zone === 'none' || zone === undefined ? null : zone,
                boundaryRssi,
                timeoutSeconds,
                geometry: feature.geometry,
              };
              if (feature.properties.id.includes(FRESH)) {
                const id: string = (
                  await axios.post(
                    `${triggersUrl}/${cid}/${pid}/microfences/`,
                    body,
                    authedRequestConfig,
                  )
                ).data.id;
                return [feature.properties.id, id];
              } else {
                await axios.patch(
                  `${triggersUrl}/${cid}/${pid}/microfences/${feature.properties.id}`,
                  body,
                  authedRequestConfig,
                );
              }
              return [];
            }),
        ),
      );

      const allLayers = toNullable(layers);
      const layer = allLayers?.get(selectedLayer.value);
      layer?.source.getFeatures().forEach(f => {
        f.setProperties({ updated: undefined, fresh: undefined });
        const newId = newIdsbyOldId[f.get('id')];
        if (newId) {
          f.set('id', newId);
        }
      });
      setLayersHaveChanged(false);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
      const newMicrofences = layer?.source.getFeatures();
      if (!newMicrofences) return;
      setAvailableMicrofences(newMicrofences.map(fence => fence.getProperties()));
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
    uiDeselectAllFences();
  };

  const saveGeofenceLayerChanges = async () => {
    if (isNone(layerRef.current) || isNone(selectedLayer)) return;
    let layerId = selectedLayer.value;
    const layerData = layerRef.current.value;
    const geojson = jsonFromLayer(layerData.source);
    freshGeofencesRef.current = [];
    setFreshGeofences([]);

    const features: {
      properties: {
        id: string;
        name: string;
        type: string;
        zone: FenceZone;
        parentId: string;
        geomobyProperties: Record<string, string>;
      };
    }[] = geojson.features
      .filter(
        (feature: GeoJSONFeature) => feature?.properties?.updated || feature?.properties?.fresh,
      )
      .map((feature: GeoJSONFeature) => {
        const type = feature.geometry.type?.toLowerCase().includes('line')
          ? 'line'
          : feature.geometry.type?.toLowerCase();
        return {
          ...feature,
          properties: {
            id: feature?.properties?.id,
            name: feature?.properties?.name,
            type,
            geomobyProperties: feature?.properties?.geomobyProperties,
            zone: type === 'polygon' ? feature?.properties?.zone ?? FenceZone.none : undefined,
            parentId: feature?.properties?.parentId,
          },
        };
      });

    const existingFences = features.filter(f => !f.properties.id.includes(FRESH));
    const newPolygons = features.filter(
      f => f.properties.type === 'polygon' && f.properties.id?.includes(FRESH),
    );

    const newMultipolygons = features.filter(
      f => f.properties.type === 'multipolygon' && f.properties.id?.includes(FRESH),
    );

    const newLines = features.filter(
      f => f.properties.type.includes('line') && f.properties.id?.includes(FRESH),
    );

    const newlyCreatedPolygons: { id: string; name: string; parentId: string | undefined }[] = [];
    let newlyCreatedMultiPolygons: { id: string; name: string; parentId: string | undefined }[] =
      [];
    let newlyCreatedLines: { id: string; name: string; parentId: string | undefined }[] = [];

    try {
      // Create layer
      if (layerId.includes(FRESH)) {
        layerId = (
          await axios.post(
            `${triggersUrl}/${cid}/${pid}/geofences`,
            {
              name: layerData.name,
            },
            authedRequestConfig,
          )
        ).data.id;
        const olmap = toNullable(mapRef.current);
        const freshLayer = olmap
          ?.getAllLayers()
          .find((l, i) => l instanceof VectorLayer && l.get('id')?.includes(FRESH));
        if (freshLayer) {
          freshLayer.set('id', layerId);
          freshLayer
            .getSource()
            .getFeatures()
            .forEach((f: Feature<Geometry>) => {
              f.set('layerId', layerId);
            });
          freshLayer.getSource().setProperties({
            id: layerId,
            name: freshLayer.get('name'),
          });
        }
      } else if (!layerId.includes(FRESH) && renamingLayer) {
        // Update layer
        layerId = (
          await axios.patch(
            `${triggersUrl}/${cid}/${pid}/geofences/${layerId}`,
            {
              name: renamingLayer,
            },
            authedRequestConfig,
          )
        ).data.id;
      }

      // Delete fences
      if (deletedFenceIds.length > 0) {
        await Promise.all(
          deletedFenceIds
            .filter(f => !f.id.includes(FRESH))
            .map(({ type, id }) =>
              axios.delete(
                `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
                authedRequestConfig,
              ),
            ),
        );
        setDeletedFenceIds([]);
      }

      // Update existing fences
      if (existingFences.length > 0) {
        await Promise.all(
          existingFences.map(async (feature: GeoJSONFeature) => {
            await axios.patch(
              `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${feature?.properties.type}/${feature?.properties?.id}`,
              feature,
              authedRequestConfig,
            );
          }),
        );
      }

      // Reassign to another layer
      const layer = layerRef.current.value;
      if (reassignedFences.length > 0) {
        setMapIsLoading(true);
        await reassignedFences
          .map(r => processNextReassignedFence(r, layerId))
          .reduce((prev, cur) => {
            return prev.then(() => cur());
          }, Promise.resolve(true));

        if (availableGeofences.length > 0) {
          reassignedFences.forEach(reassignedFence => {
            const foundFeature = layer.source
              .getFeatures()
              .find((f: Feature<Geometry>) => f.get('id') === reassignedFence.id);
            if (foundFeature && isSome(mapRef.current)) {
              const newlayer = mapRef.current.value
                .getAllLayers()
                .find(
                  l =>
                    l instanceof VectorLayer &&
                    l.getProperties().source.get('id') === reassignedFence.newLayerId,
                );

              if (newlayer) {
                const updatedFeature = foundFeature;
                updatedFeature.setProperties({
                  ...updatedFeature.getProperties(),
                  layerId: newlayer.getProperties().source.get('id'),
                  layerName: newlayer.getProperties().source.get('name'),
                });

                const foundBuffer = layer.source
                  .getFeatures()
                  .find((f: Feature<Geometry>) => f.get('parentId') === foundFeature.get('id'));
                if (foundBuffer) {
                  const updatedBuffer = foundBuffer;
                  updatedBuffer.setProperties({
                    ...updatedBuffer.getProperties(),
                    layerId: newlayer.getProperties().source.get('id'),
                    layerName: newlayer.getProperties().source.get('name'),
                  });
                  newlayer.getProperties().source.addFeature(updatedBuffer as Feature<Geometry>);
                  layer.source.removeFeature(foundBuffer as Feature<Geometry>);
                }
                newlayer.getProperties().source.addFeature(updatedFeature as Feature<Geometry>);
                layer.source.removeFeature(foundFeature as Feature<Geometry>);
              }
            }
          });

          setAvailableGeofences(
            availableGeofences.filter(f => !reassignedFences.find(r => r.id === f.id)),
          );
        }
        setReassignedFences([]);
        setMapIsLoading(true);
      }

      // Create polygons
      if (newPolygons.length > 0) {
        const createdPolygons = (
          await axios.post(
            `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/polygon/multiple`,
            { polygons: newPolygons },
            authedRequestConfig,
          )
        ).data;
        newlyCreatedPolygons.push(
          ...createdPolygons.map((p: SavedFeature) => {
            return {
              id: p.id,
              name: p.name,
              parentId: p.parentId,
            };
          }),
        );
      }

      // Create multipolygons
      newlyCreatedMultiPolygons =
        newMultipolygons.length > 0
          ? await Promise.all(
              newMultipolygons.map(async (multipolygon: GeoJSONFeature) => {
                return (
                  await axios.post(
                    `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/multipolygon`,
                    multipolygon,
                    authedRequestConfig,
                  )
                ).data;
              }),
            )
          : [];

      // Create lines
      newlyCreatedLines =
        newLines.length > 0
          ? await Promise.all(
              newLines.map(async (line: GeoJSONFeature) => {
                return (
                  await axios.post(
                    `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/line`,
                    line,
                    authedRequestConfig,
                  )
                ).data;
              }),
            )
          : [];

      setSelectedLayer(fromNullable(layerId));
      setLayerFilter({ layer: { name: layerData.name, id: layerId } });
      setRefreshSearch(true);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      const errorData = ((error as AxiosError).response as AxiosResponse).data;
      if (errorData.statusCode === 413) serErrorCode(errorData.statusCode);
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: errorData.message,
      });
    }

    const allLayers = toNullable(layers);
    const layer = allLayers?.get(selectedLayer.value);
    layer?.source.getFeatures().forEach(f => {
      f.setProperties({ updated: undefined, fresh: undefined });
      const newFence = [
        ...newlyCreatedPolygons,
        ...newlyCreatedMultiPolygons,
        ...newlyCreatedLines,
      ].find(n => n.name === f.get('name'));

      if (newFence) {
        f.set('id', newFence.id);
        if (newFence.parentId) {
          f.set('parentId', newFence.parentId);
        }
      }
    });
    if (layer && selectedLayer.value !== layerId) {
      allLayers?.delete(selectedLayer.value);
      allLayers?.set(layerId, layer);
      setLayers(fromNullable(allLayers));
      if (isSome(layers)) {
        const lyr = layers.value.get(layerId);
        lyr?.source.setProperties({
          id: layerId,
          name: lyr.name,
        });
      }
      setSelectedLayer(fromNullable(layerId));
    }

    selectedFenceRef.current = undefined;
    setSelectedGeofence(undefined);
    setLayersHaveChanged(false);
    uiDeselectAllFences();
  };

  const saveLayerChanges = async (): Promise<void> => {
    if (isNone(layerRef.current) || isNone(selectedLayer)) return;
    const layerId = selectedLayer.value;

    if (layerId === MICROFENCE_LAYER_ID) {
      // Validate Microfences
      let hasDuplicateAssetId: Feature<Geometry> | undefined;
      const features = toNullable(layerRef.current)?.source.getFeatures();
      if (features) {
        const hasUndefinedAssetId = features.find(
          f =>
            (f.get('microfenceType') === 'beacon' &&
              (!f.get('assetId') || !f.get('assetId').uuid)) ||
            (f.get('microfenceType') === 'gateway' &&
              (!f.get('assetId') || !f.get('assetId').gatewayId)) ||
            (f.get('microfenceType') === 'smartplugId' &&
              (!f.get('assetId') || !f.get('assetId').smartplugId)) ||
            (f.get('microfenceType') === 'device' &&
              (!f.get('assetId') || !f.get('assetId').deviceId)),
        );
        const hasUndefinedMajorOrMinor = features.find(
          f =>
            f.get('microfenceType') === 'beacon' &&
            (!f.get('assetId').major || !f.get('assetId').minor),
        );

        setDirtySave({
          isDirty: !!hasUndefinedAssetId,
          issue: hasUndefinedAssetId ? 'Microfence ID is required' : null,
        });

        if (!hasUndefinedAssetId) {
          features.forEach(feature => {
            const foundFeature = features.find(f => {
              const differentId = f.get('id') !== feature.get('id');
              if (!differentId) return false;

              const sameBeaconIds =
                !!f.get('assetId').uuid &&
                f.get('assetId').uuid === feature.get('assetId').uuid &&
                !!f.get('assetId').major &&
                f.get('assetId').major === feature.get('assetId').major &&
                !!f.get('assetId').minor &&
                f.get('assetId').minor === feature.get('assetId').minor;
              const sameGatewayId =
                !!f.get('assetId').gatewayId &&
                f.get('assetId').gatewayId === feature.get('assetId').gatewayId;
              const sameSmartplugId =
                !!f.get('assetId').smartplugId &&
                f.get('assetId').smartplugId === feature.get('assetId').smartplugId;
              const sameDeviceId =
                !!f.get('assetId').deviceId &&
                f.get('assetId').deviceId === feature.get('assetId').deviceId;

              return sameBeaconIds || sameGatewayId || sameSmartplugId || sameDeviceId;
            });
            if (foundFeature) {
              hasDuplicateAssetId = foundFeature;
            }
          });
          if (hasDuplicateAssetId !== undefined) {
            setDirtySave({
              isDirty: !!hasDuplicateAssetId,
              issue: hasDuplicateAssetId ? 'Microfence ID must be unique' : null,
            });
          }

          if (!hasDuplicateAssetId) {
            setDirtySave({
              isDirty: !!hasUndefinedMajorOrMinor,
              issue: hasUndefinedMajorOrMinor
                ? 'Microfence Major and Minor values are required'
                : null,
            });
          }
        }

        if (hasUndefinedAssetId || hasDuplicateAssetId || hasUndefinedMajorOrMinor) {
          selectedFenceRef.current = hasUndefinedAssetId ?? hasDuplicateAssetId;
          const dirtyFence = (
            hasUndefinedAssetId ??
            hasDuplicateAssetId ??
            hasUndefinedMajorOrMinor
          )?.getProperties() as GridRowData;
          setSelectedGeofence(dirtyFence);
          return;
        }
      }

      selectedFenceRef.current = undefined;
      setSelectedMicrofence(undefined);
      return saveMicrofenceLayerChanges();
    } else {
      // Validate Geofences
      const features = toNullable(layerRef.current)?.source.getFeatures();
      if (features) {
        const layerNotSelected = selectedGeofence?.layerId === FRESH_UNKNOWN_LAYER;
        setDirtySave({
          isDirty: !!layerNotSelected,
          issue: layerNotSelected
            ? 'A group has not yet been selected. Please assign one to this geofence.'
            : null,
        });
        if (layerNotSelected) return;

        const hasEmptyGeomobyPropertyName = features.find(
          f =>
            JSON.stringify(Object.entries(f.get('geomobyProperties'))).includes('["",') ||
            JSON.stringify(Object.entries(f.get('geomobyProperties'))).includes(',""'),
        );
        setDirtySave({
          isDirty: !!hasEmptyGeomobyPropertyName,
          issue: hasEmptyGeomobyPropertyName
            ? 'Geofence Property names must not be left empty'
            : null,
        });

        if (hasEmptyGeomobyPropertyName) {
          selectedFenceRef.current = hasEmptyGeomobyPropertyName;
          setSelectedGeofence(hasEmptyGeomobyPropertyName?.getProperties() as GridRowData);
          return;
        }
      }
      saveGeofenceLayerChanges();
    }
  };

  const uiUnsetEditing = () => {
    //when we editing and clicking cancel
    if (isNone(editRef.current))
      throw new Error('UI should not be able to unset editing while not editing');
    if (isNone(layerRef.current))
      throw new Error('UI should not be able to unset editing while no layer selected');
    if (isNone(mapRef.current)) return;

    //remove edit interactions
    const e = editRef.current.value;
    const m = mapRef.current.value;
    m.removeInteraction(e.draw);
    m.removeInteraction(e.modify);
    m.removeInteraction(e.modifyScaleRot);
    m.removeInteraction(e.snap);
    m.removeInteraction(e.translate);

    //remove edit state
    editRef.current = none;
    setEditing(false);
    document.removeEventListener('keyup', removeLastDrawnSegment);
  };

  const createNewLayer = (layerName: string): string => {
    if (isNone(mapRef.current)) throw new Error('No way');

    const layerId =
      layerName === FRESH_UNKNOWN_LAYER ? FRESH_UNKNOWN_LAYER : `fresh-${new Date().getTime()}`;

    const newSource = new VectorSource();
    const newLayer = new VectorLayer({
      source: newSource,
      style: selectedLayerStyle(styleCache, selectedGeofence?.id ?? selectedMicrofence?.id),
      properties: {
        name: layerName,
        id: layerId,
        geomobyProperties: {},
      },
    });
    if (isNone(layers)) {
      setLayers(
        some(
          new Map<string, { source: VectorSource<Geometry>; name: string }>([
            [layerId, { source: newSource, name: layerName }],
          ]),
        ),
      );
    } else {
      setLayers(some(layers.value.set(layerId, { source: newSource, name: layerName })));
    }
    mapRef.current.value.addLayer(newLayer);
    setSelectedLayer(some(layerId));
    layerChanged();
    return layerId;
  };

  const deleteLayer = async (): Promise<void> => {
    if (isNone(selectedLayer) || isNone(layerRef.current))
      throw new Error('UI should not trigger delete when no layer selected');
    if (isNone(layers)) throw new Error('UI should not trigger delete when there are no layers');
    if (isNone(mapRef.current)) return;

    const layerId = selectedLayer.value;
    const layer = layerRef.current.value;
    const m = mapRef.current.value;

    const mapLayer = getLayerFromMap(layer.name);
    if (mapLayer) {
      m.removeLayer(mapLayer);
    }
    const freshLayer = m
      .getAllLayers()
      .find((l, i) => l instanceof VectorLayer && l.get('id')?.includes(FRESH));
    if (freshLayer) {
      m.removeLayer(freshLayer);
    }

    const lyrs = layers.value;
    lyrs.delete(layerId);
    setLayers(some(lyrs));

    try {
      await axios.delete(`${triggersUrl}/${cid}/${pid}/geofences/${layerId}`, authedRequestConfig);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Delete' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Delete',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
    setDeletedFenceIds([]);
    setSelectedLayer(none);
    setSelectedGeofence(undefined);
    setSelectedMicrofence(undefined);
    selectedFenceRef.current = undefined;
    uiDeselectAllFences();
    setDrawType(none);
    layerIds.forEach(layer => changeVisibility(layer.id, layer.id !== layerId));
    setTotalFences(0);
  };

  const findFeature = async (fenceId: string) => {
    if (isNone(layerRef.current)) return;
    let feature = layerRef.current.value.source.getFeatures().find(f => f.get('id') === fenceId);
    if (!feature) {
      const geofence = await getGeofence();
      if (geofence) {
        const newFence = isPolygon
          ? new Polygon(geofence.points.coordinates)
          : isMultipolygon
          ? new MultiPolygon(geofence.points.coordinates)
          : new LineString(geofence.points.coordinates);

        if (isPolygon) {
          const coords: number[][] = (newFence as Polygon).getCoordinates()[0];
          (newFence as Polygon).setCoordinates([
            coords.map(coord => transform(coord, 'EPSG:4326', 'EPSG:3857')),
          ]);
        } else if (isMultipolygon) {
          const coords: number[][][] = (newFence as MultiPolygon).getCoordinates().map(polygon => {
            return polygon[0].map(coord => transform(coord, 'EPSG:4326', 'EPSG:3857'));
          });
          (newFence as MultiPolygon).setCoordinates([coords]);
        } else if (isLine) {
          const coords: number[][] = (newFence as LineString).getCoordinates();
          (newFence as LineString).setCoordinates(
            coords.map(coord => transform(coord, 'EPSG:4326', 'EPSG:3857')),
          );
        }
        feature = new Feature(newFence);
        feature.setGeometry(newFence);
        feature.setProperties(geofence);
        // Check again!
        const existingFeature = layerRef.current.value.source
          .getFeatures()
          .find(f => f.get('id') === fenceId);
        if (existingFeature) {
          layerRef.current.value.source.removeFeature(existingFeature);
        }
        layerRef.current.value.source.addFeature(feature);
        return feature;
      }
    }
    return feature;
  };

  const uiDeleteFence = async (optionalFenceId?: string): Promise<void> => {
    if (isNone(layerRef.current))
      throw new Error('UI should not be able to delete a feature when no layer is selected');
    let fenceId: string = optionalFenceId ?? '';
    const microfences =
      (
        (isSome(mapRef.current)
          ? (mapRef.current.value
              .getAllLayers()
              .find(lyr => lyr.get('id') === MICROFENCE_LAYER_ID) as AnimatedCluster)
          : undefined
        )?.getSource() as Cluster
      )
        ?.getSource()
        .getFeatures() ?? [];

    if (!fenceId) {
      if (availableGeofences.length === 0 && microfences.length === 0)
        throw new Error(
          'UI should not be able to delete a feature when there are no features to delete',
        );
      if (!selectedGeofence && !selectedMicrofence)
        throw new Error('UI should not be able to delete a feature when no feature is selected');
      if (isNone(layerRef.current)) return;

      fenceId = selectedGeofence?.id ?? selectedMicrofence?.id;
    }

    if (isNone(mapRef.current)) return;
    const layer = layerRef.current.value;
    const feature = await findFeature(fenceId);
    if (!feature) throw new Error(`Failed to find fence to perform deletion [${fenceId}]`);

    if (!fenceId.includes(FRESH))
      setDeletedFenceIds(list => [
        ...list,
        {
          id: fenceId,
          type:
            feature.getGeometry()?.getType()?.toLowerCase() === 'polygon' ||
            feature.get('points')?.type?.toLowerCase() === 'polygon'
              ? 'polygon'
              : feature.getGeometry()?.getType()?.toLowerCase() === 'multipolygon' ||
                feature.get('points')?.type?.toLowerCase() === 'multipolygon'
              ? 'multipolygon'
              : 'line',
        },
      ]);

    const bufferFeature =
      feature.get('zone') === FenceZone.breach
        ? layer.source.getFeatures().find(f => f.get('parentId') === feature.get('id'))
        : undefined;

    layer.source.removeFeature(feature);
    setSelectedGeofence(undefined);
    setSelectedMicrofence(undefined);
    selectedFenceRef.current = undefined;

    if (toNullable(selectedLayer) !== MICROFENCE_LAYER_ID && availableGeofences.length > 0) {
      setAvailableGeofences(availableGeofences.filter(f => f.id !== fenceId));
    } else if (
      toNullable(selectedLayer) === MICROFENCE_LAYER_ID &&
      (availableMicrofences.length > 0 || microfences.length)
    ) {
      setAvailableMicrofences(
        (availableMicrofences ?? microfences.map(m => m.getProperties())).filter(
          f => f.id !== fenceId,
        ),
      );
    }
    setLayersHaveChanged(true);

    if (bufferFeature) {
      uiDeleteFence(bufferFeature.get('id'));
    }
  };

  const uiSetEditing = () => {
    //when user clicks edit
    if (isSome(editRef.current))
      throw new Error('UI should not be able to set editing while already editing');
    if (isNone(mapRef.current)) return;
    if (isNone(layerRef.current)) {
      const layer = createNewLayer(FRESH_UNKNOWN_LAYER);
      const foundNewLayer = mapRef.current.value
        .getAllLayers()
        .find(lyr => lyr instanceof VectorLayer && lyr.get('name') === FRESH_UNKNOWN_LAYER);
      if (foundNewLayer) {
        layerRef.current = fromNullable({
          name: FRESH_UNKNOWN_LAYER,
          source: foundNewLayer.getSource(),
        });
      }
    }
    if (isNone(layerRef.current))
      throw new Error('UI should not be able to set editing while no layer selected');

    const lyr = layerRef.current.value;
    const m = mapRef.current.value;
    if (selectedFenceRef.current?.getGeometry()?.getType().toLowerCase() !== 'multipolygon') {
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      selectedFenceRef.current = undefined;
    }
    unsetMapClickSelection(m);

    lyr.source.on('addfeature', e => {
      if (e.feature?.get('id') || e.feature?.get('isMeasurementTool')) return;
      let layerId =
        isNone(selectedLayer) && toNullable(layerRef.current)?.name === FRESH_UNKNOWN_LAYER
          ? FRESH_UNKNOWN_LAYER
          : toNullable(selectedLayer);

      if (
        toNullable(selectedLayer) !== MICROFENCE_LAYER_ID &&
        isSome(layers) &&
        !Array.from(layers.value.keys()).find(l => l.includes(FRESH))
      ) {
        layerId = (e.target as VectorSource<Geometry>)?.get('id');
        setSelectedLayer(fromNullable(layerId));
      }
      if (e.feature?.getGeometry().getType() === 'Circle') {
        const tempFeat = fromCircle(e.feature.getGeometry() as Circle);
        e.feature.setGeometry(tempFeat);
      }

      if (
        selectedFenceRef.current &&
        selectedFenceRef.current.getGeometry() &&
        e.feature?.getGeometry().getType().toLowerCase() === 'multipolygon' &&
        (selectedFenceRef.current.get('type') === 'multipolygon' ||
          selectedFenceRef.current.get('points')?.type.toLowerCase() === 'multipolygon')
      ) {
        const coords = (selectedFenceRef.current.getGeometry() as MultiPolygon)?.getCoordinates();
        if (
          coords.find(
            c => JSON.stringify(c) === JSON.stringify(e.feature?.getGeometry().getCoordinates()[0]),
          )
        )
          return;
        coords.push(e.feature.getGeometry().getCoordinates()[0]);
        selectedFenceRef.current.setGeometry(new MultiPolygon(coords));

        if (selectedFenceRef.current.get('points')) {
          selectedFenceRef.current.get('points').coordinates.push([
            e.feature
              .getGeometry()
              .getCoordinates()[0][0]
              .map((c: [number, number]) => transform(c, 'EPSG:3857', 'EPSG:4326')),
          ]);
          if (!selectedFenceRef.current.get(FRESH)) {
            selectedFenceRef.current.set('updated', true);
          }
        }
        const featureToRemove = toNullable(layerRef.current)
          ?.source.getFeatures()
          .find((feature: Feature<Geometry>) => feature.get('id') === e.feature?.get('id'));
        if (featureToRemove) {
          toNullable(layerRef.current)?.source.removeFeature(featureToRemove);
        }
        setEditing(true);
        return;
      }

      const fenceName =
        toNullable(selectedLayer) === MICROFENCE_LAYER_ID
          ? MICROFENCE + getMicroFenceNumber()
          : GEOFENCE +
            getNewGeofenceNumber(
              lyr.source.getFeatures().map(f => f.getProperties() as GridRowData),
            );
      const fenceId = `fresh-${new Date().getTime()}`;
      e.feature?.setProperties({
        name: fenceName,
        id: fenceId,
        type:
          e.feature.getGeometry().getType() === 'LineString'
            ? 'line'
            : e.feature.getGeometry().getType().toLowerCase(),
        fresh: true,
        selected: true,
        layerId,
        layerName: lyr.source.get('name'),
        microfenceType: microfenceDrawTypeRef.current,
        geomobyProperties:
          toNullable(selectedLayer) === MICROFENCE_LAYER_ID
            ? Object.fromEntries(
                Object.entries(MICROFENCE_DEFAULT_PROPS).map(([key, val]) => [key, String(val)]),
              )
            : {},
        zone:
          e.feature.getGeometry().getType() === 'LineString'
            ? undefined
            : e.feature.getGeometry().getType() === 'MultiPolygon'
            ? FenceZone.cleared
            : FenceZone.none,
        numberOfArrows: e.feature.getGeometry().getType() === 'LineString' ? 4 : 0,
      });

      if (e.feature) {
        setCreateEditFence('CREATE');
        if (layerId === MICROFENCE_LAYER_ID) {
          let assetId: {
            deviceId?: string;
            gatewayId?: string;
            uuid?: string;
            major?: number;
            minor?: number;
            smartplugId?: string;
          };
          if (e.feature.get('microfenceType') === 'device') {
            assetId = {
              deviceId: '',
            };
          } else if (e.feature.get('microfenceType') === 'gateway') {
            assetId = {
              gatewayId: '',
            };
          } else if (e.feature.get('microfenceType') === 'beacon') {
            assetId = {
              uuid: '',
              major: 0,
              minor: 0,
            };
          } else if (e.feature.get('microfenceType') === 'smartplug') {
            assetId = {
              smartplugId: '',
            };
          }
          const newFeature = e.feature;
          lyr.source.forEachFeature(feature => {
            if (feature.get('id') === newFeature.get('id')) {
              feature.set('assetId', assetId);
              setSelectedMicrofence(feature.getProperties());
            }
          });

          setAvailableMicrofences(
            [...lyr.source.getFeatures().map(f => f.getProperties() as GridRowData)].sort((a, b) =>
              microfenceFilter?.order?.id === 'DESC'
                ? b.name.localeCompare(a.name)
                : a.name.localeCompare(b.name),
            ),
          ); // This is case insensitive. The pagination end-point it not. I need to come up with a better solution.
        } else {
          freshGeofencesRef.current = [...freshGeofencesRef.current, e.feature.getProperties()];
          setFreshGeofences(freshGeofencesRef.current);
          setAvailableGeofences(
            lyr.source
              .getFeatures()
              .filter(fence => !deletedFenceIds.find(f => f.id === fence.get('id')))
              .map(f => f.getProperties() as GridRowData)
              .sort((a, b) =>
                geofenceFilter.order?.id === 'ASC'
                  ? a.name.localeCompare(b.name)
                  : b.name.localeCompare(a.name),
              ),
          ); // This is case insensitive. The pagination end-point it not. I need to come up with a better solution.
          setSelectedGeofence(e.feature.getProperties() as GridRowData);
        }
      }
    });
    const draw = new Draw({
      type: GeometryType.POLYGON,
      source: lyr.source,
      stopClick: true,
    });

    draw.on('drawend', layerChanged);
    const modify = new Modify({
      source: lyr.source,
    });

    const translate = new Translate({
      condition: function (event) {
        return primaryAction(event) && platformModifierKeyOnly(event);
      },
      layers: m.getAllLayers(),
    });

    modify.on('modifyend', (e: ModifyEvent) => featureModified(e, lyr.source));

    const es = {
      draw: draw,
      modify: modify,
      modifyScaleRot: modify,
      snap: new Snap({
        source: lyr.source,
      }),
      translate: translate,
    };

    editRef.current = some(es);
    m.addInteraction(es.draw);
    m.addInteraction(es.snap);
    m.addInteraction(es.translate);
    setEditing(true);
    document.addEventListener('keyup', removeLastDrawnSegment);
  };

  function calculateCenterOfFence(geometry: LineString | Polygon | MultiPolygon) {
    const type = geometry.getType();
    let center: number[];
    const coordinates =
      type === 'Polygon'
        ? geometry.getCoordinates()[0].slice(1)
        : type === 'LineString'
        ? (geometry as LineString).getCoordinateAt(0.5)
        : type === 'MultiPolygon'
        ? (geometry.getCoordinates() as number[][][])[0][0]
        : [];

    let minRadius = 1;
    if (type === 'Polygon') {
      let x = 0;
      let y = 0;
      let i = 0;

      (coordinates as number[][]).forEach(coordinate => {
        if (Array.isArray(coordinate) && coordinate.length > 1) {
          x += coordinate[0];
          y += coordinate[1];
          i++;
        }
      });
      center = [x / i, y / i];
    } else if (type === 'MultiPolygon') {
      center = getCenter(geometry.getExtent());
    } else if (type === 'LineString') {
      center = (geometry as LineString).getCoordinateAt(0.5);
    } else {
      center = getCenter(geometry.getExtent());
    }
    let sqDistances: number[] = [];
    if (Array.isArray(coordinates)) {
      sqDistances = (
        type === 'Polygon' || type === 'MultiPolygon'
          ? (coordinates as number[][])
          : (coordinates as number[])
      ).map(coordinate => {
        if (Array.isArray(coordinate) && coordinate.length > 1) {
          const dx = coordinate[0] - center[0];
          const dy = coordinate[1] - center[1];
          return dx * dx + dy * dy;
        }
        return 0;
      });
      if (Array.isArray(sqDistances)) {
        minRadius = Math.sqrt(Math.max(...sqDistances)) / 3;
      }
    } else {
      minRadius = Math.max(getWidth(geometry.getExtent()), getHeight(geometry.getExtent())) / 3;
    }
    return {
      center: center,
      coordinates: coordinates,
      minRadius: minRadius,
      sqDistances: sqDistances,
    };
  }

  const scalableRotatableLayer = new VectorLayer({
    className: 'Scalable-rotatable-layer',
    source: toNullable(layerRef.current)?.source ?? new VectorSource(),
    style: feature => {
      const styles = [
        new Style({
          geometry: feature => {
            const modifyGeometry = feature.get('modifyGeometry');
            return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
          },
          stroke:
            feature.get('zone') === FenceZone.breach
              ? new Stroke({ color: [209, 27, 21, 1.0], width: 3 })
              : feature.get('zone') === FenceZone.buffer
              ? new Stroke({ color: [235, 138, 12, 1.0], width: 3 })
              : feature.get('zone') === FenceZone.cleared
              ? new Stroke({ color: [182, 66, 245], width: 3 })
              : new Stroke({ color: [76, 184, 196], width: 3 }),
          zIndex: FenceZIndexes.FENCE_MID_Z_INDEX,
        }),
      ];
      const modifyGeometry = feature.get('modifyGeometry');
      const geometry = modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
      const result = calculateCenterOfFence(geometry);
      const center = result.center;
      if (center) {
        const coordinates = result.coordinates;
        if (coordinates) {
          const minRadius = result.minRadius;
          const sqDistances = result.sqDistances;
          const rsq = minRadius * minRadius;
          const points = (coordinates as number[][]).filter(
            (coordinate: number[], index: number) => {
              return sqDistances[index] > rsq;
            },
          );
        }
      }
      if (
        feature.get('points')?.type === 'LineString' ||
        feature.get('type') === 'LineString' ||
        feature.get('type') === 'line'
      ) {
        styles.push(
          ...stylesForLineFence(getCoordsForNewTripwire(geometry.flatCoordinates, 4), false, true),
        );
      }
      return styles;
    },
  });

  function defaultScaleRotFenceStyle(cache: Map<string, Style[]>): StyleFunction {
    return (feature: Feature<Geometry> | RenderFeature, p1) => {
      feature.get('features').forEach((modifyFeature: Feature<Geometry>) => {
        const modifyGeometry = modifyFeature.get('modifyGeometry');
        if (modifyGeometry && feature.getGeometry() !== undefined) {
          const geometry = feature.getGeometry();
          if (!geometry) return;
          const point = getCenter(geometry.getExtent());
          let modifyPoint = modifyGeometry.point;
          if (!modifyPoint) {
            // save the initial geometry and vertex position
            modifyPoint = point;
            modifyGeometry.point = modifyPoint;
            modifyGeometry.geometry0 = modifyGeometry.geometry;
            // get anchor and minimum radius of vertices to be used
            const result = calculateCenterOfFence(modifyGeometry.geometry0);
            modifyGeometry.center = result.center;
            modifyGeometry.minRadius = result.minRadius;
          }

          const center = modifyGeometry.center;
          const minRadius = modifyGeometry.minRadius;
          let dx, dy;
          dx = modifyPoint[0] - center[0];
          dy = modifyPoint[1] - center[1];
          const initialRadius = Math.sqrt(dx * dx + dy * dy);
          if (initialRadius > minRadius && Array.isArray(point)) {
            const initialAngle = Math.atan2(dy, dx);
            dx = point[0] - center[0];
            dy = point[1] - center[1];
            const currentRadius = Math.sqrt(dx * dx + dy * dy);
            if (currentRadius > 0) {
              const currentAngle = Math.atan2(dy, dx);
              const geometry = modifyGeometry.geometry0.clone();
              geometry.scale(currentRadius / initialRadius, undefined, center);
              geometry.rotate(currentAngle - initialAngle, center);
              modifyGeometry.geometry = geometry;
            }
          }
        }
      });
    };
  }

  const calculateMeasurementDistance = (line: LineString) => {
    const length = getLength(line);
    if (!measurementType) {
      return Math.round(length * 100) / 100 + ' ' + 'm';
    }

    switch (measurementType) {
      case 'M':
        return length.toFixed(2) + ' ' + 'm';
      case 'FT':
        return (length * M_TO_FT).toFixed(2) + ' ' + 'ft';
      case 'KM':
        return (length * M_TO_KM).toFixed(2) + ' ' + 'km';
      case 'MI':
        return (length * M_TO_MI).toFixed(2) + ' ' + 'mi';
    }
  };

  const calculateArea = (polygon: Polygon) => {
    const area = getArea(polygon);
    if (!measurementType) {
      return Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
    }

    switch (measurementType) {
      case 'M2':
        return area.toFixed(2) + ' ' + 'm<sup>2</sup>';
      case 'FT2':
        return (area * M_TO_FT * M_TO_FT).toFixed(2) + ' ' + 'ft<sup>2</sup>';
      case 'KM2':
        return (area * M_TO_KM * M_TO_KM).toFixed(2) + ' km<sup>2</sup>';
      case 'MI2':
        return (area * M_TO_MI * M_TO_MI).toFixed(2) + ' mi<sup>2</sup>';
    }
  };

  const updateMeasureTooltip = (olmap: olMap) => {
    if (measureTooltipElementRef.current) {
      measureTooltipElementRef.current.parentNode?.removeChild(measureTooltipElementRef.current);
    }
    measureTooltipElementRef.current = document.createElement('div');
    measureTooltipRef.current = new Overlay({
      element: measureTooltipElementRef.current,
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: false,
      insertFirst: false,
    });
    olmap.addOverlay(measureTooltipRef.current);
  };

  const addFreshFeature = (addedFeature: Feature<Geometry>) => {
    setTimeout(() => {
      if (addedFeature.getProperties()?.id.includes(FRESH)) {
        currentlyDisplayedTripwiresRef.current.push(addedFeature);
      }
      currentlyDisplayedTripwiresRef.current = Array.from(
        new Set(currentlyDisplayedTripwiresRef.current),
      );
    }, 100);
  };

  const addShapeChangeModifyInteration = (
    source: VectorSource<Geometry>,
    type: 'line' | 'polygon' | 'multipolygon',
    addedFeature?: Feature<Geometry>,
  ) => {
    refreshInteractions();
    if (isNone(editRef.current) || isNone(mapRef.current)) return;
    const modifiableFeatures: Collection<Feature<Geometry>> = new Collection();
    if (addedFeature) {
      modifiableFeatures.push(addedFeature);
    }
    source.forEachFeature(feature => {
      if (
        feature.getProperties().points?.type.toLowerCase().includes(type) ||
        feature.getProperties().type === type
      ) {
        modifiableFeatures.push(feature);
      }
    });
    editRef.current.value.modify = new Modify({
      source: source,
      insertVertexCondition: type === 'line' ? never : always,
      features: modifiableFeatures,
    });
    editRef.current.value.modify.on('modifyend', (e: ModifyEvent) => {
      featureModified(e, source);
      if (type === 'polygon') {
        e.features.forEach(feature => {
          const geometry = feature.getGeometry();
          if (!(geometry instanceof Geometry)) return;
          if (selectedFenceRef.current?.get('id') !== feature.get('id')) return;
          setHasFences(!!featureContainsFeature(geometry));
          selectedFenceRef.current = feature as Feature<Geometry>;
          selectedFenceRef.current.set('selected', true);
          setSelectedGeofence(feature.getProperties() as GridRowData);
        });
      }
      if (type !== 'line' || !addedFeature) return;
      addedFeature.setStyle(selectedFenceStyle(new Map()));
      addFreshFeature(addedFeature);
    });
    editRef.current.value.modify.on('modifystart', (e: ModifyEvent) => {
      selectedFenceRef.current = e.features.getArray()[0] as Feature<Geometry>;
      selectedFenceRef.current.set('selected', true);
      setSelectedGeofence(e.features.getArray()[0].getProperties() as GridRowData);
      if (type !== 'line') return;
      if (!addedFeature) return;
      addedFeature.setProperties({ numberOfArrows: 4 });
    });

    mapRef.current.value.addInteraction(editRef.current.value.modify);
  };

  const addScaleRotModifyInteration = (
    source: VectorSource<Geometry>,
    type: 'line' | 'polygon' | 'multipolygon',
    addedFeature?: Feature<Geometry>,
  ) => {
    refreshInteractions();
    if (isNone(editRef.current) || isNone(mapRef.current)) return;
    const modifiableFeatures: Collection<Feature<Geometry>> = new Collection();
    if (addedFeature) {
      modifiableFeatures.push(addedFeature);
    }
    source.forEachFeature(feature => {
      if (
        feature.getProperties().points?.type.toLowerCase().includes(type) ||
        feature.getProperties().type === type
      ) {
        modifiableFeatures.push(feature);
      }
    });

    editRef.current.value.modifyScaleRot = new Modify({
      source: source,
      condition: event => {
        return primaryAction(event) && !platformModifierKeyOnly(event);
      },
      deleteCondition: never,
      insertVertexCondition: type === 'line' ? never : always,
      style: defaultScaleRotFenceStyle(new Map()),
    });
    editRef.current.value.translate = new Translate({
      condition: event => {
        return primaryAction(event) && platformModifierKeyOnly(event);
      },
      layers: [scalableRotatableLayer],
    });

    mapRef.current.value.addLayer(scalableRotatableLayer);
    editRef.current.value.modifyScaleRot.on('modifystart', (e: ModifyEvent) => {
      toNullable(mapRef.current)
        ?.getAllLayers()
        .forEach(layer => {
          if (
            !(layer instanceof VectorLayer) ||
            layer.getClassName() === 'Scalable-rotatable-layer'
          )
            return;
          layer.setVisible(false);
        });

      e.features.forEach(feature => {
        const geom = feature.getGeometry();
        if (!(geom instanceof Geometry)) return;
        (feature as Feature<Geometry>).set('modifyGeometry', { geometry: geom.clone() }, true);
        selectedFenceRef.current = feature as Feature<Geometry>;
        selectedFenceRef.current?.set('selected', true);
        setSelectedGeofence(feature.getProperties() as GridRowData);
      });
      if (type !== 'line') return;
      if (!addedFeature) return;
      addedFeature.setProperties({ numberOfArrows: 4 });
    });

    editRef.current.value.modifyScaleRot.on('modifyend', (e: ModifyEvent) => {
      changeVisibility(toNullable(layerRef.current)?.source.get('id'), true);
      e.features.forEach(feature => {
        const geom = feature.getGeometry();
        if (!(geom instanceof Geometry)) return;
        const modifyGeometry = feature.get('modifyGeometry');
        if (modifyGeometry) {
          (feature as Feature<Geometry>).setGeometry(modifyGeometry.geometry);
          (feature as Feature<Geometry>).unset('modifyGeometry', true);
          if (type === 'polygon') {
            setHasFences(!!featureContainsFeature(modifyGeometry.geometry));
          }
          selectedFenceRef.current = feature as Feature<Geometry>;
          selectedFenceRef.current?.set('selected', true);
          setSelectedGeofence(feature.getProperties() as GridRowData);
        }
      });

      featureModified(e, source);
      if (type !== 'line' || !addedFeature) return;
      addedFeature.setStyle(selectedFenceStyle(new Map()));
      addFreshFeature(addedFeature);
    });

    mapRef.current.value.addInteraction(editRef.current.value.modifyScaleRot);
    mapRef.current.value.addInteraction(editRef.current.value.translate);
  };

  const refreshInteractions = () => {
    if (isNone(mapRef.current)) return;
    if (isNone(editRef.current)) return;
    const olmap = mapRef.current.value;
    olmap.removeInteraction(editRef.current.value.draw);
    olmap.removeInteraction(editRef.current.value.modify);
    olmap.removeInteraction(editRef.current.value.modifyScaleRot);
    olmap.removeInteraction(editRef.current.value.translate);
    const scaleRotLayer = olmap
      .getAllLayers()
      .find(l => l.getClassName() === 'Scalable-rotatable-layer');
    if (scaleRotLayer) {
      mapRef.current.value.removeLayer(scaleRotLayer);
    }
  };

  const removeLastDrawnSegment = (e: KeyboardEvent) => {
    if (e.key === 'Backspace') {
      toNullable(editRef.current)?.draw.removeLastPoint();
    }
  };

  const drawEnd = (
    olmap: olMap,
    geometry: Geometry,
    layerSource: VectorSource<Geometry>,
    type: 'polygon' | 'multipolygon' | 'line',
  ) => {
    toNullable(layerRef.current)
      ?.source.getFeatures()
      .filter((f: Feature<Geometry>) => f.get('selected'))
      .forEach((f: Feature<Geometry>) => {
        f.set('selected', false);
      });

    layerChanged();
    if (type === 'polygon') {
      setHasFences(!!featureContainsFeature(geometry));
    }
    if (isNone(editRef.current)) return;
    // Slight hack to reset the editType. The default editType will not be active otherwise.
    setEditType(prev => fromNullable(toNullable(prev)));
    // Needs a short interval for the new polygon to be registered as part of the layer.
    setTimeout(() => {
      addShapeChangeModifyInteration(layerSource, type);
      if (isNone(editRef.current)) return;
      olmap.addInteraction(editRef.current.value.draw);
    });
  };

  const updateMeasurementType = (type: 'Polygon' | 'LineString') => {
    if (isNone(mapRef.current)) return;
    if (isNone(editRef.current)) return;
    if (isNone(layerRef.current)) return;
    const olmap = mapRef.current.value;
    refreshInteractions();
    const layerSource = layerRef.current.value.source;

    microfenceDrawTypeRef.current = undefined;
    editRef.current.value.draw = new Draw({
      source: layerSource,
      type,
      style: [
        new Style({
          image: new CircleStyle({
            radius: 2,
            fill: new Fill({
              color: [1, 1, 1],
            }),
          }),
          stroke: new Stroke({
            color: [1, 1, 1],
            lineDash: [4, 8],
          }),
          zIndex: FenceZIndexes.LINE_Z_INDEX,
        }),
      ],
    });

    olmap.addInteraction(editRef.current.value.draw);
    editRef.current.value.draw.on('drawstart', e => {
      e.feature.setStyle(
        new Style({
          stroke: new Stroke({
            color: [1, 1, 1],
            lineDash: [4, 8],
          }),
        }),
      );

      updateMeasureTooltip(olmap);
      let tooltipCoord = e.feature?.getGeometry().getCoordinates();
      e.feature.getGeometry().on('change', (evt: Event) => {
        const geom = evt.target;
        if (geom instanceof Polygon) {
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
          const area = calculateArea(geom);
          if (measureTooltipElementRef.current && measureTooltipRef.current && area) {
            measureTooltipElementRef.current.innerHTML = area;
            measureTooltipElementRef.current.style.background = `rgb(${[0, 0, 0, 0.25]})`;
            measureTooltipElementRef.current.style.borderRadius = '5px';
            measureTooltipRef.current.setPosition(tooltipCoord);
          }
        } else if (geom instanceof LineString) {
          tooltipCoord = geom.getLastCoordinate();
          const distance = calculateMeasurementDistance(geom);
          if (measureTooltipElementRef.current && measureTooltipRef.current && distance) {
            measureTooltipElementRef.current.innerHTML = distance;
            measureTooltipElementRef.current.style.background = `rgb(${[0, 0, 0, 0.25]})`;
            measureTooltipElementRef.current.style.borderRadius = '5px';
            measureTooltipRef.current.setPosition(tooltipCoord);
          }
        }
      });
    });

    editRef.current.value.draw.on('drawend', e => {
      e.feature.set('isMeasurementTool', true);
      e.feature.setStyle(
        new Style({
          stroke: new Stroke({
            color: [1, 1, 1, 0],
          }),
        }),
      );
      const foundFence = toNullable(layerRef.current)
        ?.source.getFeatures()
        .find(f => f.get('id') === e.feature.get('id'));
      if (foundFence) {
        toNullable(layerRef.current)?.source.removeFeature(foundFence);
      }
      updateMeasureTooltip(olmap);
    });
  };

  const changeDrawShape = () => {
    if (isNone(mapRef.current)) return;
    if (isNone(editRef.current)) return;
    if (isNone(layerRef.current)) return;
    if (isNone(drawType)) return;
    const olmap = mapRef.current.value;
    refreshInteractions();
    const layerSource = layerRef.current.value.source;

    if (isSome(editType)) {
      switch (editType.value) {
        case 'SHAPE_CHANGE':
          addShapeChangeModifyInteration(
            layerSource,
            drawType.value === 'CIRCLE' || drawType.value === 'POLYGON'
              ? 'polygon'
              : drawType.value === 'MULTIPOLYGON'
              ? 'multipolygon'
              : 'line',
          );
          break;
        case 'SCALE_ROT':
          addScaleRotModifyInteration(
            layerSource,
            drawType.value === 'CIRCLE' || drawType.value === 'POLYGON'
              ? 'polygon'
              : drawType.value === 'MULTIPOLYGON'
              ? 'multipolygon'
              : 'line',
          );
          break;
      }
    }

    switch (drawType.value) {
      case 'CIRCLE':
        microfenceDrawTypeRef.current = undefined;
        if (layerSource.get('name') === TRACKING_BOUNDS) {
          editRef.current.value.draw = styleForDraw(layerSource, GeometryType.CIRCLE, '#fff');
        } else {
          editRef.current.value.draw = new Draw({
            source: layerSource,
            type: GeometryType.CIRCLE,
            stopClick: true,
          });
        }

        olmap.addInteraction(editRef.current.value.draw);
        editRef.current.value.draw.on('drawend', e => {
          if (layerSource.get('name') === TRACKING_BOUNDS) e.feature.setStyle(undefined);
          drawEnd(olmap, e.feature.getGeometry(), layerSource, 'polygon');
        });
        break;
      case 'POLYGON':
        microfenceDrawTypeRef.current = undefined;
        if (layerSource.get('name') === TRACKING_BOUNDS) {
          editRef.current.value.draw = styleForDraw(layerSource, GeometryType.POLYGON, '#fff');
          olmap.addInteraction(editRef.current.value.draw);
          editRef.current.value.draw.on('drawstart', e => {
            e.feature.setStyle(
              new Style({
                fill: new Fill({ color: [255, 255, 255, 0.3] }),
                stroke: new Stroke({
                  color: 'rgba(0, 0, 0, 0)',
                  width: 3,
                }),
              }),
            );
          });
        } else {
          editRef.current.value.draw = new Draw({
            source: layerSource,
            type: GeometryType.POLYGON,
            stopClick: true,
          });
          olmap.addInteraction(editRef.current.value.draw);
        }
        editRef.current.value.draw.on('drawend', e => {
          if (layerSource.get('name') === TRACKING_BOUNDS) e.feature.setStyle(undefined);
          drawEnd(olmap, e.feature.getGeometry(), layerSource, 'polygon');
        });
        break;
      case 'MULTIPOLYGON':
        microfenceDrawTypeRef.current = undefined;
        editRef.current.value.draw = styleForDraw(
          layerSource,
          GeometryType.MULTI_POLYGON,
          '#B642F5',
        );
        olmap.addInteraction(editRef.current.value.draw);
        editRef.current.value.draw.on('drawstart', e => {
          e.feature.setStyle(
            new Style({
              fill: new Fill({ color: [255, 255, 255, 0.3] }),
              stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0)',
                width: 3,
              }),
            }),
          );
        });

        editRef.current.value.draw.on('drawend', e => {
          e.feature.setStyle(undefined);
          drawEnd(olmap, e.feature.getGeometry(), layerSource, 'multipolygon');
        });
        break;
      case 'TRIPWIRE':
        microfenceDrawTypeRef.current = undefined;
        editRef.current.value.draw = new Draw({
          source: layerSource,
          type: GeometryType.LINE_STRING,
          stopClick: true,
          maxPoints: 2,
          minPoints: 2,
        });
        olmap.addInteraction(editRef.current.value.draw);
        editRef.current.value.draw.on('drawend', e => {
          drawEnd(olmap, e.feature.getGeometry(), layerSource, 'line');
          if (isNone(editRef.current)) return;
          olmap.removeInteraction(editRef.current.value.modify);
          addFreshFeature(e.feature);
        });
        break;
      case 'MICROFENCE_GATEWAY':
        microfenceDrawTypeRef.current = 'gateway';
        editRef.current.value.draw = new Draw({
          source: layerSource,
          type: GeometryType.POINT,
          stopClick: true,
        });
        olmap.addInteraction(editRef.current.value.draw);
        editRef.current.value.draw.on('drawend', e => {
          layerChanged();
          e.feature.set('microfenceType', 'gateway');
        });

        editRef.current.value.modify = new Modify({
          source: layerSource,
          hitDetection: olmap.getAllLayers().find(l => l.get('id') === MICROFENCE_LAYER_ID) as
            | HitDetectionLayer
            | undefined,
        });
        olmap.addInteraction(editRef.current.value.modify);
        editRef.current.value.modify.on('modifyend', (e: ModifyEvent) =>
          featureModified(e, layerSource),
        );
        break;
      case 'MICROFENCE_BEACON':
        microfenceDrawTypeRef.current = 'beacon';
        editRef.current.value.draw = new Draw({
          source: layerSource,
          type: GeometryType.POINT,
          stopClick: true,
        });
        olmap.addInteraction(editRef.current.value.draw);
        editRef.current.value.draw.on('drawend', e => {
          layerChanged();
          e.feature.set('microfenceType', 'beacon');
        });

        editRef.current.value.modify = new Modify({
          source: layerSource,
          hitDetection: olmap.getAllLayers().find(l => l.get('id') === MICROFENCE_LAYER_ID) as
            | HitDetectionLayer
            | undefined,
        });
        olmap.addInteraction(editRef.current.value.modify);
        editRef.current.value.modify.on('modifyend', (e: ModifyEvent) =>
          featureModified(e, layerSource),
        );
        break;
      case 'MICROFENCE_DEVICE':
        microfenceDrawTypeRef.current = 'device';
        editRef.current.value.draw = new Draw({
          source: layerSource,
          type: GeometryType.POINT,
          stopClick: true,
        });
        olmap.addInteraction(editRef.current.value.draw);
        editRef.current.value.draw.on('drawend', e => {
          layerChanged();
          e.feature.set('microfenceType', 'device');
        });
        editRef.current.value.modify = new Modify({
          source: layerSource,
          hitDetection: olmap.getAllLayers().find(l => l.get('id') === MICROFENCE_LAYER_ID) as
            | HitDetectionLayer
            | undefined,
        });
        olmap.addInteraction(editRef.current.value.modify);
        editRef.current.value.modify.on('modifyend', (e: ModifyEvent) =>
          featureModified(e, layerSource),
        );
        break;
      case 'MEASURE':
        setMeasurementType('M');
        break;
    }
  };

  const changeMeasurementType = () => {
    if (!measurementType) return;

    if (measurementType.includes('2')) {
      updateMeasurementType('Polygon');
    } else {
      updateMeasurementType('LineString');
    }
  };

  const sourceAndNameToLayerAndName = (
    lyr: Option<{ name: string; source: VectorSource<Geometry> }>,
  ) =>
    pipe(
      lyr,
      chain(layer => {
        const fromMap = getLayerFromMap(layer.name);
        return fromMap
          ? some({
              layer: fromMap,
              name: layer.name,
            })
          : none;
      }),
    );

  const featureContainsFeature = (geometry: Geometry): Feature<Geometry> | undefined => {
    const containedFeatures = toNullable(layerRef.current)
      ?.source.getFeatures()
      .filter(f => {
        const ex = f.getGeometry()?.getExtent();
        if (f.getProperties().geometry.getType().toLowerCase() === 'polygon') {
          const coords = (f.getGeometry() as Polygon).getCoordinates()[0];
          if (!coords) return false;
          return coords.every((coord: number[]) => geometry.intersectsCoordinate(coord));
        }
      });

    return containedFeatures?.length === 1 ? containedFeatures[0] : undefined;
  };

  const setAsBreachZone = async (
    fenceId: string,
    offset: number,
    bufferShape: BufferShapeType,
  ): Promise<void> => {
    if (isNone(layerRef.current))
      throw new Error('UI should not be able to add zones while no layer selected');
    if (isNone(mapRef.current)) return;

    const lyr = layerRef.current.value;
    const feature = await findFeature(fenceId);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    selectedFenceRef.current = feature;
    const geometry = (feature as Feature<Geometry>).getGeometry();
    if (!geometry)
      throw new Error('UI should not be able to add zones to a feature not without a geometry');
    const coords: number[][] =
      geometry.getType()?.toLowerCase() === 'polygon'
        ? (geometry as Polygon).getCoordinates()[0]
        : geometry.getType() === 'LineString'
        ? (geometry as LineString).getCoordinates()
        : [];
    if (!coords.length)
      throw new Error('UI should not be able to add zones to a feature without coords');

    // Find a circle that contains the feature
    const center = getCenter(geometry.getExtent());
    const centerRadius = Math.max(
      ...coords.map(coord => new LineString([center, coord]).getLength()),
    );

    // Using the centroid may result in a smaller circle
    const centroid = coords
      .reduce((acc, cur) => [acc[0] + cur[0], acc[1] + cur[1]])
      .map(sum => sum / coords.length);
    const centroidRadius = Math.max(
      ...coords.map(coord => new LineString([centroid, coord]).getLength()),
    );

    const radiusScaleFactor = 1.005; // Determined by trial and error
    const coordinates = (geometry as Polygon)
      .getCoordinates()[0]
      .map(coord => transform(coord, 'EPSG:3857', 'EPSG:4326'));
    const maxY = Math.max(...coordinates.map(coord => coord[1]));
    const minY = Math.min(...coordinates.map(coord => coord[1]));
    const scaledOffset = offset / Math.cos((((maxY + minY) / 2) * Math.PI) / 180);

    // Use whichever has the smaller radius, adding on the buffer distance (scaling both as per the above)
    let bufferFeature: Feature<Geometry>;

    if (bufferShape === 'Circle') {
      bufferFeature = new Feature(
        fromCircle(
          centerRadius < centroidRadius
            ? new Circle(center, centerRadius * radiusScaleFactor + scaledOffset)
            : new Circle(centroid, centroidRadius * radiusScaleFactor + scaledOffset),
        ),
      );
    } else {
      const bufferZone = (
        await axios.post<{ points: { type: string; coordinates: number[][][] } }>(
          `${triggersUrl}/${cid}/${pid}/geofences/${feature.get('layerId')}/polygon/build-zone`,
          {
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
            bufferMeters: offset === undefined || offset < 1 ? 1 : offset,
            limit: 1000,
          },
          authedRequestConfig,
        )
      ).data;

      const bufferPoints = polygonPointsFromJson(bufferZone);
      bufferFeature = new Feature({
        geometry: new Polygon((bufferPoints?.getGeometry() as Polygon).getCoordinates()),
      });
    }

    const newFenceName = `${feature.get('name')}_warning`.replace(/(\D)0$/, '$1');
    const newfenceId = `fresh-buffer-${new Date().getTime()}`;
    bufferFeature.setProperties({
      name: newFenceName,
      id: newfenceId,
      layerId: feature.get('layerId'),
      type: 'polygon',
      fresh: true,
      zone: FenceZone.buffer,
      geomobyProperties: {},
      parentId: feature.get('id'),
    });
    lyr.source.addFeature(bufferFeature);

    feature.setProperties({
      zone: FenceZone.breach,
      parentId: undefined,
      updated: true,
    });

    const breachIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (breachIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        { ...breachIsFresh, zone: FenceZone.breach },
      ];
    }

    if (toNullable(selectedLayer) !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const index = availableGeofences.findIndex(
          f => f.id === selectedFenceRef.current?.get('id'),
        );
        const existingBufferIndex = availableGeofences.findIndex(
          f => f.id === feature.get('parentId'),
        );
        if (existingBufferIndex > -1) {
          availableGeofences.splice(existingBufferIndex, 1);
        }
        const bufferZone = {
          name: newFenceName,
          id: newfenceId,
          type: 'polygon',
          zone: FenceZone.buffer,
          geomobyProperties: {},
          parentId: feature.get('id'),
        } as FenceNameIdZone;
        if (index > -1) {
          availableGeofences[index].zone = FenceZone.breach;
          availableGeofences[index].parentId = undefined;
        }
        freshGeofencesRef.current = [...freshGeofencesRef.current, bufferZone];
        availableGeofences.splice(index + 1, 0, bufferZone);
      }
      setTotalFences(totalFences + 1 ?? 0);
      setAvailableGeofences(availableGeofences);
      setFreshGeofences(freshGeofencesRef.current);
    }
    setZoneChange(new Date());
  };

  const setAsBufferZone = async (fenceId: string): Promise<void> => {
    if (isNone(layerRef.current))
      throw new Error('UI should not be able to add zones while no layer selected');
    if (isNone(mapRef.current)) return;

    const lyr = layerRef.current.value;
    const feature = await findFeature(fenceId);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    selectedFenceRef.current = feature;
    const geometry = feature.getGeometry();
    if (!geometry)
      throw new Error('UI should not be able to add zones to a feature without geometry');
    const containedFeature = featureContainsFeature(geometry);

    if (!containedFeature?.get('parentId')) {
      containedFeature?.setProperties({
        zone: FenceZone.breach,
        type: 'polygon',
        parentId: undefined,
        updated: true,
      });
      feature.setProperties({
        zone: FenceZone.buffer,
        type: 'polygon',
        parentId: containedFeature?.get('id'),
        name: `${containedFeature?.get('name')}_warning`,
        updated: true,
      });
    }

    const bufferIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (bufferIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        {
          ...bufferIsFresh,
          name: `${containedFeature?.get('name')}_warning`,
          zone: FenceZone.buffer,
        },
      ];
    }
    const breachIsFresh = freshGeofencesRef.current.find(f => f.id === containedFeature?.get('id'));
    if (breachIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== containedFeature?.get('id')),
        { ...breachIsFresh, zone: FenceZone.breach },
      ];
    }

    if (toNullable(selectedLayer) !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const bufferIndex = availableGeofences.findIndex(
          f => f.id === selectedFenceRef.current?.getProperties().id,
        );
        if (bufferIndex > -1 && containedFeature) {
          const containedFeatureIndex = availableGeofences.findIndex(
            f => f.id === containedFeature.getProperties().id,
          );
          if (containedFeatureIndex > -1) {
            availableGeofences[containedFeatureIndex].zone = FenceZone.breach;
            availableGeofences[containedFeatureIndex].parentId = undefined;
          }
          availableGeofences[bufferIndex].zone = FenceZone.buffer;
          availableGeofences[bufferIndex].parentId = containedFeature.get('id');
          availableGeofences[bufferIndex].name = `${containedFeature.get('name')}_warning`;
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
    setFreshGeofences(freshGeofencesRef.current);
  };

  const unsetAsBreachZone = async (fenceId: string): Promise<void> => {
    if (isNone(layerRef.current))
      throw new Error('UI should not be able to add zones while no layer selected');
    if (isNone(mapRef.current)) return;

    const lyr = layerRef.current.value;
    const feature = await findFeature(fenceId);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    selectedFenceRef.current = feature;
    const relatedFeature = lyr.source
      .getFeatures()
      .find(f => f.get('parentId') === feature.get('id'));
    if (relatedFeature) {
      uiDeleteFence(relatedFeature.get('id'));
    }
    feature.setProperties({
      zone: FenceZone.none,
      parentId: undefined,
      updated: true,
    });

    const breachIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (breachIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        { ...breachIsFresh, zone: FenceZone.none },
      ];
    }

    if (toNullable(selectedLayer) !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0 && relatedFeature) {
        const relatedFeatureIndex = availableGeofences.findIndex(
          f => f.id === relatedFeature.get('id'),
        );
        if (relatedFeatureIndex > -1) {
          freshGeofencesRef.current = freshGeofencesRef.current.filter(
            f => f.id !== relatedFeature?.get('id'),
          );
          setFreshGeofences(freshGeofencesRef.current);
          availableGeofences.splice(relatedFeatureIndex, 1);
        }
        const breachIndex = availableGeofences.findIndex(f => f.id === feature.get('id'));
        if (breachIndex > -1) {
          availableGeofences[breachIndex].zone = FenceZone.none;
          availableGeofences[breachIndex].parentId = undefined;
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
  };

  const setAsClearedZone = async (fenceId: string): Promise<void> => {
    if (isNone(layerRef.current))
      throw new Error('UI should not be able to add zones while no layer selected');
    if (isNone(mapRef.current)) return;

    const lyr = layerRef.current.value;
    const feature = await findFeature(fenceId);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');
    selectedFenceRef.current = feature;

    feature.setProperties({
      ...feature.getProperties(),
      zone: FenceZone.cleared,
      updated: true,
    });

    const clearedIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (clearedIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        { ...clearedIsFresh, zone: FenceZone.cleared },
      ];
    }

    if (toNullable(selectedLayer) !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const index = availableGeofences.findIndex(
          f => f.id === selectedFenceRef.current?.getProperties().id,
        );
        if (index > -1) {
          availableGeofences[index].zone = FenceZone.cleared;
          availableGeofences[index].parentId = undefined;
        }
      }
      setAvailableGeofences(availableGeofences);
    }

    setSelectedGeofence(feature.getProperties() as GridRowData);
    setFreshGeofences(freshGeofencesRef.current);
  };

  const unsetAsClearedZone = async (fenceId: string): Promise<void> => {
    if (isNone(layerRef.current))
      throw new Error('UI should not be able to add zones while no layer selected');
    if (isNone(mapRef.current)) return;

    const lyr = layerRef.current.value;
    const feature = await findFeature(fenceId);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    selectedFenceRef.current = feature;
    feature.setProperties({
      zone: FenceZone.none,
      parentId: undefined,
      updated: true,
    });

    const clearedIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (clearedIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        { ...clearedIsFresh, zone: FenceZone.none },
      ];
    }

    if (toNullable(selectedLayer) !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const clearedIndex = availableGeofences.findIndex(f => f.id === feature.get('id'));
        if (clearedIndex > -1) {
          availableGeofences[clearedIndex].zone = FenceZone.none;
          availableGeofences[clearedIndex].parentId = undefined;
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
    setFreshGeofences(freshGeofencesRef.current);
  };

  const removeBufferZone = async (fenceId: string): Promise<void> => {
    if (isNone(layerRef.current))
      throw new Error('UI should not be able to add zones while no layer selected');
    if (isNone(mapRef.current)) return;

    const lyr = layerRef.current.value;
    const feature = await findFeature(fenceId);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    selectedFenceRef.current = feature;
    const relatedFeature = feature.get('parentId')
      ? lyr.source.getFeatures().find(f => f.get('id') === feature.get('parentId'))
      : undefined;
    relatedFeature?.setProperties({
      zone: FenceZone.none,
      updated: true,
    });

    uiDeleteFence(feature.get('id'));

    if (toNullable(selectedLayer) !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const relatedFeatureIndex = availableGeofences.findIndex(
          f => f.id === relatedFeature?.get('id') || f.id === feature.get('parentId'),
        );
        if (relatedFeatureIndex > -1) {
          availableGeofences[relatedFeatureIndex].zone = FenceZone.none;
          availableGeofences[relatedFeatureIndex].parentId = undefined;
        }

        const bufferIndex = availableGeofences.findIndex(f => f.id === fenceId);
        if (bufferIndex > -1) {
          availableGeofences.splice(bufferIndex, 1);
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
  };

  const updateGeomobyProperties = async (
    geomobyProperties: Record<string, string>,
  ): Promise<Record<string, string> | undefined> => {
    if (isNone(layerRef.current))
      throw new Error('UI should not be able to add geomobyProperties while no layer is selected');
    if (isNone(mapRef.current)) return;
    if (!selectedGeofence && !selectedMicrofence)
      throw new Error(
        'UI should not be able to add geomobyProperties while no feature is selected',
      );

    const lyr = layerRef.current.value;
    const feature = lyr.source
      .getFeatures()
      .find(f => f.get('id') === (selectedGeofence?.id ?? selectedMicrofence?.id));
    let newFeature;
    if (!feature) {
      const foundFeature = await getGeofence();
      if (foundFeature) {
        newFeature = new Feature(
          isPolygon
            ? new Polygon(foundFeature?.points.coordinates)
            : isMultipolygon
            ? new MultiPolygon(foundFeature.points.coordinates)
            : new LineString(foundFeature.points.coordinates),
        );
        newFeature.setProperties({
          ...foundFeature,
          geomobyProperties: geomobyProperties,
          updated: true,
        });
        lyr.source.addFeature(newFeature);
      }
    }

    if (!feature && !newFeature)
      throw new Error(
        'UI should not be able to add geomobyProperties to a feature not in the selected layer',
      );

    if (feature) {
      feature.setProperties({
        geomobyProperties: geomobyProperties,
        updated: true,
      });
    }

    setLayersHaveChanged(true);
    return geomobyProperties;
  };

  // Required for setting the next to geofence name to Geofence n+1
  const getNewGeofenceNumber = (newFences: GridRowData[]): number => {
    const geofenceNumbers: number[] = [];
    [...availableGeofences, ...(newFences ?? [])].forEach(f => {
      const name: string = f.name ?? '';
      if (
        name.startsWith(GEOFENCE) &&
        name.length > GEOFENCE.length &&
        !isNaN(Number(name.substring(GEOFENCE.length)))
      ) {
        geofenceNumbers.push(Number(name.substring(GEOFENCE.length)));
      }
    });
    const total = Math.max(...geofenceNumbers, Number(paginatedCount), 0) + 1;
    setTotalFences(total);
    return total;
  };

  const getMicroFenceNumber = (): number => {
    const microfences = toNullable(layerRef.current)?.source.getFeatures();
    const microfenceNumbers: number[] = [];
    if (microfences) {
      microfences.forEach(m => {
        const name = m.get('name');
        if (
          name?.startsWith(MICROFENCE) &&
          name.length > MICROFENCE.length &&
          !isNaN(Number(name.substring(MICROFENCE.length)))
        ) {
          microfenceNumbers.push(Number(name.substring(MICROFENCE.length)));
        }
      });
    }
    return Number(Math.max(...microfenceNumbers, microfenceNumbers.length ?? 0) + 1);
  };

  const featureGeometryToFenceNameIdZone = () => {
    const props = selectedFenceRef.current?.getProperties();
    return {
      name: props?.name,
      id: props?.id,
      type:
        selectedFenceRef.current?.getGeometry() instanceof Polygon
          ? 'polygon'
          : selectedFenceRef.current?.getGeometry() instanceof MultiPolygon
          ? 'multipolygon'
          : 'line',
      zone: props?.zone,
      geomobyProperties: props?.geomobyProperties,
      numberOfArrows:
        props?.type === 'LineString' || props?.points?.type === 'LineString' ? 4 : undefined,
      parentId: props?.parentId,
    } as FenceNameIdZone;
  };

  const moveUnknownFenceToExistingLayer = () => {
    if (toNullable(layerRef.current)?.name === FRESH_UNKNOWN_LAYER) {
      const freshUnknownLayer = getLayerFromMap(FRESH_UNKNOWN_LAYER);
      const existingLayer = getLayerFromMap(
        layerIds.find(lyr => lyr.id === selectedGeofence?.layerId)?.name ?? '',
      );
      if (freshUnknownLayer && existingLayer && isSome(mapRef.current)) {
        const freshUnknownFence = freshUnknownLayer.getSource().getFeatures()?.[0];
        freshUnknownFence.set('layerId', existingLayer.getSource().get('id'));
        freshUnknownFence.set('layerName', existingLayer.getSource().get('name'));

        existingLayer.getSource().addFeature(freshUnknownFence);
        mapRef.current.value.removeLayer(freshUnknownLayer);
        toNullable(layers)?.delete(FRESH_UNKNOWN_LAYER);
      }
    }
  };

  useEffect(() => {
    if (!specifiedCoordinates) return;

    const initialise = async () => {
      const { map: m, setSource: setMapSource } = createMap(
        EDIT_MAP_ID,
        createOutdoorMapDefaults({
          sourceType: mapSourceType,
          edit: true,
          specifiedCoordinates: specifiedCoordinates ?? [initialLongitude, initialLatitude],
          mapApiKeys,
        }),
      );
      setSourceRef.current = fromNullable(setMapSource);

      setFencesLoading(true);
      const [vls, microfenceLayer] = await Promise.all([
        getVectorLayers(triggersUrl, authedRequestConfig, { cid, pid }, bounds),
        getMicrofencesVectorLayer(triggersUrl, authedRequestConfig, { cid, pid }, bounds),
      ]);
      vls.set(MICROFENCE_LAYER_ID, {
        layer: microfenceLayer,
        name: MICROFENCE_LAYER_LABEL,
      });
      setLayers(
        some(
          new Map(
            Array.from(vls.entries()).map(([key, { layer, name }]) => [
              key,
              {
                source:
                  layer.getProperties().id === MICROFENCE_LAYER_ID
                    ? (layer.getSource() as Cluster).getSource()
                    : layer.getSource(),
                name,
              },
            ]),
          ),
        ),
      );
      vls.forEach((vl, id) => {
        if (id !== MICROFENCE_LAYER_ID) {
          vl.layer.setSource(vl.layer.getSource());
        }
        m.addLayer(vl.layer);
      });
      setFencesLoading(false);
      setMapIsLoading(false);
      mapRef.current = some(m);
      if (isNone(mapRef.current) || mapRef.current.value.hasListener('click')) return;
      setMapClickDeselection(mapRef.current.value);
      setMapMoveHandler(mapRef.current.value);
      setCurrentCenter(mapRef.current.value.getView().getCenter());

      mapRef.current.value.getView().on('change:resolution', () => {
        const zoom = toNullable(mapRef.current)?.getView().getZoom() || 0;
        const res = toNullable(mapRef.current)?.getView().getResolution() || 0;

        if (zoom > ZOOM_THRESHOLD - 1) {
          toNullable(mapRef.current)
            ?.getView()
            .setZoom(ZOOM_THRESHOLD - 1);
        }
        extentInDegreesRef.current =
          zoom <= initialZoomHeight
            ? initialExtentInDegrees
            : (res / Math.min(zoom, ZOOM_THRESHOLD - 1)) * 0.1;
        if (
          !currentlyDisplayedTripwiresRef.current ||
          currentlyDisplayedTripwiresRef.current.length === 0
        )
          return;
        currentlyDisplayedTripwiresRef.current.forEach(f => {
          const extent = f.getGeometry()?.getExtent();
          if (extent && extent.length > 0) {
            const size = Math.abs(extent[0] - extent[2]) + Math.abs(extent[1] - extent[3]);
            f.setProperties({ numberOfArrows: size / res < 50 ? 2 : size / res > 500 ? 4 : 3 });
          }
        });
      });
    };

    const timerId: NodeJS.Timeout = setTimeout(() => {
      initialise();
    }, 100);
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specifiedCoordinates]);

  useEffect(() => {
    if (isNone(layers)) return;
    if (isNone(mapRef.current)) return;
    if (editing) return;

    const loadMoreFences = async () => {
      setFencesLoading(true);
      const { latitude, longitude, extentInDegrees } = bounds;
      const [xMax, yMax] = transform(
        [longitude + extentInDegrees, latitude + extentInDegrees / 2],
        'EPSG:4326',
        'EPSG:3857',
      );
      const [xMin, yMin] = transform(
        [longitude - extentInDegrees, latitude - extentInDegrees / 2],
        'EPSG:4326',
        'EPSG:3857',
      );
      const extent: [number, number, number, number] = [xMax, yMax, xMin, yMin];
      // already got features for this extent
      if (knownExtents.current.some(ext => containsExtent(ext, extent))) {
        setFencesLoading(false);
        return;
      }

      getVectorLayers(triggersUrl, authedRequestConfig, { cid, pid }, bounds)
        .then(vls => {
          setFencesLoading(false);
          const newLayers = Array.from(vls.entries()).map(([key, { layer, name }]) => ({
            key,
            source:
              layer.getProperties().id === MICROFENCE_LAYER_ID
                ? (layer.getSource() as Cluster).getSource()
                : layer.getSource(),
            name,
          }));

          newLayers.forEach(nl => {
            const existingLayer = getLayerFromMap(nl.name);
            const newFeatures = nl.source.getFeatures();
            let existingSource = existingLayer?.getSource();
            if (existingSource instanceof Cluster) {
              existingSource = existingSource.getSource() as VectorSource<Geometry>;
            }
            const existingFeatures = existingSource?.getFeatures() || [];
            const deletedFeatures: [string, boolean][] = deletedFenceIds.map(({ id }) => [
              id,
              true,
            ]);
            const keepIds = Object.fromEntries([
              ...existingFeatures.map(f => [`${f.get('id')}`, true]),
              ...deletedFeatures,
            ]);

            const keepFeatures = newFeatures.filter(f => !keepIds[f.get('id')]);
            currentlyDisplayedTripwiresRef.current = Array.from(
              new Set([
                ...currentlyDisplayedTripwiresRef.current,
                ...existingFeatures,
                ...keepFeatures,
              ]),
            ).filter(f => f.get('points')?.type === 'LineString' || f.get('type') === 'LineString');
            existingSource?.addFeatures(keepFeatures);
          });
        })
        .catch(error => {
          setFencesLoading(false);
          if (selectedGeofence) {
            findFeature(selectedGeofence?.id);
          }
          setSaveNotification({
            id: SaveResult.FAIL,
            action: '',
            message: ((error as AxiosError).response as AxiosResponse).data.message,
          });
        });
      knownExtents.current = [...knownExtents.current, extent];
    };
    loadMoreFences();
    if (
      selectedFenceRef.current &&
      selectedFenceRef.current.get('layerId') !== MICROFENCE_LAYER_ID &&
      (selectedFenceRef.current.getProperties().points?.type.toLowerCase() === 'polygon' ||
        selectedFenceRef.current.getProperties().points?.type.toLowerCase() === 'multipolygon')
    ) {
      const geometry = selectedFenceRef.current.getGeometry();
      if (geometry) {
        setHasFences(!!featureContainsFeature(geometry));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  // Handle selected layer change
  useEffect(() => {
    const oldLyr: Option<{ source: VectorSource<Geometry>; name: string }> = layerRef.current;
    const nowLyr: Option<{ source: VectorSource<Geometry>; name: string }> = pipe(
      sequenceT(option.option)(layers, selectedLayer),
      chain(([ls, sl]) => {
        return fromNullable(ls.get(sl));
      }),
    );

    setSelectedLayerStyle(
      sourceAndNameToLayerAndName(oldLyr),
      sourceAndNameToLayerAndName(nowLyr),
      selectedLayerStyle(styleCache, selectedGeofence?.id ?? selectedMicrofence?.id),
    );

    if (
      isSome(oldLyr) &&
      isSome(nowLyr) &&
      oldLyr.value.source.get('id') !== nowLyr.value.source.get('id')
    ) {
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      selectedFenceRef.current = undefined;
    }

    layerRef.current = nowLyr;
    if (toNullable(nowLyr)?.name === FRESH_UNKNOWN_LAYER) return;
    if (isSome(layerRef.current)) {
      layerIds.forEach(layer =>
        changeVisibility(
          layer.id,
          layer.id === toNullable(selectedLayer) || showGhostGeofencesRef.current,
        ),
      );
    } else {
      setAvailableGeofences([]);
      setSelectedLayer(none);
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      selectedFenceRef.current = undefined;
      uiDeselectAllFences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLayer]);

  useEffect(() => {
    const lids = pipe(
      layers,
      option.map(ls =>
        Array.from(ls.keys()).map(k => ({
          name: toNullable(layers)?.get(k)?.name ?? k,
          id: k,
        })),
      ),
    );
    setLayerIds((isSome(lids) ? lids.value : []) as NameId[]);
  }, [layers]);

  useEffect(() => {
    // Ideally it would be better do this everytime fenceIds changes but we can't always capture that event.
    if (isSome(mapRef.current) && isSome(editRef.current)) {
      const e = editRef.current.value;
      const m = mapRef.current.value;
      m.removeInteraction(e.modify);
      m.removeInteraction(e.modifyScaleRot);
    }

    if (isSome(layerRef.current) && isSome(drawType)) {
      const modifiedInteration =
        drawType.value === 'TRIPWIRE'
          ? 'line'
          : drawType.value === 'CIRCLE' || drawType.value === 'POLYGON'
          ? 'polygon'
          : drawType.value === 'MULTIPOLYGON'
          ? 'multipolygon'
          : undefined;
      if (modifiedInteration) {
        addShapeChangeModifyInteration(layerRef.current.value.source, modifiedInteration);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneChange]);

  useEffect(() => {
    if (!selectedGeofence || !selectedMicrofence) return;
    const geofenceExists = availableGeofences.find(fence => fence.id === selectedGeofence?.id);
    const microfenceExists = availableMicrofences.find(
      fence => fence.id === selectedMicrofence?.id,
    );
    if (!mapIsLoading) {
      if (!geofenceExists) {
        setSelectedGeofence(undefined);
      } else if (!microfenceExists) {
        setSelectedMicrofence(undefined);
      }
      if (!(geofenceExists && microfenceExists)) {
        selectedFenceRef.current = undefined;
      }
    }
  }, [
    availableGeofences,
    availableMicrofences,
    selectedGeofence,
    selectedMicrofence,
    mapIsLoading,
  ]);

  useEffect(() => {
    // Set the current layer style to highlight the focused fence
    if (isNone(layerRef.current)) return;
    getLayerFromMap(layerRef.current.value.name)?.setStyle(
      selectedLayerStyle(styleCache, selectedGeofence?.id ?? selectedMicrofence?.id),
    );

    if (!selectedFenceRef.current || isNone(selectedLayer)) return;
    const fence =
      availableGeofences.find(f => f.id === selectedGeofence?.id) ??
      availableMicrofences.find(f => f.id === selectedMicrofence?.id);
    if (!fence) return;

    layerRef.current.value.source
      .getFeatures()
      .filter(
        (f: Feature<Geometry>) =>
          f.get('selected') && f.get('id') !== (selectedGeofence?.id ?? selectedMicrofence?.id),
      )
      .forEach((f: Feature<Geometry>) => {
        f.set('selected', false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGeofence, selectedMicrofence, selectedLayer]);

  useEffect(() => {
    if (selectedFromMap || !(selectedGeofence ?? selectedMicrofence)?.selected) return;
    // Update selectedFenceRef.current when selecting from sideBar
    const fenceVisibleOnScreen = (selectedFenceRef.current = toNullable(layerRef.current)
      ?.source.getFeatures()
      .find(f => f.get('id') === (selectedGeofence ?? selectedMicrofence)?.id));
    if (fenceVisibleOnScreen) {
      fenceVisibleOnScreen.set('selected', true);
      selectedFenceRef.current = fenceVisibleOnScreen;
    }
  }, [selectedFromMap, selectedGeofence, selectedMicrofence]);

  useEffect(() => {
    if (!navigateTo) return;
    if (navigateTo === (selectedGeofence?.id ?? selectedMicrofence?.id)) {
      animateToFeature();
      setNavigateTo(null);
    }
  }, [selectedGeofence, selectedMicrofence, selectedLayer, navigateTo, animateToFeature]);

  useEffect(() => {
    changeDrawShape();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawType, editType]);

  useEffect(() => {
    changeMeasurementType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementType]);

  useEffect(() => {
    if (
      !mapIsLoading &&
      availableGeofences.length > 0 &&
      selectedFenceRef.current &&
      isSome(layerRef.current)
    ) {
      const id = selectedFenceRef.current.get('id');
      if (
        !id.includes(FRESH) &&
        availableGeofences.find(f => f.id === id) &&
        !deletedFenceIds.find(f => f.id === id)
      ) {
        setSelectedGeofence(selectedFenceRef.current?.getProperties() as GridRowData);
      }
    }
    // Creating a fence while there is no list to push it to will cause problems.
    if (isNone(mapRef.current)) return;
    const m = mapRef.current.value;
    m.getInteractions().forEach(i => {
      if (i instanceof Draw || i instanceof Modify) {
        i.setActive(!mapIsLoading);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapIsLoading]);

  useEffect(() => {
    if (isNone(mapRef.current)) return;
    const olmap = mapRef.current.value;
    if (!dropLocationPin) {
      olmap?.setLayers(
        olmap?.getAllLayers().filter(layer => layer?.getClassName() !== 'pin-layer'),
      );
    } else {
      const pinLayer = olmap?.getAllLayers().find(layer => layer?.getClassName() === 'pin-layer');
      if (pinLayer) return;
      olmap?.addLayer(
        new VectorLayer({
          className: 'pin-layer',
          source: new VectorSource({
            features: [dropLocationPin],
          }),
          style: [
            new Style({
              image: new CircleStyle({
                radius: 14,
                fill: new Fill({
                  color: 'rgb(198,87,250, 0.5)',
                }),
              }),
            }),
            new Style({
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color: 'rgb(184,0,255)',
                }),
              }),
            }),
          ],
        }),
      );
    }
  }, [dropLocationPin]);

  useEffect(() => {
    if (isNone(mapRef.current)) return;
    if (!locationSearchData) {
      setDropLocationPin(undefined);
      return;
    }

    const { coords, address, isStreetAddress } = locationSearchData;
    const olmap = mapRef.current.value;
    animateToSearchedLocation(olmap.getView(), coords, address, isStreetAddress).then(pin => {
      setDropLocationPin(pin);
    });
  }, [locationSearchData, animateToSearchedLocation]);

  useEffect(() => {
    showGhostGeofencesRef.current = showGhostGeofences;
  }, [showGhostGeofences]);

  return (
    <>
      <SidebarAndMap
        sidebar={
          <>
            <GeofenceEditorSearch
              layerIds={layerIds?.sort((a, b) => a.name.localeCompare(b.name))}
              setLayerIds={setLayerIds}
              layers={layers}
              setLayers={setLayers}
              availableGeofences={availableGeofences}
              setAvailableGeofences={setAvailableGeofences}
              freshGeofences={freshGeofences}
              layersFromMap={
                toNullable(mapRef.current)
                  ?.getAllLayers()
                  .filter(l => l instanceof VectorLayer) as VectorLayer<VectorSource<Geometry>>[]
              }
              availableMicrofences={availableMicrofences}
              setAvailableMicrofences={setAvailableMicrofences}
              microfences={
                (
                  (isSome(mapRef.current)
                    ? (mapRef.current.value
                        .getAllLayers()
                        .find(lyr => lyr.get('id') === MICROFENCE_LAYER_ID) as AnimatedCluster)
                    : undefined
                  )?.getSource() as Cluster
                )
                  ?.getSource()
                  .getFeatures() ?? []
              }
              selectedGeofence={selectedGeofence}
              setSelectedGeofence={setSelectedGeofence}
              selectedMicrofence={selectedMicrofence}
              setSelectedMicrofence={setSelectedMicrofence}
              count={paginatedCount}
              setCount={setPaginatedCount}
              setExtent={setUserExtent}
              searchType={searchType}
              setSearchType={setSearchType}
              layerFilter={layerFilter}
              setLayerFilter={setLayerFilter}
              geofenceFilter={geofenceFilter}
              setGeofenceFilter={setGeofenceFilter}
              microfenceFilter={microfenceFilter}
              setMicrofenceFilter={setMicrofenceFilter}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              showGhostGeofences={showGhostGeofences}
              setShowGhostGeofences={setShowGhostGeofences}
              createEditLayer={createEditLayer}
              setCreateEditLayer={setCreateEditLayer}
              refreshSearch={refreshSearch}
              setRefreshSearch={setRefreshSearch}
              setLocationSearchData={setLocationSearchData}
              currentCenter={currentCenter}
              locationDisplay={locationDisplay}
              setLocationDisplay={setLocationDisplay}
              setDrawType={setDrawType}
              layersHaveChanged={layersHaveChanged}
              setLayersHaveChanged={setLayersHaveChanged}
              selectedLayer={fromNullable(
                (layerIds ?? []).find(lyr => lyr.id === toNullable(selectedLayer)),
              )}
              setSelectedLayer={setSelectedLayer}
              setRenamingLayer={setRenamingLayer}
              reassignedFences={reassignedFences}
              setReassignedFences={setReassignedFences}
              setDirtySave={setDirtySave}
              dirtySave={dirtySave}
              openGenericDialog={openGenericDialog}
              setOpenGenericDialog={setOpenGenericDialog}
              mapIsLoading={mapIsLoading}
              isLoading={paginating}
              setIsLoading={setPaginating}
              createEditFence={createEditFence}
              setCreateEditFence={setCreateEditFence}
              setNavigateTo={setNavigateTo}
              selectedFromMap={selectedFromMap}
              setSelectedFromMap={setSelectedFromMap}
              hasFences={hasFences}
              deselectFence={deselectFence}
              setDeselectFence={setDeselectFence}
              createNewLayer={createNewLayer}
              deleteLayer={deleteLayer}
              deleteFence={uiDeleteFence}
              changeVisibility={changeVisibility}
              editing={editing}
              setEditing={uiSetEditing}
              unsetEditing={uiUnsetEditing}
              deselectAllFences={uiDeselectAllFences}
              moveUnknownFenceToExistingLayer={moveUnknownFenceToExistingLayer}
              resetLayerChanges={uiResetLayerChanges}
              saveLayerChanges={saveLayerChanges}
              updateFenceIdentifiers={uiUpdateFenceIdentifiers}
              updateGeomobyProperties={updateGeomobyProperties}
              setAsBufferZone={setAsBufferZone}
              removeBufferZone={removeBufferZone}
              setAsBreachZone={setAsBreachZone}
              unsetAsBreachZone={unsetAsBreachZone}
              setAsClearedZone={setAsClearedZone}
              unsetAsClearedZone={unsetAsClearedZone}
            />

            <GeofenceEditorFilter
              searchType={searchType}
              layerFilter={layerFilter}
              setLayerFilter={setLayerFilter}
              geofenceFilter={geofenceFilter}
              setGeofenceFilter={setGeofenceFilter}
              microfenceFilter={microfenceFilter}
              setMicrofenceFilter={setMicrofenceFilter}
              selectedMicrofence={selectedMicrofence}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              setRefreshSearch={setRefreshSearch}
            ></GeofenceEditorFilter>
          </>
        }
        map={
          <MapContainer id={EDIT_MAP_ID}>
            <ToolPanel
              mapType={'OUTDOOR'}
              selectedLayer={
                isNone(selectedLayer) && searchType?.id === 'GEOFENCES'
                  ? fromNullable('ALL')
                  : selectedLayer
              }
              geofenceType={
                isPolygon
                  ? 'polygon'
                  : isMultipolygon
                  ? 'multipolygon'
                  : isLine
                  ? 'line'
                  : undefined
              }
              geofenceTooBig={
                selectedGeofence && selectedFenceRef.current
                  ? selectedFenceRef.current.get('points')?.coordinates?.length >
                    MAX_NUMBER_OF_POLYGONS
                  : false
              }
              editing={editing}
              isLoading={mapIsLoading || paginating}
              setEditing={uiSetEditing}
              unsetEditing={uiUnsetEditing}
              drawType={drawType}
              setDrawType={setDrawType}
              editType={editType}
              setEditType={setEditType}
              measurementType={measurementType}
              setMeasurementType={setMeasurementType}
            />

            <MapToolbar>
              {fencesLoading && <LoadIndicator what="geofences" />}
              <ChangeMapSourceType
                current={mapSourceType}
                setType={value => {
                  setMapSourceType(value);
                  isSome(setSourceRef.current) && setSourceRef.current.value(value);
                }}
              />

              <ZoomIn
                onClick={() => {
                  if (isSome(mapRef.current)) {
                    mapRef.current.value.getView().adjustZoom(0.5);
                  }
                }}
              />
              <ZoomOut
                onClick={() => {
                  if (isSome(mapRef.current)) {
                    mapRef.current.value.getView().adjustZoom(-0.5);
                  }
                }}
              />
            </MapToolbar>
          </MapContainer>
        }
      />

      <Dialog open={!!errorCode} onClose={() => serErrorCode(undefined)}>
        <DialogTitle>{`The geofence you are attempting to modify is too large, please contact GeoMoby Support.`}</DialogTitle>
        <DialogActions>
          <Button onClick={() => serErrorCode(undefined)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GeofenceEditor;
