import { Delete, FormatListBulleted } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { Header } from '../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { useMobile } from '../../util/useMobile';
import InputContainer from '../Global/InputContainer';

export const Properties = () => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);

  const [deleting, setDeleting] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [properties, setProperties] = useState<{ id?: string; label: string }[]>([]);
  const [selectedProperty, setSelectedProperty] =
    useState<{ id?: string; label: string } | undefined>();

  const isMobile = useMobile();

  const getProjectProperties = useCallback(async () => {
    setIsLoading(true);
    const properties = (
      await axios.get<{ label: string; id: string }[]>(
        `${triggersUrl}/${cid}/${pid}/geofences/properties/project`,
        authedConfig,
      )
    ).data;
    setProperties(properties.sort((a, b) => a.label.localeCompare(b.label)));
    setIsLoading(false);
  }, [cid, pid, triggersUrl, authedConfig]);

  const createProperty = async () => {
    if (!selectedProperty) return;
    try {
      const createdProperty = (
        await axios.post<{ id: string; label: string }>(
          `${triggersUrl}/${cid}/${pid}/geofences/properties/project`,
          { label: selectedProperty.label },
          authedConfig,
        )
      ).data;

      setProperties(
        [...properties, createdProperty].sort((a, b) => a.label.localeCompare(b.label)),
      );
      setSelectedProperty(undefined);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const updateProperty = async () => {
    if (!selectedProperty) return;
    try {
      const updatedProperty = (
        await axios.patch<{ id: string; label: string }>(
          `${triggersUrl}/${cid}/${pid}/geofences/properties/project/${selectedProperty.id}`,
          { label: selectedProperty.label },
          authedConfig,
        )
      ).data;

      setProperties(
        [...properties.filter(p => p.id !== selectedProperty.id), updatedProperty].sort((a, b) =>
          a.label.localeCompare(b.label),
        ),
      );
      setSelectedProperty(undefined);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const deleteProperty = async () => {
    if (!selectedProperty) return;
    try {
      await axios.delete(
        `${triggersUrl}/${cid}/${pid}/geofences/properties/project/${selectedProperty.id}`,
        authedConfig,
      );

      setProperties(properties.filter(p => p.id !== selectedProperty.id));
      setSelectedProperty(undefined);
      setDeleting(undefined);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  useEffect(() => {
    getProjectProperties();
  }, [getProjectProperties]);

  return (
    <>
      <Paper>
        <Grid id="project-properties" container direction="column" justifyContent="space-between">
          <div
            style={{
              alignSelf: 'start',
              margin: '22px',
            }}
          >
            <Header icon={<FormatListBulleted />}>Project Properties</Header>
          </div>

          <Typography
            style={{
              alignSelf: 'center',
            }}
            variant="h6"
          >
            {selectedProperty?.id ? 'Update Property' : 'Create Property'}
          </Typography>

          <Grid
            id="create-property"
            container
            direction="column"
            style={{
              alignSelf: 'center',
              alignItems: 'center',
              marginBottom: '22px',
            }}
          >
            <FormControl
              style={{
                width: '400px',
                marginBottom: '22px',
              }}
            >
              <InputContainer
                id="create-name"
                label="Name"
                key={'create-name'}
                name={'create-name'}
                value={selectedProperty?.label ?? ''}
                onChange={(e: { target: { value: string } }) => {
                  setSelectedProperty({
                    ...selectedProperty,
                    label: e.target.value,
                  });
                }}
                placeholder={properties.length > 0 ? properties[0].label : 'Propery Name'}
              />
            </FormControl>

            <FormControl
              style={{
                width: '400px',
              }}
            >
              <InputLabel id="property-values">Values</InputLabel>
              <Select
                fullWidth
                labelId="property-values"
                id="property-values"
                value={'COMING SOON'}
                label="Values"
                disabled={true}
                onChange={e => {
                  // TODO: LTP-935
                }}
              >
                {[{ id: 'COMING SOON' }].map(value => (
                  <MenuItem key={value.id} value={value.id}>
                    <Tooltip title={value.id}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {value.id}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Submit */}
            <Grid
              container
              direction="column"
              style={
                selectedProperty?.id
                  ? {
                      width: 'calc(100% - 10vw)',
                      marginTop: '20px',
                      display: 'grid',
                      gap: '2%',
                      gridTemplateColumns: '49% 49%',
                    }
                  : {
                      width: '401px',
                      marginTop: '20px',
                    }
              }
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (selectedProperty?.id) {
                    updateProperty();
                  } else {
                    createProperty();
                  }
                }}
              >
                {selectedProperty?.id ? 'Update' : 'Create'}
              </Button>
              {selectedProperty?.id && (
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setSelectedProperty(undefined);
                  }}
                >
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>

          <Typography
            style={{
              alignSelf: 'center',
            }}
            variant="h6"
          >
            Existing Properties
          </Typography>

          <Grid
            container
            direction="column"
            style={{
              marginLeft: '23px',
              marginBottom: '-20px',
              display: 'grid',
              gridTemplateColumns: '48% 48%',
            }}
          >
            <Button
              style={{
                justifySelf: 'start',
                marginLeft: '-5px',
              }}
              disabled={!selectedProperty?.id}
              onClick={() => {
                setDeleting(selectedProperty?.id);
              }}
            >
              <span style={{ fontSize: '10px' }}>Delete</span>
              <Delete />
            </Button>

            <Typography
              style={{
                justifySelf: 'end',
              }}
            >{`${properties.length} ${
              Number(properties.length) === 1 ? 'result' : 'results'
            }`}</Typography>
          </Grid>

          <Grid
            id="properties-grid"
            container
            justifyContent="left"
            style={{
              border: 'inset #23272D',
              maxWidth: '96%',
              height: 'auto',
              maxHeight: '500px',
              overflow: 'hidden auto',
              flexFlow: 'column',
              background: '#23272D',
              margin: '25px',
            }}
          >
            {/* Loading */}
            {isLoading && (
              <CircularProgress
                style={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  marginTop: '20%',
                  left: '50%',
                }}
              />
            )}

            {/* Exiting Properties */}
            <TableContainer
              component={Paper}
              style={{
                width: '99.8%',
              }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow
                    style={{
                      height: '55px',
                    }}
                  >
                    <TableCell style={{ fontSize: '15px' }}></TableCell>
                    <TableCell style={{ fontSize: '15px' }} align="left">
                      Name
                    </TableCell>
                    <TableCell style={{ fontSize: '15px' }} align="left">
                      Values
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {properties.map(prop => (
                    <TableRow
                      key={prop.label}
                      sx={{
                        height: '55px',
                        fontSize: '15px',
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell align="left" sx={{ width: '1em' }}>
                        <Checkbox
                          checked={
                            !!properties.find(
                              p => p.id === selectedProperty?.id && p.id === prop?.id,
                            )
                          }
                          onChange={() => {
                            setSelectedProperty(selectedProperty === prop ? undefined : prop);
                          }}
                        />
                      </TableCell>
                      <Tooltip title={prop.label}>
                        <TableCell align="left" component="th" scope="row">
                          {prop.label}
                        </TableCell>
                      </Tooltip>
                      <Tooltip title={''}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{
                            color: '#4CB8C4',
                          }}
                        >
                          {'COMING SOON'}
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>

      <Dialog open={!!deleting} onClose={() => setDeleting(undefined)}>
        <DialogTitle>{`Are you sure you want to delete this Property?`}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              if (deleting) {
                const deletable = properties.find(d => d.id === deleting);
                if (!deletable) return;
                deleteProperty();
              }
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setDeleting(undefined)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
